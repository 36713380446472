import React, { useState } from 'react'
import { Button, Modal, Form, Label } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const ManageSingleTradePublication = ({ tradePublications, setTradePublications, tradePublication }) => {

  const originalTradePublicationName = tradePublication.name;
  const { handleSubmit, errors, register } = useForm();
  const [open, setOpen] = useState(false);

  const handleUpdatingTradePublication = (data) => {
    if (data.name !== originalTradePublicationName) {
      axiosWithAuth()
        .put(`/tradepublications/${tradePublication.id}`, data)
        .then(res => {
          setTradePublications(tradePublications.map(trade => {
            if (trade.id === res.data.id) {
              return res.data
            } else {
              return trade
            }
          }))
          setOpen(false)
        }).catch(err => { console.log(err) })
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button onClick={e => e.preventDefault()} basic color='yellow'>Manage</Button>}
    >
      <Modal.Header>Manage Trade Publication</Modal.Header>
      <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div>
          <Form style={{ width: "700px", margin: "0 auto", display: "flex", flexDirection: "column" }}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              defaultValue={tradePublication.name}
              name="name"
              id="name"
              ref={register({
                required: "You must provide a trade publication name",
                minLength: {
                  value: 2,
                  message: "Trade publication name must be at least 2 characters"
                }
              })}
            />
            {errors.name && <Label basic color='red' pointing >* {errors.name.message} *</Label>}
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" onClick={handleSubmit(handleUpdatingTradePublication)} basic color='yellow'>
          Save
        </Button>
        <Button onClick={() => setOpen(false)} basic color='red'>
          Exit
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ManageSingleTradePublication;