import { Button, Divider, Form, Card, Header, Icon, Modal } from 'semantic-ui-react';
import Breadcrumbs from "../breadcrumbs";
import { useEffect, useState, useRef } from 'react';
import { useForm } from "react-hook-form";
import { useParams, useHistory } from 'react-router-dom';
import CryptoJS from "crypto-js";
import { axiosWithAuth } from '../../helperFunctions/axiosWithAuth';


const TradeEvents = () => {
    const { id } = useParams();
    const breadcrumbPath = [{ name: "Dashboard", url: `/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}` }, { name: "Trade Events" }];
    const [tradePresences, setTradePresences] = useState([]);
    const { handleSubmit, errors, register, watch, reset } = useForm();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [tradeshows, setTradeshows] = useState([]);
    const exhibiting = useRef({});
    exhibiting.current = watch("1", false);
    const reserved = useRef({});
    reserved.current = watch("booth_space_reserved", false);
    var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);


    useEffect(() => {
        axiosWithAuth()
            .get(`/tradeevents/${id}`)
            .then(res => {
                if (res.data.tradeForm.companies_id !== Number(theId) || res.data.tradeForm.tradeshow_form_complete === true) {
                    history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
                }
            }).catch(err => {
                history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
            })
    }, [])


    useEffect(() => {
        axiosWithAuth()
            .get(`/tradepresences`)
            .then(res => {
                setTradePresences(res.data)
            }).catch(err => {
                console.log(err)
            })
    }, [])


    useEffect(() => {
        axiosWithAuth()
            .get(`/tradeevents/${id}/tradeshows`)
            .then(res => {
                setTradeshows(res.data)
            }).catch(err => {
                console.log(err)
            })

    }, [])


    const handleMyDataPut = e => {
        e.preventDefault()
        axiosWithAuth()
            .put(`/tradeevents/${id}`, { "tradeshow_form_complete": true })
            .then(res => {
                history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
            }).catch(err => {
                console.log(err)
            })
    }


    // Need to rethink this function, There is an easier way to accomplish end result
    const dataFormatting = data => {
        let persences = []
        let dataObject = {}
        for (const [key, value] of Object.entries(data)) {
            if (typeof value === "boolean") {
                if (key !== "booth_space_reserved") {
                    if (value === true) {
                        persences.push(Number(key))
                    }
                } else {
                    if (value === true) {
                        dataObject[key] = true;
                    } else {
                        dataObject[key] = false;
                    }
                }
            } else {
                dataObject[key] = value;
            }
        }
        dataObject["trade_event_forms_id"] = Number(id);
        dataObject["companies_id"] = Number(theId)
        dataObject["presences"] = persences
        return dataObject
    }


    const handleTradeEvent = data => {
        const myData = dataFormatting(data)
        axiosWithAuth()
            .post(`/tradeshows`, myData)
            .then(res => {
                setTradeshows([...tradeshows, res.data.tradeShows[0]])
                reset();
            }).catch(err => {
                console.log(err)
            })
    }


    const handleRemovingTrade = showId => {
        axiosWithAuth()
            .delete(`/tradeshows/${showId}`)
            .then(res => {
                setTradeshows(() => {
                    return tradeshows.filter(trade => {
                        if (trade.id !== showId) {
                            return trade
                        }
                    })
                })
            }).catch(err => {
                console.log(err)
            })
    }


    return (
        <div style={{ paddingBottom: "5rem" }}>
            <div style={{ width: "90%", margin: "0 auto", textAlign: "left", marginTop: "2rem" }}>
                <Breadcrumbs path={breadcrumbPath} />
            </div>
            <div style={{ backgroundColor: "rgb(250,250,250)", width: "55%", margin: "0 auto", boxShadow: "6px 7px 3px 1px #C6C6C6" }} >
                <div style={{ marginTop: "2rem", color: "rgb(51,51,51)", fontSize: "2rem", padding: "1rem", backgroundColor: "rgb(255,191,77)" }}>Trade Events Form</div>
                <Form onSubmit={handleSubmit(handleTradeEvent)} style={{ margin: "2rem 0" }}>
                    <section style={{ display: "flex", textAlign: "left", justifyContent: "space-evenly" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
                            <label htmlFor="name" >Tradeshow Event Name</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                ref={register({
                                    required: "Please provide the name of the Trade Show"
                                })}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
                            <label htmlFor="type_of_show" >Tradeshow Event Type</label>
                            <select
                                type="text"
                                name="type_of_show"
                                id="type_of_show"
                                ref={register({
                                    required: "Please indicate the type of tradeshow "
                                })}
                            >
                                <option value="In-Person">In-Person</option>
                                <option value="Virtual" >Virtual</option>
                            </select>
                        </div>
                    </section>
                    <p style={{ textAlign: "left", margin: "2rem 0rem 1rem 2rem" }}>Please select the category(s) that best applies to your trade event goals</p>
                    <section style={{ display: "flex", justifyContent: "space-evenly", margin: "1rem" }}>
                        {tradePresences && tradePresences.length > 0 && tradePresences.map(trade => (
                            <div key={trade.id}>
                                <input
                                    type="checkbox"
                                    name={trade.id}
                                    id={trade.id}
                                    style={{ marginRight: ".5rem" }}
                                    ref={register({
                                        required: false,
                                    })}
                                />
                                <label htmlFor={trade.id}>{trade.name}</label>
                            </div>
                        ))}
                    </section>
                    {exhibiting.current === true && (
                        <div style={{ textAlign: "left", margin: "3rem 1rem 1rem 1rem" }}>
                            <Divider style={{ margin: "3rem 1rem 2rem 1rem " }} />
                            <input
                                type="checkbox"
                                name="booth_space_reserved"
                                id="booth_space_reserved"
                                style={{ marginRight: ".5rem", marginLeft: "1rem" }}
                                ref={register({
                                    required: false,
                                })}
                            />
                            <label htmlFor="booth_space_reserved">Please select if a booth has been reserved</label>
                        </div>
                    )}
                    {reserved.current === true && (
                        <section style={{ display: "flex", textAlign: "left", justifyContent: "space-evenly", marginBottom: "2rem" }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
                                <label htmlFor="booth_number" >Booth Number</label>
                                <input
                                    type="text"
                                    name="booth_number"
                                    id="booth_number"
                                    ref={register({
                                        required: true,
                                        valueAsNumber: "the value must be a number"
                                    })}
                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
                                <label htmlFor="approximate_dimensions" >Approximate Booth Dimensions</label>
                                <input
                                    type="text"
                                    name="approximate_dimensions"
                                    id="approximate_dimensions"
                                    ref={register({
                                        required: true
                                    })}
                                />
                            </div>
                        </section>
                    )}
                    <Button type="submit" style={{ marginTop: "1rem" }}>Add Tradeshow</Button>
                    <Divider style={{ margin: "2rem 1rem 2rem 1rem " }} />
                    <div style={{ paddingTop: "1rem", display: "flex", width: "98%", margin: "0 auto", justifyContent: "space-evenly", flexWrap: "wrap", alignItems: "baseline" }}>
                        {tradeshows && tradeshows.length > 0 && tradeshows.map(show => (
                            <Card style={{ textAlign: "left" }} key={show.id}>
                                <Card.Content>
                                    <p><strong>{show.name.toUpperCase()}</strong></p>
                                    <p>{show.type_of_show.toUpperCase()}</p>
                                </Card.Content>
                                <Card.Content extra>
                                    <div>
                                        <p>Booth Number: {show.booth_number || "N/A"}</p>
                                        <p>Approximate Dimensions: {show.approximate_dimensions || "N/A"}</p>
                                    </div>
                                </Card.Content>
                                <Card.Content>
                                    <Button style={{ width: "100%" }} color="red" inverted onClick={() => handleRemovingTrade(show.id)}>Remove</Button>
                                </Card.Content>
                            </Card>
                        ))}
                    </div>
                    <div style={{ padding: "1rem 2rem 2rem 0rem", marginTop: "2rem", display: "flex", justifyContent: "flex-end" }}>
                        <Modal
                            basic
                            onClose={() => setOpen(false)}
                            onOpen={() => {
                                if (tradeshows && tradeshows.length > 0) {
                                    setOpen(true)
                                }
                            }}
                            open={open}
                            size='small'
                            trigger={<Button type="submit" color="yellow">Submit</Button>}
                        >
                            <Header icon><Icon name='send' />Trade Events Form</Header>
                            <Modal.Content>
                                <p style={{ fontWeight: 700 }}>After submission, this form will be sent for review and will only be accessible in the form management tab located in settings. Are you sure you want to submit?</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button basic color='red' inverted onClick={() => setOpen(false)}>
                                    <Icon name='remove' /> No
                        </Button>
                                <Button type="submit" color='green' inverted onClick={e => {
                                    handleMyDataPut(e)
                                    setOpen(false)
                                }}>
                                    <Icon name='checkmark' /> Yes
                        </Button>
                            </Modal.Actions>
                        </Modal>
                    </div>
                </Form>
            </div>
        </div >
    )
}

export default TradeEvents;