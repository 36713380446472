import React, { useState } from 'react'
import { Button, Modal, Form, Label } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const ManageSingleWebsite = ({ website, setWebsites, websites }) => {

  const originalWebsiteURL = website.url
  const { handleSubmit, errors, register } = useForm();
  const [open, setOpen] = useState(false);

  const handleUpdatingWebsite = (data) => {
    if (data.url !== originalWebsiteURL) {
      axiosWithAuth()
        .put(`/websites/${website.id}`, data)
        .then(res => {
          setWebsites(websites.map(web => {
            if (web.id === res.data[0].id) {
              return res.data[0]
            } else {
              return web
            }
          }))
          setOpen(false)
        }).catch(err => { console.log(err) })
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button onClick={e => e.preventDefault()} basic color='yellow'>Manage</Button>}
    >
      <Modal.Header>Manage Website</Modal.Header>
      <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div>
          <Form style={{ width: "700px", margin: "0 auto", display: "flex", flexDirection: "column" }}>
            <label htmlFor="url">URL</label>
            <input
              type="text"
              defaultValue={website.url}
              name="url"
              id="url"
              ref={register({
                required: "Please provide a valid URL",
                pattern: {
                  value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,
                  message: "Please provide a valid url starting with HTTP or HTTPS"
                }
              })}
            />
            {errors.url && <Label basic color='red' pointing >* {errors.url.message} *</Label>}
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" onClick={handleSubmit(handleUpdatingWebsite)} basic color='yellow'>
          Save
        </Button>
        <Button onClick={() => setOpen(false)} basic color='red'>
          Exit
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ManageSingleWebsite;