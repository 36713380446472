import { Table, Button, Menu, Header } from 'semantic-ui-react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ManageSingleTradePublication from "./managesingletradepublication";
import AddTradePublication from "./addtradepublication";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';


const TradePublicationsTable = ({ userDash }) => {

    const [tradePublications, setTradePublications] = useState([])
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${theId}/tradePublications`)
            .then(res => {
                setTradePublications(res.data)
            }).catch(err => console.log(err))
    }, [])


    const handleRemovingTradePublication = (tradePublicationId) => {
        axiosWithAuth()
            .delete(`/tradepublications/${tradePublicationId}`)
            .then(res => {
                setTradePublications(tradePublications.filter(trade => {
                    if (trade.id !== tradePublicationId) {
                        return trade
                    }
                }))
            }).catch(err => console.log(err))
    }


    return (
        <>
            {tradePublications && tradePublications.length > 0 ? (
                <>
                    {userDash === true && <Header style={{ textAlign: "left", width: "1100px", margin: "2rem auto -2rem auto" }}>Trade Publication</Header>}
                    <Table celled style={{ width: "1100px", margin: "3rem auto" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {tradePublications.map(tradePublication => (
                                <Table.Row key={tradePublication.id}>
                                    <Table.Cell style={{ width: "70%" }}>{tradePublication.name}</Table.Cell>
                                    <Table.Cell style={{ width: "30%", textAlign: "center" }}><ManageSingleTradePublication tradePublication={tradePublication} tradePublications={tradePublications} setTradePublications={setTradePublications} /><Button color="red" basic onClick={() => handleRemovingTradePublication(tradePublication.id)}>Delete</Button></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='2'>
                                    <Menu floated='right'>
                                        <AddTradePublication tradePublications={tradePublications} setTradePublications={setTradePublications} originalText={theId} />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </>)
                :
                <div style={{ padding: "5rem 0rem", textAlign: "center" }}>There are no trade publications in our system</div>
            }
        </>
    )
}

export default TradePublicationsTable;