import React, { useState } from 'react'
import { Button, Modal, Form, Label } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const ManageSingleSocial = ({ social, setSocials, socials }) => {

    const originalsocialPlatform = social.name
    const originalsocialurl = social.url
    const { handleSubmit, errors, register } = useForm();
    const [open, setOpen] = useState(false);
    const media = ["linkedin", "facebook", "instagram", "twitter", "youtube", "reddit", "pinterest", "quora", "other"];


    function capitalizeFirstLetter(string) {
        if (string === "linkedin") {
            const newLinked = string.charAt(0).toUpperCase() + string.slice(1)
            return newLinked.slice(0, 6) + newLinked.charAt(6).toUpperCase() + string.slice(7)
        } else {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }

    const handleUpdatingsocials = (data) => {
        debugger;
        if (data.name !== originalsocialPlatform || data.url !== originalsocialurl) {
            axiosWithAuth()
                .put(`/socials/${social.id}`, data)
                .then(res => {
                    setSocials(socials.map(soc => {
                        debugger;
                        if (soc.id !== social.id) {
                            return soc;
                        } else {
                            return res.data;
                        }
                    }))
                    setOpen(false)
                }).catch(err => { console.log(err) })
        }
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={<Button basic color='yellow'>Manage</Button>}
        >
            <Modal.Header>Manage Social</Modal.Header>
            <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div>
                    <Form style={{ width: "700px", margin: "0 auto", display: "flex", flexDirection: "column" }}>
                        <label htmlFor={"name"}>Platform</label>
                        <select
                            style={{ width: "100%" }}
                            name={"name"}
                            id={"name"}
                            defaultValue={social.name.toLowerCase()}
                            ref={register({ required: "Required field" })}
                        >
                            <option hidden value={""}>Choose Option</option>
                            {media && media.map(media => (<option key={media} value={media}>{capitalizeFirstLetter(media)}</option>))}
                        </select>
                        {errors.name && <p style={{ color: "red", margin: ".2rem 0rem" }}>* {errors.name.message} *</p>}
                        <label htmlFor="url" style={{ marginTop: "1rem" }}>URL</label>
                        <input
                            type="text"
                            defaultValue={social.url}
                            name="url"
                            id="url"
                            ref={register({
                                required: "Required field",
                                pattern: {
                                    value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
                                    message: "Please provide a valid url"
                                }
                            })
                            }
                        />
                        {errors.name && <Label basic color='red' pointing >* {errors.name.message} *</Label>}
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button type="submit" onClick={handleSubmit(handleUpdatingsocials)} basic color='yellow'>
                    Save
        </Button>
                <Button onClick={() => setOpen(false)} basic color='red'>
                    Exit
        </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ManageSingleSocial;