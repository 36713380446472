import { Table, Button, Menu, Header } from 'semantic-ui-react';
import { useEffect, useState } from "react";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js";
import { useParams } from "react-router-dom";
import ManageSingleChannel from "./managesinglechannel";
import AddChannel from "./addchannel";
import CryptoJS from "crypto-js";


const ChannelsTable = ({ userDash }) => {

    const [channels, setChannels] = useState([])
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${theId}/channels`)
            .then(res => {
                setChannels(res.data)
            }).catch(err => console.log(err))
    }, [])


    const handleRemovingChannel = (channelId) => {
        axiosWithAuth()
            .delete(`/channels/${channelId}`)
            .then(res => {
                setChannels(channels.filter(chan => {
                    if (chan.id !== channelId) {
                        return chan
                    }
                }))
            }).catch(err => console.log(err))
    }

    return (
        <>
            {channels && channels.length > 0 ? (
                <>
                    {userDash === true && <Header style={{ textAlign: "left", width: "1100px", margin: "2rem auto -2rem auto" }}>Channels</Header>}
                    <Table celled style={{ width: "1100px", margin: "3rem auto" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Percentage</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {channels.map(channel => (
                                <Table.Row key={channel.id}>
                                    <Table.Cell style={{ width: "50%" }}>{channel.name}</Table.Cell>
                                    <Table.Cell style={{ width: "20%" }}>{channel.percentage}%</Table.Cell>
                                    <Table.Cell style={{ width: "30%", textAlign: "center" }}><ManageSingleChannel channel={channel} setChannels={setChannels} channels={channels} /><Button color="red" basic onClick={() => handleRemovingChannel(channel.id)}>Delete</Button></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='3'>
                                    <Menu floated='right'>
                                        <AddChannel channels={channels} setChannels={setChannels} originalText={theId} />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </>)
                :
                <div style={{ padding: "5rem 0rem", textAlign: "center" }}>There are no channels in our system</div>
            }
        </>
    )
}

export default ChannelsTable;