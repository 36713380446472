import { Button, Image, Label, Segment, Table } from 'semantic-ui-react';

const InterestCard = ({ serviceID, companiesServiceID, name, headline, image, formCreator}) => {

    return (
        <div style={{ zIndex: "10", width: "80%", margin: "2rem auto" }}>
            <Segment >
                <Table celled>
                    <Table.Body>
                        <Table.Row >
                            <Table.Cell style={{ width: "10%" }}><Image floated='right' size='tiny' src={image} /></Table.Cell>
                            <Table.Cell style={{ width: "15%" }}>{name}</Table.Cell>
                            <Table.Cell style={{ width: "42%" }}>{headline}</Table.Cell>
                            <Table.Cell style={{ width: "15%", textAlign: "center" }}><Label basic color="red">Needs Attention!</Label></Table.Cell>
                            <Table.Cell style={{ width: "28%", textAlign: "center" }}><Button onClick={() => formCreator(companiesServiceID, serviceID)} basic color='yellow'>Complete Form</Button></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    )
}

export default InterestCard;