import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Label, Button, Divider, Form } from 'semantic-ui-react';
import { useParams } from "react-router-dom";

const Geographics = ({ marketMappingForm, setMarketMappingForm, url, formName, fillOutMarketMappingForm, history, originalText }) => {

    const [geographics, setGeographics] = useState([["name0", "percentage0"]]);
    const [validatePercentages, setValidatedPercentages] = useState(false)
    const { handleSubmit, errors, register } = useForm();
    const { id } = useParams();


    useEffect(() => {
        if (marketMappingForm.geographics_complete === true) {
            history.push(`${url}/productsservices`)
        }
    }, [])

    const handleAddingInput = (myI, e) => {
        e.preventDefault()
        if (myI < 5) {
            setGeographics([...geographics, [`name${myI + 1}`, `percentage${myI + 1}`]])
        }
    }

    const handleRemovingInput = (myI, e) => {
        e.preventDefault()
        if (geographics.length > 1) {
            setGeographics(geographics.filter(geo => {
                if (geo[0] !== `name${myI}`) {
                    return geo
                }
            }))
        }
    }
    const handleFilteringData = data => {

        let myObject = {}
        let compId = Number(originalText)

        for (const [key, value] of Object.entries(data)) {
            //filtering and matching data
            const newKey = key.replace("name", "");
            const filteredKey = newKey.replace("percentage", "");
            if (!myObject[filteredKey]) {
                myObject[filteredKey] = value
            }
            else {
                myObject[filteredKey] = { "name": myObject[filteredKey], "percentage": value, companies_id: compId, marketing_mapping_forms_id: id }
            }
        }
        return { "geographics": Object.values(myObject), isForm: true }
    }

    const handleValidationOfPercetages = data => {
        let count = 0;
        for (const [key, value] of Object.entries(data)) {
            if (key.includes("percentage")) {
                count += value
            }
        }
        if (count === 100) {
            return true
        }

        return false
    }

    const handlePost = data => {
        setValidatedPercentages(false)
        if (handleValidationOfPercetages(data) === true) {
            fillOutMarketMappingForm(formName, handleFilteringData(data))
                .then(res => {
                    setMarketMappingForm({
                        ...marketMappingForm,
                        geographics_complete: 1
                    })
                    history.push(`${url}/productsservices`);
                })
                .catch(err => { console.log(err) })
        } else {
            setValidatedPercentages(true)
        }
    }

    return (
        <div style={{ textAlign: "left", padding: "2rem" }}>
            <div style={{ fontSize: "2rem", margin: "1rem 0rem" }}><strong>Geographies</strong></div>
            <p>Please list up to 6 geographies (countries, continents, international regions or US regions) through which you currently do business. Examples include: France, South America, EMEA, Southeast US. If you only sell into one geography, please note that in the area below. If you sell into multiple geographies, please try to approximate what proportion of your sales are associated with each geography in the boxes to the right.</p>
            <div>
                <Form onSubmit={handleSubmit(handlePost)} style={{ display: "flex", flexDirection: "column" }}>
                    {geographics && geographics.map((geo, i) => (
                        <div key={i} style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", }}>
                                <label style={{ width: "40%", marginRight: "1rem" }} htmlFor={geo[0]}>Geographic {i + 1}</label>
                                <label htmlFor={geo[1]}>Percentage {i + 1}</label>
                            </div>
                            <div style={{ display: "flex", margin: ".5rem 0rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "40%", marginRight: "1rem", alignItems: "center" }}>
                                    <input
                                        type="text"
                                        id={geo[0]}
                                        name={geo[0]}
                                        ref={register({
                                            required: "You must provide a Geographic name",
                                            minLength: {
                                                value: 2,
                                                message: "Geographic must be at least 2 characters"
                                            }
                                        })}
                                    />
                                    {errors[geo[0]] && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem" }} pointing>{errors[geo[0]].message}</Label>}
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "40%", marginRight: "1rem", alignItems: "center" }}>
                                    <input
                                        type="text"
                                        id={geo[1]}
                                        name={geo[1]}
                                        ref={register({
                                            required: "You must provide a percentage between 1-100",
                                            valueAsNumber: true,
                                            validate: (value) => value <= 100 && value >= 1 || "You must provide a percentage between 1-100",
                                        })} />
                                    {errors[geo[1]] && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem" }} pointing>{errors[geo[1]].message}</Label>}
                                </div>
                                {i === geographics.length - 1 &&
                                    <div>
                                        <Button icon="add" color="yellow" onClick={(e) => handleAddingInput(i, e)} />
                                        <Button icon="x" onClick={(e) => handleRemovingInput(i, e)}></Button>
                                    </div>}
                            </div>
                        </div>
                    ))}
                    {validatePercentages === true && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem", width: "82%" }}>All percentages together should add up to 100</Label>}
                    <Divider />
                    <div style={{ marginTop: ".5rem", textAlign: "left", display: "flex", justifyContent: "flex-end" }}>
                        <Button type="submit" color="yellow">Submit</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}


export default Geographics;