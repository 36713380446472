export const handleRemovingURL = (name, inputs, setInputs, setNumberURL, numberURL, e, numberOfInputs) => {
    e.preventDefault()
    if (inputs.length > 1) {
        if (numberOfInputs == 2) {
            setInputs(inputs.filter((input, i) => {
                if (input.name !== name) {
                    return input
                }
            }))
        } else {
            setInputs(inputs.filter((input, i) => {
                if (input !== name) {
                    return input
                }
            }))
        }
        setNumberURL(numberURL - 1)
    }
}


export const handleAddingInput = (e, setInputs, inputs, numberURL, setNumberURL, numberOfInputs) => {
    e.preventDefault()
    if (numberOfInputs === 2) {
        setInputs([...inputs, {
            name: `name${numberURL}`,
            url: `url${numberURL}`
        }])
    } else {
        setInputs([...inputs, `name${numberURL}`])
    }
    setNumberURL(numberURL + 1)
}