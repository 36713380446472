import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { creatingUser } from "../../reduxStore/actions/user-actions";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Label } from 'semantic-ui-react';
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth.js";
import Breadcrumbs from "../breadcrumbs";
import CustomLoader from "../Loader";

const AddLevyUser = ({ creatingUser }) => {

    const [permissionsTypes, setPermissionsTypes] = useState([]);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState("");
    const { handleSubmit, errors, register, watch } = useForm();
    const password = useRef({});
    password.current = watch("password", "");
    const breadcrumbPath = [{ name: "Dashboard", url: "/employeedashboard" }, { name: "Create Internal User" }];

    useEffect(() => {
        axiosWithAuth()
            .get(`/usertypes`)
            .then(res => {
                setPermissionsTypes(res.data);
            }).catch(err => console.log(err))
    }, []);

    const handleSignup = data => {
        delete data["confirm_password"];
        setIsLoading(true);

        creatingUser(data)
            .then(response => {
                if (!response.errorMessage) {
                    history.push("/managelevyusers");
                } else {
                    setIsLoading(false)
                    setErr(response.errorMessage)
                }
            });
    };

    return (
        <div>
            <CustomLoader hide={!isLoading} />
            <div style={{ width: "90%", margin: "0 auto", textAlign: "left", marginTop: "2rem" }} hidden={isLoading}>
                <Breadcrumbs path={breadcrumbPath} />
            </div>
            <div style={{ paddingBottom: "5rem" }} >
                <div style={{ width: "60%", display: "flex", flexDirection: "column", alignItems: "space-between", backgroundColor: "rgb(250,250,250)", boxShadow: "6px 7px 3px 1px #C6C6C6", margin: "3rem auto" }}>
                    <div style={{ backgroundColor: "rgb(255,191,77)", width: "100%", textAlign: "center" }}>
                        <div style={{ fontSize: "2rem", padding: "1rem", color: "rgb(51,51,51)" }}>Create Internal User</div>
                    </div>
                    <section style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center", marginTop: ".5rem" }}>
                        <form onSubmit={handleSubmit(handleSignup)} autoComplete="off" style={{ display: "flex", flexDirection: "column", width: "95%", justifyContent: "space-evenly" }}>
                            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    <label style={{ fontWeight: 700, fontSize: "18px", textAlign: "left", marginLeft: "1rem" }} htmlFor="first_name">First Name <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            defaultValue={""}
                                            ref={register({
                                                required: "You must provide a first name",
                                                minLength: {
                                                    value: 2,
                                                    message: "First name must be at least 2 characters"
                                                }
                                            })} />

                                        {errors.first_name && <Label basic color='red' pointing>{errors.first_name.message}</Label>}
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    <label style={{ fontWeight: 700, fontSize: "18px", textAlign: "left", marginLeft: "1rem" }} htmlFor="last_name">Last Name <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            defaultValue={""}
                                            ref={register({
                                                required: "You must provide a last name",
                                                minLength: {
                                                    value: 2,
                                                    message: "Last name must be at least 2 characters"
                                                }
                                            })} />
                                        {errors.last_name && <Label basic color='red' pointing>{errors.last_name.message}</Label>}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="phone_number">Phone Number <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="tel"
                                            name="phone_number"
                                            id="phone_number"
                                            defaultValue={""}
                                            ref={register({
                                                required: "You must enter a valid phone number",
                                                pattern: {
                                                    value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                                                    message: "Please enter a valid phone number"
                                                }
                                            })}
                                        />
                                        {errors.phone_number && <Label basic color='red' pointing>{errors.phone_number.message} </Label>}
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }} onClick={() => err ? setErr("") : null}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="email">Email <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        {/*Input and error handling goes in here */}
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="text"
                                            name="email"
                                            id="email"
                                            defaultValue={""}
                                            autoComplete="new-email"
                                            ref={register({
                                                required: "You must enter an email",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            })} />
                                        {errors.email && <Label basic color='red' pointing>{errors.email.message}</Label>}
                                        {err && <Label basic color='red' pointing>{err}</Label>}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="password">Password <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        {/*Input and error handling goes in here */}
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="password"
                                            name="password"
                                            id="password"
                                            defaultValue={""}
                                            ref={register({
                                                required: "You must enter a password",
                                                minLength: {
                                                    value: 8,
                                                    message: "Your password must have at least 8 characters",
                                                },
                                            })} />
                                        {errors.password && <Label basic color='red' pointing>{errors.password.message}</Label>}
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="confirm_password">Confirm Password <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        {/*Input and error handling goes in here */}
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="password"
                                            name="confirm_password"
                                            id="confirm_password"
                                            defaultValue={""}
                                            ref={register({
                                                validate: (value) =>
                                                    value === password.current || "Your passwords do not match",
                                            })} />
                                        {errors.confirm_password && <Label basic color='red' pointing>{errors.confirm_password.message}</Label>}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="hubspot_owner_id">Position <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        {/*Input and error handling goes in here */}
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="text"
                                            name="position"
                                            id="position"
                                            defaultValue={""}
                                            ref={register({
                                                required: "You must enter a provide the user's current position",
                                            })} />
                                        {errors.position && <Label basic color='red' pointing>{errors.position.message}</Label>}
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="user_types_id">Set Permissions <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        {/*Input and error handling goes in here */}
                                        <select
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            name="user_types_id"
                                            id="user_types_id"
                                            ref={register({
                                                required: "You must specify user premissions", valueAsNumber: true
                                            })}>
                                            <option hidden value={""}>Choose One</option>
                                            {permissionsTypes && permissionsTypes.length > 0 && permissionsTypes.map(type => (
                                                <option key={type.id} value={type.id}>{type.name}</option>
                                            ))}

                                        </select>
                                        {errors.user_types_id && <Label basic color='red' pointing>{errors.user_types_id.message}</Label>}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="hubspot_owner_id">HubSpot Owner ID <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        {/*Input and error handling goes in here */}
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="text"
                                            name="hubspot_owner_id"
                                            id="hubspot_owner_id"
                                            defaultValue={""}
                                            ref={register({
                                                required: "You must enter a valid 8 digit HubSpot owner ID",
                                                valueAsNumber: true,
                                                minLength: {
                                                    value: 8,
                                                    message: "Your hubspot owner id is an 8 digit number",
                                                }
                                            })} />
                                        {errors.hubspot_owner_id && <Label basic color='red' pointing>{errors.hubspot_owner_id.message}</Label>}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", width: "97%", margin: "3rem auto" }}>
                                <button type="submit" style={{ width: "13rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer" }}>Create</button>
                            </div>
                        </form>
                    </section>
                </div>
            </div >
        </div>
    );
}

const mapStateToProps = state => {
    return {
        user: state.companiesReducer.user
    }
}

export default connect(mapStateToProps, { creatingUser })(AddLevyUser);