import { useForm } from "react-hook-form";
import { Label, Divider } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth";


const PassionAssets = ({ brandForm, setBrandForm, url }) => {

    const { handleSubmit, errors, register } = useForm();
    const history = useHistory();

    const handlePut = data => {
        data["passions_assets_complete"] = 1;
        axiosWithAuth()
            .put(`/brandpositioning/${brandForm.id}`, data)
            .then(res => {
                setBrandForm(res.data)
                history.push(`${url}/featuresdifferentiation`);
            }).catch(err => { console.log(err) })

    }

    return (
        <form onSubmit={handleSubmit(handlePut)}>
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "5rem auto 2rem auto" }}>
                <h2>5. What are the 10-15 most important Passions of your organization?</h2>
                <p style={{ marginBottom: "0" }}><strong>Definition:</strong> What you care about most deeply.</p>
                <h5><strong>Examples:</strong></h5>
                <ul style={{ marginTop: "0" }}>
                    <li>Being true to our word</li>
                    <li>Customer Service</li>
                    <li>Excellence</li>
                    <li>Innovation</li>
                    <li>Technology</li>
                    <li>etc..</li>
                </ul>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="passions" style={{ marginBottom: "1rem" }}><strong>What are your Company’s 6 to 20 most important Passions?</strong></label>
                    <textarea
                        id="passions"
                        name="passions"
                        style={{ height: "150px" }}
                        defaultValue={brandForm.passions}
                        ref={register({
                            required: "You must provide the passions of your company",
                        }
                        )} />
                    {errors.passions && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.passions.message} </Label>}
                </div>
            </section>
            <Divider section style={{ margin: "0 3rem" }} />
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "2rem auto" }}>
                <h2>6. What are your most important Assets besides your physical plant, if applicable?</h2>
                <p style={{ marginBottom: "0" }}><strong>Definition:</strong> The strengths and best qualities that make you good at what you do.</p>
                <h5><strong>Examples:</strong></h5>
                <ul style={{ marginTop: "0" }}>
                    <li>Awards</li>
                    <li>Customer Lists</li>
                    <li>Loyalty of customers to our company</li>
                    <li>Media Coverage</li>
                    <li>Patents</li>
                    <li>etc..</li>
                </ul>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="assets" style={{ marginBottom: "1rem" }}><strong>What are your 6 to 12 most important Assets?</strong></label>
                    <textarea
                        id="assets"
                        name="assets"
                        style={{ height: "150px" }}
                        defaultValue={brandForm.assets}
                        ref={register({
                            required: "You must provide your company us with your company assets",
                        }
                        )} />
                    {errors.assets && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.assets.message} </Label>}
                </div>
            </section>
            <div style={{ width: "80%", margin: "0 auto", textAlign: "right" }}>
                <button style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginBottom: "2rem", marginRight: "1rem" }} onClick={() => { history.push(`${url}/valuesgoals`); }}>Previous</button>
                <button type="submit" style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginBottom: "2rem" }}>Next</button>
            </div>
        </form>
    )
}

export default PassionAssets;