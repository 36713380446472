import React, { useState, useEffect, useRef } from 'react'
import { Button, Modal, Form, Menu, Divider } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const AddTradeShow = ({ tradeshows, setTradeshows, originalText }) => {

    const { handleSubmit, register, watch } = useForm();
    const [tradePresences, setTradePresences] = useState([]);
    const [open, setOpen] = useState(false);
    const exhibiting = useRef({});
    exhibiting.current = watch("1", false);
    const reserved = useRef({});
    reserved.current = watch("booth_space_reserved", false);

    useEffect(() => {
        axiosWithAuth()
            .get(`/tradepresences`)
            .then(res => {
                setTradePresences(res.data)
            }).catch(err => {
                console.log(err)
            })
    }, [])

    const dataFormatting = data => {
        let persences = []
        let dataObject = {}
        for (const [key, value] of Object.entries(data)) {
            if (typeof value === "boolean") {
                if (key !== "booth_space_reserved") {
                    if (value === true) {
                        persences.push(Number(key))
                    }
                } else {
                    if (value === true) {
                        dataObject[key] = 1
                    } else {
                        dataObject[key] = 0
                    }
                }
            } else {
                dataObject[key] = value
            }
        }
        dataObject["companies_id"] = Number(originalText)
        dataObject["presences"] = persences
        return dataObject
    }

    const handleAddingTradeshow = (data) => {
        const myData = dataFormatting(data)
        axiosWithAuth()
            .post(`/tradeshows`, myData)
            .then(res => {
                setTradeshows([...tradeshows, res.data.tradeShowArr[0]])
                setOpen(false)
            }).catch(err => { console.log(err) })

    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={<Menu.Item>Add Tradeshow</Menu.Item>}
        >
            <Modal.Header>Add Tradeshow</Modal.Header>
            <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                <div style={{ width: "100%" }}>
                    <Form >
                        <section style={{ display: "flex", textAlign: "left", justifyContent: "space-evenly" }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "95%" }}>
                                <label htmlFor="name" >Tradeshow Event Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    ref={register({
                                        required: "Please provide the name of the Trade Show"
                                    })}
                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: "95%" }}>
                                <label htmlFor="type_of_show" >Tradeshow Event Type</label>
                                <select
                                    type="text"
                                    name="type_of_show"
                                    id="type_of_show"
                                    ref={register({
                                        required: "Please indicate the type of tradeshow "
                                    })}
                                >
                                    <option value="In-Person">In-Person</option>
                                    <option value="Virtual" >Virtual</option>
                                </select>
                            </div>
                        </section>
                        <p style={{ textAlign: "left", margin: "2rem 0rem 1rem 2rem" }}>Please select the category(s) that best applies to your trade event goals</p>
                        <section style={{ display: "flex", justifyContent: "space-evenly", margin: "1rem" }}>
                            {tradePresences && tradePresences.length > 0 && tradePresences.map(trade => (
                                <div key={trade.id}>
                                    <input
                                        type="checkbox"
                                        name={trade.id}
                                        id={trade.id}
                                        style={{ marginRight: ".5rem" }}
                                        ref={register({
                                            required: false,
                                        })}
                                    />
                                    <label htmlFor={trade.id}>{trade.name}</label>
                                </div>
                            ))}
                        </section>
                        {exhibiting.current === true && (
                            <div style={{ textAlign: "left", margin: "3rem 1rem 1rem 1rem" }}>
                                <Divider style={{ margin: "3rem 1rem 2rem 1rem " }} />
                                <input
                                    type="checkbox"
                                    name="booth_space_reserved"
                                    id="booth_space_reserved"
                                    style={{ marginRight: ".5rem", marginLeft: "1rem" }}
                                    ref={register({
                                        required: false,
                                    })}
                                />
                                <label htmlFor="booth_space_reserved">Please select if a booth has been reserved</label>
                            </div>
                        )}
                        {reserved.current === true && (
                            <section style={{ display: "flex", textAlign: "left", justifyContent: "space-evenly", marginBottom: "2rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "95%" }}>
                                    <label htmlFor="booth_number" >Booth Number</label>
                                    <input
                                        type="text"
                                        name="booth_number"
                                        id="booth_number"
                                        ref={register({
                                            required: true,
                                            valueAsNumber: "the value must be a number"
                                        })}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "95%" }}>
                                    <label htmlFor="approximate_dimensions" >Approximate Booth Dimensions</label>
                                    <input
                                        type="text"
                                        name="approximate_dimensions"
                                        id="approximate_dimensions"
                                        ref={register({
                                            required: true
                                        })}
                                    />
                                </div>
                            </section>
                        )}
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button type="submit" onClick={handleSubmit(handleAddingTradeshow)} basic color='yellow'>
                    Save
        </Button>
                <Button onClick={() => setOpen(false)} basic color='red'>
                    Exit
        </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default AddTradeShow;