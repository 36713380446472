import React, { useState } from 'react'
import { Button, Modal, Form, Label, Menu } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const AddProduct = ({ setProducts, companyID }) => {

    const { handleSubmit, errors, register } = useForm();
    const [open, setOpen] = useState(false);

    const handleAddingProduct = (data) => {
        axiosWithAuth()
            .post(`/products`, { products: [{...data, companies_id: Number(companyID)}] })
            .then(res => {
                setProducts(res.data);
                setOpen(false)
            }).catch(err => { console.log(err) })
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={<Menu.Item>Add Product</Menu.Item>}
        >
            <Modal.Header>Add Product</Modal.Header>
            <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div>
                    <Form style={{ width: "700px", margin: "0 auto", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            defaultValue={""}
                            name="name"
                            id="name"
                            ref={register({
                                required: "You must provide a product name",
                                minLength: {
                                    value: 2,
                                    message: "Product must be at least 2 characters"
                                }
                            })}
                        />
                        {errors.name && <Label basic color='red' pointing >* {errors.name.message} *</Label>}
                        <label htmlFor="percentage" style={{ marginTop: "1rem" }}>Percentage</label>
                        <input
                            type="text"
                            defaultValue={""}
                            name="percentage"
                            id="percentage"
                            ref={register({
                                required: "You must provide a percentage between 1-100",
                                valueAsNumber: true,
                                validate: (value) => value <= 100 && value >= 1 || "You must provide a percentage between 1-100",
                            })} />
                        {errors.percentage && <Label basic color='red' pointing >* {errors.percentage.message} *</Label>}
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button type="submit" onClick={handleSubmit(handleAddingProduct)} basic color='yellow'>
                    Save
        </Button>
                <Button onClick={() => setOpen(false)} basic color='red'>
                    Exit
        </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default AddProduct;