import { useForm } from "react-hook-form";
import { Label, Divider } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth";


const FeaturesDifferentiation = ({ brandForm, setBrandForm, url }) => {

    const { handleSubmit, errors, register } = useForm();
    const history = useHistory();

    const handlePut = data => {
        data["features_differentiation_complete"] = 1;
        axiosWithAuth()
            .put(`/brandpositioning/${brandForm.id}`, data)
            .then(res => {
                setBrandForm(res.data)
                history.push(`${url}/positioningvalidation`);
            }).catch(err => { console.log(err) })

    }

    return (
        <form onSubmit={handleSubmit(handlePut)}>
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "5rem auto 3rem auto" }}>
                <h2>7. What are the most important Features of your business, and of the products or services you offer?</h2>
                <p style={{ marginBottom: "0" }}><strong>Definition:</strong> One- or two-word descriptions of what you do or how you interact with team members and customers/clients, and how you would like your customers to feel as they interact with your products or services.</p>
                <h5><strong>Examples:</strong></h5>
                <ul style={{ marginTop: "0" }}>
                    <li>Best-in-Class</li>
                    <li>Business-to-Business (B2B)</li>
                    <li>Business-to-Consumer (B2C)</li>
                    <li>Comfortable</li>
                    <li>Consistent</li>
                    <li>etc..</li>
                </ul>
                <div style={{ display: "flex", flexDirection: "column", }}>
                    <label htmlFor="business_features" style={{ marginBottom: "1rem" }}><strong>What are the 6 to 12 most important Features of your business?</strong></label>
                    <textarea
                        id="business_features"
                        name="business_features"
                        style={{ height: "150px" }}
                        defaultValue={brandForm.business_features}
                        ref={register({
                            required: "You must provide the business features of your company",
                        }
                        )} />
                    {errors.business_features && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.business_features.message} </Label>}
                </div>
            </section>
            <Divider section style={{ margin: "0 3rem" }} />
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "2rem auto" }}>
                <h2>8. What are your Points of Differentiation?</h2>
                <p style={{ marginBottom: "0" }}><strong>Definition:</strong> The characteristics or actions that set your products or services, or company apart from how competitors behave or what they offer.</p>
                <h5><strong>Examples:</strong></h5>
                <ul style={{ marginTop: "0" }}>
                    <li>Performs under the harshest conditions</li>
                    <li>Treat team members and customers like family or friends</li>
                    <li>Take the time to understand clients’ challenges</li>
                    <li>Everything is made to order</li>
                    <li>Everything under one roof to maintain control over entire process and accelerate delivery time</li>
                    <li>etc..</li>
                </ul>
                <div style={{ display: "flex", flexDirection: "column", }}>
                    <label htmlFor="differentiation_points" style={{ marginBottom: "1rem" }}><strong>What are your 6 to 12 most important differentiation_points?</strong></label>
                    <textarea
                        id="differentiation_points"
                        name="differentiation_points"
                        style={{ height: "150px" }}
                        defaultValue={brandForm.differentiation_points}
                        ref={register({
                            required: "You must provide your company us with your company differentiation points",
                        }
                        )} />
                    {errors.differentiation_points && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.differentiation_points.message} </Label>}
                </div>
            </section>
            <div style={{ width: "80%", margin: "0 auto", textAlign: "right" }}>
                <button style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginBottom: "2rem", marginRight: "1rem" }} onClick={() => { history.push(`${url}/passionsassets`); }}>Previous</button>
                <button type="submit" style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginBottom: "2rem" }}>Next</button>
            </div>
        </form>
    )
}

export default FeaturesDifferentiation;