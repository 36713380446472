import { connect } from 'react-redux';
import { useEffect, useState } from "react";
import { useParams, Route, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth.js"
import Breadcrumbs from "../breadcrumbs";
import Websites from "./websites";
import TypeOfAdvertising from "./typeofadvertising";
import TradePublications from "./tradepublications";
import { fillOutAdvertising } from "../../reduxStore/actions/companies-actions";
import CryptoJS from "crypto-js";

const Advertising = ({ fillOutAdvertising }) => {

    const { id } = useParams();
    let { path, url } = useRouteMatch();
    const [advertising, setAdvertising] = useState({});
    const history = useHistory();
    const breadcrumbPath = [{ name: "Dashboard", url: `/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}` }, { name: "Advertising" }]
    const location = useLocation();
    var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu") );
    var companyID = byteId.toString(CryptoJS.enc.Utf8);

    var findLastCompletedForm = (form) => {
        if (form.companies_id === Number(companyID)) {
            if (form.websites_complete === false) {
                history.push(`${url}/websites`);
            } else if (form.trade_publications_complete === false) {
                history.push(`${url}/tradepublications`);
            } else if (form.form_complete === false) {
                history.push(`${url}/typeofadvertising`);
            } else {
                history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
            }
        } else {
            history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
        }
    };

    useEffect(async () => {
        var advertisingForm = (await axiosWithAuth().get(`/advertising/${id}`)).data;
        debugger;
        if (advertisingForm) {
            if (location.pathname === `/advertising/${id}` || location.pathname === `/advertising/${id}/websites` || location.pathname === `/advertising/${id}/tradepublications` || location.pathname === `/advertising/${id}/typeofadvertising`) {
                setAdvertising(advertisingForm)
                findLastCompletedForm(advertisingForm)
            }
        } else {
            history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
        }

    }, [url.path, location.pathname])


    return (
        <div style={{ paddingBottom: "5rem" }}>
            <div style={{ width: "90%", margin: "0 auto", textAlign: "left", marginTop: "2rem" }}>
                <Breadcrumbs path={breadcrumbPath} />
            </div>
            <div style={{ backgroundColor: "rgb(250,250,250)", width: "55%", margin: "0 auto", boxShadow: "6px 7px 3px 1px #C6C6C6" }} >
                <div style={{ marginTop: "2rem", color: "rgb(51,51,51)", fontSize: "2rem", padding: "1rem", backgroundColor: "rgb(255,191,77)" }}>Advertising Form</div>
                <Route path={`${path}/websites`}>
                    <Websites advertising={advertising} setAdvertising={setAdvertising} url={url} formId={id} formName="websites" fillOutAdvertising={fillOutAdvertising} history={history} originalText={companyID} />
                </Route>
                <Route path={`${path}/tradepublications`}>
                    <TradePublications advertising={advertising} setAdvertising={setAdvertising} url={url} formId={id} formName="tradepublications" fillOutAdvertising={fillOutAdvertising} history={history} originalText={companyID} />
                </Route>
                <Route path={`${path}/typeofadvertising`}>
                    <TypeOfAdvertising advertising={advertising} setAdvertising={setAdvertising} url={url} formId={id} formName="advertising" fillOutAdvertising={fillOutAdvertising} history={history} />
                </Route>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        company: state.companiesReducer.company,
    }
}

export default connect(mapStateToProps, { fillOutAdvertising })(Advertising);