import { Route, Switch } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import EmployeeDashboard from "./components/employeeDashboard";
import Login from "./components/login";
import EmployeeLogin from './components/employeeLogin';
import Settings from './components/settings';
import Signup from './components/signup';
import CompanyDetails from "./components/companyDetails";
import Brands from "./components/gettingStarted/brands";
import Websites from "./components/gettingStarted/websites";
import Socials from "./components/gettingStarted/socials";
import MarketingEval from "./components/gettingStarted/marketingEval";
import ServiceSelection from "./components/gettingStarted/serviceSelection";
import CompanyDashboard from "./components/companyDashboard";
import NoneUserNav from "./components/non-user-nav/index.js";
import MainNavigation from "./components/navigations/index.js";
import AddCompany from "./components/addCompany";
import AddLevyUser from "./components/addLevyUser";
import ManageLevyUsers from "./components/manageLevyUsers";
import ArTourForm from "./components/artourform";
import BrandPositioning from "./components/brandpositioning";
import MarketMapping from "./components/marketmapping";
import TradeEvents from "./components/tradeevents";
import Advertising from "./components/advertising";
import ManageForms from "./components/manageforms";
import UserSettings from "./components/usersettings";
import ManageServices from "./components/manageservices";
import MarketingEvaluation from "./components/marketingevaluation";
import PrivateRouteLevy from "./helperFunctions/privateroutelevy";
import Footer from "./components/footer";
import PasswordReset from "./components/passwordReset";
import PasswordForm from "./components/requestPasswordReset";
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <div className="App" style={{ height: "100%", position: "relative" }}>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          <Switch>
            <Route exact path="/">
              <NoneUserNav />
              <Signup />
            </Route>
            <Route path="/evaluation.php">
              <NoneUserNav />
              <Signup />
            </Route>
            <Route path="/login">
              <NoneUserNav />
              <Login />
            </Route>
            <Route path="/employeelogin">
              <NoneUserNav />
              <EmployeeLogin />
            </Route>
            <Route exact path="/reset">
              <NoneUserNav />
              <PasswordForm isInternalUser={false} />
            </Route>
            <Route path="/passwordreset/:token">
              <NoneUserNav />
              <PasswordReset isInternalUser={false} />
            </Route>
            <Route exact path="/resetinternaluser">
              <NoneUserNav />
              <PasswordForm isInternalUser={true} />
            </Route>
            <Route path="/passwordresetinternaluser/:token">
              <NoneUserNav />
              <PasswordReset isInternalUser={true} />
            </Route>
            <PrivateRouteLevy>
              <Route path="/companydetails/:id">
                <MainNavigation />
                <CompanyDetails />
              </Route>
              <Route path="/employeedashboard/">
                <MainNavigation />
                <EmployeeDashboard />
              </Route>
              <Route path="/dashboard/:id">
                <MainNavigation />
                <CompanyDashboard />
              </Route>
              <Route path="/settings">
                <MainNavigation />
                <Settings />
              </Route>
              <Route path="/accountsettings">
                <MainNavigation />
                <UserSettings />
              </Route>
              <Route path="/brands">
                <MainNavigation />
                <Brands />
              </Route>
              <Route path="/websites">
                <MainNavigation />
                <Websites />
              </Route>
              <Route path="/serviceselection">
                <MainNavigation />
                <ServiceSelection />
              </Route>
              <Route path="/socials">
                <MainNavigation />
                <Socials />
              </Route>
              <Route path="/marketingevaluation">
                <MainNavigation />
                <MarketingEval />
              </Route>
              <Route path="/addcompany">
                <MainNavigation />
                <AddCompany />
              </Route>
              <Route path="/addlevyuser">
                <MainNavigation />
                <AddLevyUser />
              </Route>
              <Route path="/managelevyusers">
                <MainNavigation />
                <ManageLevyUsers />
              </Route>
              <Route path="/artours/:id">
                <MainNavigation />
                <ArTourForm />
              </Route>
              <Route path="/brandpositioning/:id">
                <MainNavigation />
                <BrandPositioning />
              </Route>
              <Route path="/marketmapping/:id">
                <MainNavigation />
                <MarketMapping />
              </Route>
              <Route path="/tradeevents/:id">
                <MainNavigation />
                <TradeEvents />
              </Route>
              <Route path="/advertising/:id">
                <MainNavigation />
                <Advertising />
              </Route>
              <Route path="/forms/:id">
                <MainNavigation />
                <ManageForms adminView={false} />
              </Route>
              <Route path="/manageservices">
                <MainNavigation />
                <ManageServices />
              </Route>
              <Route path="/marketingevaluationform">
                <MainNavigation />
                <MarketingEvaluation />
              </Route>
            </PrivateRouteLevy>
          </Switch>
        </SnackbarProvider>
      </div>
      <Footer />
    </div>
  );
}

export default App;
