function Covid({ register, errors, marketingEvaluationForm }) {
    return (
        <>
            <div style={{ padding: "0% 2%", marginTop: "1rem", display: "flex", flexDirection: "column" }}>
                <label htmlFor="covid_19" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Has COVID-19 impacted the way you generated leads?</label>
                <select
                    style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="covid_19"
                    id="covid_19"
                    defaultValue={marketingEvaluationForm.covid_19}
                    ref={register({ required: "Please indicate if COVID-19 has impacted the way you generate leads" })}
                >
                    <option value={""} hidden>Choose One</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                {errors.covid_19 && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.covid_19.message} *</p>}
            </div>
            <div style={{ marginTop: "1rem", paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                <label htmlFor="covid_19_reasoning" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Please explain:</label>
                <textarea
                    type="text"
                    name="covid_19_reasoning"
                    id="covid_19_reasoning"
                    defaultValue={marketingEvaluationForm.covid_19_reasoning}
                    style={{ width: "30rem", height: "7rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    ref={register({ required: false, maxLength: 250 })}
                />
            </div>
        </>
    );
}

export default Covid;