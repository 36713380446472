import { useForm } from "react-hook-form";
import { Label, Modal, Button, Icon, Header, Divider } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { useState, useRef } from "react"
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth";


const PositioningValidation = ({ brandForm, url }) => {

    const { handleSubmit, errors, register, watch } = useForm();
    const [open, setOpen] = useState(false);
    const [myData, setMyData] = useState({})
    const history = useHistory();
    // Watching our two uncontrolled inputs to make sure the user does leave them blank
    const validation = useRef({});
    const positioning = useRef({});
    positioning.current = watch("positioning_statements", brandForm.positioning_statements);
    validation.current = watch("validation_points", brandForm.validation_points);

    const handleDataFormatting = data => {
        data["positioning_validation_complete"] = 1;
        data["form_complete"] = 1;
        setMyData(data)
    }

    const handleMyDataPut = () => {
        axiosWithAuth()
            .put(`/brandpositioning/${brandForm.id}`, myData)
            .then(res => {
                history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
            }).catch(err => { console.log(err) })
    }

    return (
        <form onSubmit={handleSubmit(handleDataFormatting)}>
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "5rem auto 3rem auto" }}>
                <h2>9. What are your Validation Points?</h2>
                <p style={{ marginBottom: "0" }}><strong>Definition:</strong> Things you’ve done, or want to do, to demonstrate Points of Differentiation or thought leadership.</p>
                <h5><strong>Examples:</strong></h5>
                <ul style={{ marginTop: "0" }}>
                    <li>Make the most of metallurgical properties to deliver top performance</li>
                    <li>Reliable brands that mean something to customers</li>
                    <li>See opportunity where others see obstacles</li>
                    <li>Know the “big picture” like nobody else</li>
                    <li>The highest level of expertise you’ll find anywhere</li>
                    <li>Our top and senior management team spent many years as customers of companies like ours, so we know what’s needed</li>
                </ul>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="validation_points" style={{ marginBottom: "1rem" }}><strong>What are the 3 to 6 Validation Points that distinguish your company from competitors?</strong></label>
                    <textarea
                        id="validation_points"
                        name="validation_points"
                        style={{ height: "150px" }}
                        defaultValue={brandForm.validation_points}
                        ref={register({
                            required: "You must provide the validation_points of your company",
                        }
                        )} />
                    {errors.validation_points && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.validation_points.message} </Label>}
                </div>
            </section>
            <Divider section style={{ margin: "0 3rem" }} />
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "2rem auto" }}>
                <h2>10. What is the Positioning Statement for each brand you manufacture or carry?</h2>
                <p style={{ marginBottom: "0" }}><strong>Definition:</strong> The written and verbal expressions of the value proposition for each brand, or product or service you provide.</p>
                <h5><strong>Examples:</strong></h5>
                <p>We use mother nature as our inspiration to develop technologies and solutions that meet requirements of the toughest wear applications.</p>
                <p>Generations of Americans have used our power tools to create valuable products and family heirlooms.</p>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="positioning_statements" style={{ marginBottom: "1rem" }}><strong>What 3 to 6 Positioning Statements reflect the value your products or services bring to the marketplace?</strong></label>
                    <textarea
                        id="positioning_statements"
                        name="positioning_statements"
                        style={{ height: "150px" }}
                        defaultValue={brandForm.positioning_statements}
                        ref={register({
                            required: "You must provide your company us with your company positioning_statements",
                        }
                        )} />
                    {errors.positioning_statements && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.positioning_statements.message} </Label>}
                </div>
            </section>
            <div style={{ width: "80%", margin: "0 auto", textAlign: "right" }}>
                <button style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginBottom: "2rem", marginRight: "1rem" }} onClick={() => { history.push(`${url}/featuresdifferentiation`); }}>Previous</button>
                <Modal
                    basic
                    onClose={() => setOpen(false)}
                    onOpen={() => {
                        // checking to make sure the text areas are completed before rendering the confirm submission modal
                        if (validation.current.length > 0 && positioning.current.length > 0) {
                            setOpen(true)
                        }
                    }}
                    open={open}
                    size='small'
                    trigger={<button style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginBottom: "2rem" }}>Submit</button>}
                >
                    <Header icon><Icon name='send' />Brand Positioning Form</Header>
                    <Modal.Content>
                        <p style={{ fontWeight: 700 }}>After submission, this form will be sent for review and will only be accessible in the form management tab located in settings. Are you sure you want to submit?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic color='red' inverted onClick={() => setOpen(false)}>
                            <Icon name='remove' /> No
                        </Button>
                        <Button type="submit" color='green' inverted onClick={() => {
                            handleMyDataPut()
                            setOpen(false)
                        }}>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </form>
    )
}

export default PositioningValidation;