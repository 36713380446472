import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';

const PrivateRouteLevy = (props) => {
  return (
    <Fragment>
      {localStorage.getItem('KTyE65sP') ? props.children : <Redirect to="/login" />}
    </Fragment>
  );
};
export default PrivateRouteLevy;