function MailingList({ register, errors, marketingEvaluationForm }) {
    return (
        <>
            <div style={{ padding: "0% 2%", display: "flex", flexDirection: "column" }}>
                <label htmlFor="mailing_list" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Do you have a mailing list?</label>
                <select
                    style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="mailing_list"
                    id="mailing_list"
                    defaultValue={marketingEvaluationForm.mailing_list}
                    ref={register({ required: "Please indicate whether you have a mailing list" })}>
                    <option value={""} hidden>Choose One</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                {errors.mailing_list && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.mailing_list.message} *</p>}
            </div>
            <div style={{ marginTop: "1rem", paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                <label htmlFor="number_of_contact" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>How many active contacts do you currently have?</label>
                <select
                    style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="number_of_contact"
                    id="number_of_contact"
                    defaultValue={marketingEvaluationForm.number_of_contacts}
                    ref={register({ required: false })}
                >
                    <option hidden value={""}>Approximately</option>
                    <option value={"1-10"}>1-5</option>
                    <option value={"10-50"}>5-10</option>
                    <option value={"50-200"}>15-20</option>
                    <option value={"200+"}>20+</option>
                </select>
            </div>
            <div style={{ marginTop: "1rem", paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                <label htmlFor="contact_system" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600, }}>What email system do you use?</label>
                <input
                    type="text"
                    name="contact_system"
                    id="contact_system"
                    defaultValue={marketingEvaluationForm.contact_system}
                    placeholder="MailChimp, iContact, Constant Contact…"
                    style={{ width: "30rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    ref={register({ required: false, maxLength: 60 })}
                />
            </div>
        </>
    );
}

export default MailingList;