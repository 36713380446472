import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import welcome from "../../internalData/GH_Client Dashboard_Header.gif";
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 300,
    width: "100%"
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <>
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    </>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const CustomizedDialogs = ({ handleClose, open }) => {
  const classes = useStyles();

  return (
    <>
      {open === true && (
        <div>
          <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
            <DialogTitle id="customized-dialog-title" onClose={handleClose} />
            <DialogContent style={{ backgroundColor: "#383838", color: "white", padding: "1rem" }} >
              <CardMedia
                component="img"
                className={classes.media}
                image={welcome}
                title="Welcome Message"
              />
              <Typography id="modal-modal-description" sx={{ mt: 3 }} style={{ marginTop: "2rem", fontSize: "16px" }}>
                Welcome to your personalized GrowthHive dashboard! You’ve successfully filled out our Marketing Evaluation form and selected your desired services. A member of our team will be in touch with you shortly to schedule a discovery meeting.
              </Typography>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
}

export default CustomizedDialogs;