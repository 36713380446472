import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import InformationDialogs from './InformationDialog';
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';
import ServiceSelectionCard from "./serviceSelectionCards";
import CurrentPageFooter from "../gettingStartedUtils/currentPageFooter";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    body: {
        margin: "2rem 0rem",
        fontSize: "20px"
    }
}));

const ServiceSelection = () => {

    const history = useHistory();
    const [services, setServices] = useState([]);
    const [open, setOpen] = useState(true);
    var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
    var companyID = byteId.toString(CryptoJS.enc.Utf8);
    const classes = useStyles();

    useEffect(async () => {
        setServices((await axiosWithAuth().get(`/companies/${companyID}/services`)).data);
    }, []);

    async function handleMakingCompanyInterested(id, changes) {
        var updatedService = (await axiosWithAuth().put(`/servcomp/${id}`, changes)).data;
        setServices(services.map(service => {
            if (service.id !== updatedService.id) {
                return service;
            } else {
                return updatedService;
            }
        }));
    };

    const advanceToDashboard = () => {
        history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <InformationDialogs open={open} handleClose={handleClose} />
            <Container style={{ marginTop: "2rem", marginBottom: "2rem", textAlign: "right" }}>
                <Button style={{ cursor: "pointer", backgroundColor: "rgb(247,197,70)" }} onClick={advanceToDashboard}>Advance to Dashboard</Button>
            </Container>
            <Container style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                {services && services.length > 0 &&
                    services.map(service => (
                        <ServiceSelectionCard service={service} key={service.id} handleMakingCompanyInterested={handleMakingCompanyInterested} />
                    ))
                }
            </Container>
            <CurrentPageFooter page="serviceSelection" />
        </>
    )
};

export default ServiceSelection;