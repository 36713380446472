import { useState, useEffect } from "react";
import { Grid } from 'semantic-ui-react';
import AllCompaniesGrid from "./allCompaniesGrid";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth";
import "../../../App.css";


function CompaniesOverview() {
  const [ clients, setClients ] = useState([]);
  const [ accountManagers, setAccountManagers ] = useState([]);
  const [camClients, setCamClients] = useState("all")

  
  useEffect(() => {
    axiosWithAuth()
      .get(`/users/`)
      .then(res => setAccountManagers(res.data))
      .catch(err => console.log(err) )
  }, [])

  useEffect(() => {
    if (camClients === "all"){
      axiosWithAuth()
        .get(`/companies`)
        .then(res => setClients(res.data.reverse()))
        .catch(err => console.log(err) )
    }
    else{
      axiosWithAuth()
        .get(`/users/${camClients}/companies`)
      .then(res => setClients(res.data.reverse()))
      .catch(err => console.log(err))
    }
  }, [camClients])

  const handleClientFilter = e =>{
      setCamClients(e.target.value)
  }
  

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingBottom: "5rem", color: "black", marginTop: "2rem"}}>
      <div style={{marginBottom: "2rem", width: "90%"}}>
        <div style={{ padding: "5px",display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
          <label htmlFor="accountmanager" style={{paddingRight: "5px", fontSize: "1.3rem"}}>Filter:</label>
          <select id="accountmanager" name="accountmanager" onChange={handleClientFilter} style={{width: "14rem", height: "1.7rem", fontSize: "1rem"}}>
              <option value="all">All</option>
              {accountManagers && accountManagers.map(theUser =>{

                if (theUser.id !== Number(localStorage.getItem("JFZR5qve"))){
                  return (<option key={theUser.id} value={theUser.id}>{theUser.first_name} {theUser.last_name}</option>)
                }else {
                  return (<option key={theUser.id} value={theUser.id}>Your Client's</option>)
                }
              })}
            </select>
        </div>
      </div>
      <Grid style={{ width: "1250px"}} columns={6} padded>
        <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", backgroundColor: "grey", color: "white" }}>Company</Grid.Column>
        <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", backgroundColor: "grey", color: "white" }}>Contact Person</Grid.Column>
        <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", backgroundColor: "grey", color: "white" }}>Email</Grid.Column>
        <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "grey", color: "white" }}>Phone</Grid.Column>
        <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "grey", color: "white" }}>Account Manager</Grid.Column>
        <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "grey", color: "white" }} className="grid-item">Actions</Grid.Column>
      {clients && clients.length > 0 && clients.map(client => (
        <AllCompaniesGrid company={client} key={client.id} /> 
      ))}
      {clients && clients.length === 0 && <div style={{margin: "10rem auto"}}>They don't have any clients in our system</div>}
    </Grid>
    </div>
  );
}

export default CompaniesOverview;