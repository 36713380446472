import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import CurrentPageFooter from "../gettingStartedUtils/currentPageFooter";
import HelperButtons from "../gettingStartedUtils/brands-socials-webs-buttons";
import BrandInput from "./brandInput.js";
import { handleAddingInput, handleRemovingURL } from "../gettingStartedUtils/handleInputs";
import { filterData } from "../gettingStartedUtils/dataFormating";
import { getCompanyInfo, fillOutForm } from "../../../reduxStore/actions/companies-actions";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js";
import { Form } from "semantic-ui-react";
import CryptoJS from "crypto-js";


function Brands({ getCompanyInfo, company, fillOutForm }) {

  const { register, handleSubmit, errors } = useForm();
  const [numberURL, setNumberURL] = useState(1)
  const [inputs, setInputs] = useState(["name0"])
  const history = useHistory()
  var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
  var theId = byteId.toString(CryptoJS.enc.Utf8);

  const checkStatus = info => {
    if (info.brand_form === true) {
      if (info.social_form === true) {
        if (info.marketing_evaluation_form === true) {
          history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
        } else {
          history.push("/marketingevaluation")
        }
      } else {
        history.push("/socials")
      }
    }
  }

  useEffect(() => {
    // Checking to see if a company has completed this form already and if the redux store has the company information or if we should reload it
    if (company && company.brands_form) {
      checkStatus(company)
    } else {
      getCompanyInfo(theId)
        .then(res => {
          if (res && res.id > 0) {
            checkStatus(res)
          }
        })
    }
  }, [])

  const handleNextForm = data => {
    fillOutForm("brands", filterData(data, "name", "brands", theId))
      .then(res => {
        history.push("/socials")
      })
      .catch(err => console.log(err))
  }

  const handleSkip = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .put(`/companies/${company.id}`, { brand_form: true })
      .then(res => history.push("/socials"))
      .catch(err => console.log(err))
  }

  return (
    <>
      {company && company.brand_form === false &&
        <>
          <div class="getting-started-stepper">
            <div style={{ backgroundColor: "rgb(255,191,77)", width: "100%", textAlign: "center" }}>
              <div style={{ fontSize: "2rem", padding: "1rem", color: "rgb(51,51,51)" }}>Brands</div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "space-between", minHeight: "175px", justifyContent: "space-between" }}>
              <section style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <p style={{ padding: "1rem" }}>Please add all brands associated with your company.</p>
                <Form id="websiteForm" onSubmit={handleSubmit(handleNextForm)} style={{ width: "90%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div style={{ fontWeight: 700, fontSize: "18px", textAlign: "left", width: "85%", marginBottom: ".5rem" }}>Brand Name:</div>
                  {inputs && inputs.map((name, i) => (
                    <BrandInput
                      key={`${i}`}
                      name={name}
                      register={register}
                      errors={errors}
                      handleRemovingURL={handleRemovingURL}
                      i={i}
                      inputs={inputs}
                      setInputs={setInputs}
                      numberURL={numberURL}
                      setNumberURL={setNumberURL}
                      numberOfInput={1}
                    />
                  ))}
                  <HelperButtons
                    setInputs={setInputs}
                    inputs={inputs}
                    numberURL={numberURL}
                    setNumberURL={setNumberURL}
                    handleAddingInput={handleAddingInput}
                    comp={"brand"}
                    numberOfInput={1}
                    handleSkip={handleSkip}
                  />
                </Form>
              </section>
            </div>
          </div>
          <CurrentPageFooter page="brands" />
        </>
      }
    </>
  );
}

const mapStateToProps = state => {
  return {
    company: state.companiesReducer.company,
  }
}


export default connect(mapStateToProps, { getCompanyInfo, fillOutForm })(Brands);