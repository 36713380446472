import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../breadcrumbs";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth";
import Panes from "./panes"

const CompanyDetails = () => {

    const [breadcrumbPath, setBreadcumbsPath] = useState([{ name: "Dashboard", url: "/employeedashboard" }])
    const [user, setUser] = useState(undefined)
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var companiesId = byteId.toString(CryptoJS.enc.Utf8);


    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${companiesId}`)
            .then(res => {
                if (breadcrumbPath.length === 1) {
                    setBreadcumbsPath([...breadcrumbPath, { name: `${res.data.name}'s Detailed View` }])
                    setUser(res.data)
                }
            }).catch(err => { console.log(err) })
    }, [companiesId,breadcrumbPath])

    return (
        <div>
            <div style={{ width: "90%", margin: "4rem auto 2rem auto", textAlign: "left", marginTop: "2rem" }}>
                <Breadcrumbs path={breadcrumbPath} />
            </div>
            {user && <Panes user={user} />}
        </div>
    )
}

export default CompanyDetails;