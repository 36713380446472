import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { getUserInfo } from "../../reduxStore/actions/user-actions";
import AllServicesGrid from "./allServicesGrid"
import { Grid } from 'semantic-ui-react';
import Breadcrumbs from "../breadcrumbs";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from '../../helperFunctions/axiosWithAuth';

function ManageServices({ user, getUserInfo }) {

    const history = useHistory();
    const [services, setServices] = useState([])
    const breadcrumbPath = [{ name: "Dashboard", url: "/employeedashboard" }, { name: "Service Management" }]
    var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    var byteType = CryptoJS.AES.decrypt(localStorage.getItem("YGVtSKYr"), localStorage.getItem("SWassWQWiu"));
    var theType = byteType.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        if (theType === "4") {
            // then check the user to see if that is 4
            history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
        }
        getUserInfo(theId)
    }, [])

    useEffect(() => {
        axiosWithAuth()
            .get('/services')
            .then(res => {
                setServices(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <div style={{ paddingBottom: "5rem" }}>
            <div style={{ width: "90%", margin: "0 auto", textAlign: "left", marginTop: "2rem" }}>
                <Breadcrumbs path={breadcrumbPath} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "4rem", zIndex: "-1" }}>
                <Grid style={{ width: "90%" }} columns={4} padded >
                    <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", backgroundColor: "grey", color: "white" }}>Image</Grid.Column>
                    <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", backgroundColor: "grey", color: "white" }}>Name</Grid.Column>
                    <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", backgroundColor: "grey", color: "white" }}>Headline</Grid.Column>
                    <Grid.Column style={{ display: "flex", alignItems: "center", backgroundColor: "grey", color: "white", justifyContent: "center" }}>Actions</Grid.Column>
                    {services && services.length > 0 && services.map(service => (
                        <AllServicesGrid key={service.id} service={service} services={services} setServices={setServices} />
                    ))}
                </Grid>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        user: state.usersReducer.user,
    }
}


export default connect(mapStateToProps, { getUserInfo })(ManageServices);