import { useForm } from "react-hook-form";
import { Label, Divider } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth";


const CompanyMission = ({ brandForm, setBrandForm, url }) => {

    const { handleSubmit, errors, register } = useForm();
    const history = useHistory();

    const handlePut = data => {
        data["company_mission_complete"] = 1;
        axiosWithAuth()
            .put(`/brandpositioning/${brandForm.id}`, data)
            .then(res => {
                setBrandForm(res.data)
                history.push(`${url}/valuesgoals`);
            }).catch(err => { console.log(err) })

    }

    return (
        <form onSubmit={handleSubmit(handlePut)}>
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "5rem auto 3rem auto" }}>
                <h2>1. What is your Vision as a company?</h2>
                <p style={{ marginBottom: "0" }}><strong>Definition:</strong> Who are you? What do you aspire to be?</p>
                <h5><strong>Examples:</strong></h5>
                <p>Our company’s vision is to develop breakthrough technologies inspired by nature to dramatically improve the performance of consumer and industrial products.</p>
                <p>Our company is the authority in premium cookware. We bring together craftmanship, technology and innovation to help professional and amateur chefs realize their potential in the kitchen.</p>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="company_vision" style={{ marginBottom: "1rem" }}><strong>In 20 to 50 words, what is your Corporate Vision?</strong></label>
                    <textarea
                        id="company_vision"
                        name="company_vision"
                        style={{ height: "150px" }}
                        defaultValue={brandForm.company_vision}
                        ref={register({
                            required: "You must provide your company vision",
                            minLength: {
                                value: 20,
                                message: "Company vision must be at least 20 words long"
                            }
                        }
                        )} />
                    {errors.company_vision && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.company_vision.message} </Label>}
                </div>
            </section>
            <Divider section style={{ margin: "0 3rem" }} />
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "2rem auto" }}>
                <h2>2. What is your Corporate Mission Statement?</h2>
                <p style={{ marginBottom: "0" }}><strong>Definition:</strong> Why does your company exist? What is its reason for being?</p>
                <h5><strong>Examples:</strong></h5>
                <p>Leveraging more than a decade of aerodynamics research and engineering, ABC Company brings innovative aerospace parts and components to the world’s leading aircraft manufacturers. We maintain our technological advantage by staying true to our core values and culture.</p>
                <p>By partnering with clients to solve MRO supply chain problems, we provide clients the strategies and tactics they can use to keep their MRO spending under control and help the company maintain or improve its competitive advantage.</p>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="mission_statement" style={{ marginBottom: "1rem" }}><strong>In 30 to 50 words, what is your Corporate Mission?</strong></label>
                    <textarea
                        id="mission_statement"
                        name="mission_statement"
                        style={{ height: "150px" }}
                        defaultValue={brandForm.mission_statement}
                        ref={register({
                            required: "You must provide your company mission statement",
                            minLength: {
                                value: 30,
                                message: "Company mission statement must be at least 30 words long"
                            }
                        }
                        )} />
                    {errors.mission_statement && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.mission_statement.message} </Label>}
                </div>
            </section>
            <div style={{ width: "80%", margin: "0 auto", textAlign: "right" }}>
                <button type="submit" style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginBottom: "2rem" }}>Next</button>
            </div>
        </form>
    )
}

export default CompanyMission;