import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';


const DoubleInput = ({ inputName, inputURL, register, errors, handleRemovingURL, inputs, i, setInputs, setNumberURL, numberURL, numberOfInputs }) => {

    const media = ["linkedin", "facebook", "instagram", "twitter", "youtube", "reddit", "pinterest", "quora", "other"];

    function capitalizeFirstLetter(string) {
        if (string === "linkedin") {
            const newLinked = string.charAt(0).toUpperCase() + string.slice(1)
            return newLinked.slice(0, 6) + newLinked.charAt(6).toUpperCase() + string.slice(7)
        } else {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }

    return (
        <>
            {inputName && inputURL && (
                <div style={{ display: "flex", alignItems: "flex-start", width: "100%", marginTop: ".5rem", }}>
                    <div style={{ width: "35%", display: "flex", flexDirection: "column" }}>
                        <label htmlFor={inputName} style={{ fontWeight: 700, fontSize: "18px", textAlign: "left" }}></label>
                        <select
                            style={{ width: "100%" }}
                            name={inputName}
                            id={inputName}
                            defaultValue={""}
                            ref={register({ required: "Required field" })}
                        >
                            <option hidden value={""}>Choose Option</option>
                            {media && media.map(media => (<option key={media} value={media}>{capitalizeFirstLetter(media)}</option>))}
                        </select>
                        {errors[inputName] && <p style={{ color: "red", margin: ".2rem 0rem" }}>* {errors[inputName].message} *</p>}
                    </div>
                    <div style={{ width: "50%", marginLeft: ".5rem" }}>
                        <label htmlFor={inputURL} style={{ fontWeight: 700, fontSize: "18px", textAlign: "left" }}></label>
                        <input
                            style={{ width: "100%" }}
                            type="text"
                            name={inputURL}
                            id={inputURL}
                            defaultValue={""}
                            ref={register({
                                required: "Required field",
                                pattern: {
                                    value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
                                    message: "Please provide a valid url"
                                }
                            })
                            }
                        />
                        {errors[inputURL] && <p style={{ color: "red", margin: ".2rem 0rem" }}>* {errors[inputURL].message} *</p>}
                    </div>
                    {i === inputs.length - 1 &&
                        <button
                            onClick={(e) => { handleRemovingURL(inputName, inputs, setInputs, setNumberURL, numberURL, e, numberOfInputs) }}
                            style={{ width: "3rem", backgroundColor: "rgb(255,191,77)", padding: ".4rem .5rem", fontSize: ".8rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginLeft: ".5rem" }}>
                            <FontAwesomeIcon className='top-links' icon={faTrashAlt} />
                        </button>
                    }
                </div>
            )}
        </>
    )
}

export default DoubleInput;