import { Container, Typography, Chip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@mui/icons-material/Send';
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "2rem 0rem 2rem 0rem",
        width: 200,
    },
    chipCss: {
        padding: "1rem",
        fontSize: "16px",
        backgroundColor: "rgb(247,197,70)",
        cursor: "pointer",
        opacity: ".8",
        "&:hover": {
            opacity: "1",
        }
    }
}));

const EmailSupport = ({ company }) => {

    const classes = useStyles();

    return (
        <Container className={classes.root} align="right">
            <a href="mailto:trisha@levyind.com">
                <Chip
                    icon={<SendIcon />}
                    label={"Email Support"}
                    className={classes.chipCss}
                />
            </a>
        </Container>
    )
}

export default EmailSupport;