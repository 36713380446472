import { useState } from "react";
import { useHistory } from "react-router-dom";
import LoginForm from "./loginForm";
import { connect } from 'react-redux';
import { login } from '../../reduxStore/actions/user-actions';


function Login({ login }) {

  const history = useHistory();
  const [loginError, setLoginError] = useState("");
  const [user, setUser] = useState({
    email: "",
    password: ""
  });


  const handleLogin = () => {
    setLoginError("")
    login(user)
      .then(response => {
        if (response.user) {
          history.push(`/employeedashboard`)
        } else {
          if (response.errorMessage){
            setLoginError(response.errorMessage)
          } else {
            setLoginError("Unexpected Error")
          }
        }
      })
  }

  const handleChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "7rem 0rem" }}>
      <LoginForm handleLogin={handleLogin} user={user} handleChange={handleChange} loginError={loginError} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.usersReducer.user
  }
}

export default connect(mapStateToProps, { login })(Login)