import { connect } from 'react-redux';
import { useEffect, useState } from "react";
import { useParams, Route, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth.js";
import Breadcrumbs from "../breadcrumbs";
import { fillOutArToursForm } from "../../reduxStore/actions/companies-actions";
import Facilitiies from "./facilities";
import Brands from "./brands";
import GeneralInfomation from "./generalinformation";
import CryptoJS from "crypto-js";


const ArTourForm = ({ fillOutArToursForm }) => {

    const { id } = useParams();
    let { path, url } = useRouteMatch();
    const [arToursform, setArToursForm] = useState({});
    const history = useHistory();
    const breadcrumbPath = [{ name: "Dashboard", url: `/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}` }, { name: "AR Tours" }]
    const location = useLocation();
    var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    const findLastCompletedForm = (form) => {
        // checking to make sure the user accessing this form is the owner of the form
        if (form.companies_id === Number(theId)) {
            // setting route to where the user last left off completing form
            if (form && Object.keys(form).length > 0)
                if (form.facilities_complete === false) {
                    history.push(`${url}/facilities`);
                } else if (form.brands_complete === false) {
                    history.push(`${url}/brands`);
                } else if (form.form_complete === false) {
                    history.push(`${url}/general`);
                } else {
                    history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
                }
        } else {
            history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
        }
    };

    useEffect(() => {
        axiosWithAuth()
            .get(`/artours/${id}`)
            .then(res => {
                // checking to make sure the default component isn't rendering without one of the nested routes
                if (location.pathname === `/artours/${id}` || location.pathname === `/artours/${id}/facilities` || location.pathname === `/artours/${id}/brands` || location.pathname === `/artours/${id}/general`) {
                    setArToursForm(res.data)
                    findLastCompletedForm(res.data)
                }
            })
            .catch(err => history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`))
    }, [url.path, location.pathname])


    return (
        <div style={{ paddingBottom: "5rem" }}>
            <div style={{ width: "90%", margin: "0 auto", textAlign: "left", marginTop: "2rem" }}>
                <Breadcrumbs path={breadcrumbPath} />
            </div>
            <div style={{ backgroundColor: "rgb(250,250,250)", width: "55%", margin: "0 auto", boxShadow: "6px 7px 3px 1px #C6C6C6" }} >
                <div style={{ marginTop: "2rem", color: "rgb(51,51,51)", fontSize: "2rem", padding: "1rem", backgroundColor: "rgb(255,191,77)" }}>AR Tours Form</div>
                <Route path={`${path}/facilities`}>
                    <Facilitiies arToursform={arToursform} setArToursForm={setArToursForm} url={url} formId={id} formName="facilities" fillOutArToursForm={fillOutArToursForm} history={history} originalText={theId} />
                </Route>
                <Route path={`${path}/brands`}>
                    <Brands arToursform={arToursform} setArToursForm={setArToursForm} url={url} formId={id} formName="brands" fillOutArToursForm={fillOutArToursForm} history={history} originalText={theId} />
                </Route>
                <Route path={`${path}/general`}>
                    <GeneralInfomation arToursform={arToursform} setArToursForm={setArToursForm} url={url} formId={id} formName="artours" fillOutArToursForm={fillOutArToursForm} history={history} />
                </Route>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        company: state.companiesReducer.company,
    }
}


export default connect(mapStateToProps, { fillOutArToursForm })(ArTourForm);