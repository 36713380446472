import axios from "axios";

export const axiosWithAuth = () => {
  const token = localStorage.getItem("KTyE65sP"); 
  return axios.create({
    headers: {
      authorization: token,
    },
    baseURL: process.env.REACT_APP_API_BASE,
    //baseURL: "https://growth-hive-develop-branch.herokuapp.com/api/",

    // Production API
    // baseURL: "https://growth-hive.herokuapp.com/api/",
    // Development API
    // baseURL: "https://growth-hive-develop-branch.herokuapp.com/api/"
    // Locally Ran API 
    // baseURL: "http://localhost:5003/api/",
  });
};