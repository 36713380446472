import React from 'react';
import { Item, Label } from 'semantic-ui-react';

const ServiceCard = ({ headline, name, description, image, handleMakingCompanyInterested, serviceID, companiesServiceID, handleInterestingCardAndFormPush }) => {
    return (
        <Item.Group style={{ width: "75%", boxShadow: "2px 2px 2px 3px #dcdcdc", padding: "1rem", zIndex: "10", margin: "2rem auto", }}>
            <Item style={{ textAlign: "left" }}>
                <Item.Image size='small' src={image} />
                <Item.Content>
                    <Item.Header>{name}</Item.Header>
                    <Item.Meta>
                        <span className='cinema'>{headline}</span>
                    </Item.Meta>
                    <Item.Description>{description}</Item.Description>
                    <Item.Extra>
                        <Label style={{ cursor: "pointer" }} onClick={() => handleInterestingCardAndFormPush(companiesServiceID, serviceID)}>Get Started</Label>
                        <Label style={{ cursor: "pointer", backgroundColor: "rgb(247,197,70)" }} onClick={() => handleMakingCompanyInterested(companiesServiceID, true)}>Queue For Later</Label>
                    </Item.Extra>
                </Item.Content>
            </Item>
        </Item.Group>
    )
};

export default ServiceCard;