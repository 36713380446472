import { useState, useEffect } from "react";
import { Label, Button, Divider, Form } from 'semantic-ui-react';
import { useForm } from "react-hook-form";

const Websites = ({ advertising, setAdvertising, url, formId, formName, fillOutAdvertising, history, originalText }) => {
    
    const [websites, setWebsites] = useState(["name"]);
    const { handleSubmit, errors, register } = useForm();

    useEffect(() => {
        if (advertising.websites_complete === true) {
            history.push(`${url}/tradepublications`)
        }
    }, [])


    const handleAddingInput = (myI, e) => {
        e.preventDefault()
        if (myI < 5) {
            setWebsites([...websites, `name${websites.length}`])
        }
    }


    const handleRemovingInput = (myI, e) => {
        e.preventDefault()
        if (websites.length > 1) {
            setWebsites(websites.filter(website => {
                if (website !== `name${myI}`) {
                    return website
                }
            }))
        }
    }


    const formatData = data => {
        let formattedData = []
        for (const [key, value] of Object.entries(data)) {
            formattedData.push({ url: value, companies_id: Number(originalText), "trade_advertising_forms_id": Number(formId) })
        }
        return { "websites": formattedData, "isForm": true }
    }


    const handlePost = data => {
        fillOutAdvertising(formName, formatData(data))
            .then(res => {
                setAdvertising({
                    ...advertising,
                    websites_complete: true
                })
                history.push(`${url}/tradepublications`);
            })
            .catch(err => { console.log(err) })
    }


    return (
        <div style={{ textAlign: "left", padding: "2rem" }}>
            <div style={{ fontSize: "2rem", margin: "1rem 0rem" }}><strong>Websites</strong></div>
            <p>Please List up to six websites.</p>
            <p><strong>NOTE: Websites currently in our system do not need to be entered again</strong></p>
            <div>
                <Form onSubmit={handleSubmit(handlePost)} style={{ display: "flex", flexDirection: "column" }}>
                    {websites && websites.map((website, i) => (
                        <div key={i} style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor={website}>Website URL {i + 1}</label>
                            <div style={{ display: "flex", margin: ".5rem 0rem" }}>
                                <input
                                    type="text"
                                    name={website}
                                    id={website}
                                    defaultValue={"https://"}
                                    style={{ width: "85%", marginRight: "1rem" }}
                                    ref={register({
                                        required: "Please provide a valid URL",
                                        pattern: {
                                            value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,
                                            message: "Please provide a valid url starting with HTTP or HTTPS"
                                        }
                                    })}
                                />
                                {i === websites.length - 1 &&
                                    <div>
                                        <Button icon="add" color="yellow" onClick={(e) => handleAddingInput(i, e)} />
                                        <Button icon="x" onClick={(e) => handleRemovingInput(i, e)}></Button>
                                    </div>}
                            </div>
                            {errors[website] && <Label basic color='red' style={{ textAlign: "center", width: "85%", marginBottom: ".5rem" }} pointing>{errors[website].message}</Label>}
                        </div>
                    ))}
                    <Divider />
                    <div style={{ marginTop: ".5rem", textAlign: "left", display: "flex", justifyContent: "flex-end" }}>
                        <Button type="submit" color="yellow">Submit</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}


export default Websites;