import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const BrandInput = ({ name, register, errors, handleRemovingURL, inputs, i, setInputs, setNumberURL, numberURL, numberOfInput }) => {
    return (
        <div key={i} style={{ display: "flex", margin: "4px auto", alignItems: "flex-start", width: "100%", marginLeft: "2rem" }}>
            <div style={{ width: "75%", marginRight: ".5rem" }}>
                <label htmlFor={name} style={{ fontWeight: 700, fontSize: "18px", textAlign: "left" }}></label>
                <input
                    style={{ width: "100%" }}
                    type="text"
                    name={name}
                    id={name}
                    defaultValue={""}
                    ref={register({
                        required: "Please provide the brand name",
                        minLength: {
                            value: 2,
                            message: "Brand must be at least 2 characters"
                        }
                    })}
                />
                {errors[name] && <p style={{ color: "red", margin: ".2rem 0rem 0rem 0rem" }}>* {errors[name].message} *</p>}
            </div>
            {i === inputs.length - 1 &&
                <button
                    onClick={(e) => { handleRemovingURL(name, inputs, setInputs, setNumberURL, numberURL, e, numberOfInput) }}
                    style={{ width: "3rem", backgroundColor: "rgb(255,191,77)", padding: ".4rem .5rem", fontSize: ".8rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginLeft: ".5rem" }}>
                    <FontAwesomeIcon className='top-links' icon={faTrashAlt} />
                </button>
            }
        </div>
    )
}

export default BrandInput;