function Ecommerce({ inputs, handleChange, register, errors }) {
    return (
        <>
            <div style={{ padding: "0% 2%", display: "flex", flexDirection: "column" }}>
                <label htmlFor="e_commerce" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Do you have e-commerce?</label>
                <select
                    style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="e_commerce"
                    id="e_commerce"
                    onChange={handleChange}
                    ref={register({ required: "Please indicate whether you have e-commerce" 
                })}
                >
                    <option value={""} hidden>Choose One</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                {errors.e_commerce && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.e_commerce.message} *</p>}
            </div>
            {inputs.e_commerce === true && (
                <div style={{ marginTop: "1rem", paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                    <label htmlFor="number_of_products" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>How many products or SKUs do you sell via e-commerce?</label>
                    <select
                        name="number_of_products"
                        id="number_of_products"
                        style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                        ref={register({ required: "Please provide an approximate number of SKUs or products"})}
                    >
                        <option hidden value={""}>Approximately</option>
                        <option value={"1-10"}>1-10</option>
                        <option value={"10-50"}>10-50</option>
                        <option value={"50-200"}>50-200</option>
                        <option value={"200-1,000"}>200-1,000</option>
                        <option value={"1,000-10,000"}>1,000-10,000</option>
                        <option value={"10,000+"}>10,000+</option>
                    </select>
                    {errors.number_of_products && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.number_of_products.message} *</p>}
                </div>
            )}
            {inputs.e_commerce === false && (
                <div style={{ marginTop: "1rem", paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                    <label htmlFor="interested_in_ecommerce" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Are you interested in e-commerce?</label>
                    <select style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                        name="interested_in_ecommerce"
                        id="interested_in_ecommerce"
                        ref={register({ required: "Please indicate whether you are interested in e-commerce" 
                    })}
                    >
                        <option value={""} hidden>Choose One</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </select>
                    {errors.interested_in_ecommerce && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.interested_in_ecommerce.message} *</p>}
                </div>
            )}
        </>
    );
}

export default Ecommerce;