import { Table, Button, Menu, Header } from 'semantic-ui-react';
import { useEffect, useState } from "react";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js";
import { useParams } from "react-router-dom";
import ManageSingleTradeshow from "./managesingletradeshow";
import AddTradeShow from "./addtradeshow";
import CryptoJS from "crypto-js";

const TradeshowTable = ({ userDash }) => {

    const [tradeshows, setTradeshow] = useState([])
    const [tradeP, setTradeP] = useState({})
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${theId}/tradeshows`)
            .then(res => {
                setTradeshow(res.data)
            }).catch(err => console.log(err))
    }, [])

    useEffect(() => {
        const myObj = {}
        tradeshows.map(trade => {
            axiosWithAuth()
                .get(`/tradeshowpresences/${trade.id}/`)
                .then(res => {
                    myObj[`${trade.id}`] = res.data
                    setTradeP({ ...myObj })
                }).catch(err => console.log(err))
        })
    }, [tradeshows])

    const handleRemovingTradeshow = (tradeshowId) => {
        axiosWithAuth()
            .delete(`/tradeshows/${tradeshowId}`)
            .then(res => {
                setTradeshow(tradeshows.filter(trade => {
                    if (trade.id !== tradeshowId) {
                        return trade
                    }
                }))
            }).catch(err => console.log(err))
    }

    return (
        <>
            {tradeshows && tradeshows.length > 0 ? (
                <>
                    {userDash === true && <Header style={{ textAlign: "left", width: "1100px", margin: "2rem auto -2rem auto" }}>Tradeshows</Header>}
                    <Table celled style={{ width: "1100px", margin: "3rem auto" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Show Type</Table.HeaderCell>
                                <Table.HeaderCell>Booth Number</Table.HeaderCell>
                                <Table.HeaderCell>Dimensions</Table.HeaderCell>
                                <Table.HeaderCell>Presence</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {tradeshows.map(tradeshow => (
                                <Table.Row key={tradeshow.id}>
                                    <Table.Cell style={{ width: "15%" }}>{tradeshow.name}</Table.Cell>
                                    <Table.Cell style={{ width: "15%" }}>{tradeshow.type_of_show}</Table.Cell>
                                    <Table.Cell style={{ width: "15%" }}>{tradeshow.booth_number || "N/A"}</Table.Cell>
                                    <Table.Cell style={{ width: "15%" }}>{tradeshow.approximate_dimensions || "N/A"}</Table.Cell>
                                    <Table.Cell style={{ width: "15%" }}>{tradeP[tradeshow.id] && tradeP[tradeshow.id].length > 0 ? tradeP[tradeshow.id].map(presence => (
                                        <div style={{ border: "solid 1px rgb(222,222,223)", textAlign: "center", margin: ".2rem 0rem", padding: ".5rem", fontWeight: 600, borderRadius: "10px" }} key={presence.id}>{presence.name}</div>
                                    )) : "N/A"}</Table.Cell>
                                    <Table.Cell style={{ width: "25%", textAlign: "center" }}><ManageSingleTradeshow tradeshow={tradeshow} tradeshows={tradeshows} setTradeshows={setTradeshow} /><Button color="red" basic onClick={() => handleRemovingTradeshow(tradeshow.id)}>Delete</Button></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='6'>
                                    <Menu floated='right'>
                                        <AddTradeShow tradeshows={tradeshows} setTradeshows={setTradeshow} originalText={theId} />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </>)
                :
                <div style={{ padding: "15rem 0", textAlign: "center" }}>There haven't been any trade shows reported</div>
            }
        </>
    )
}

export default TradeshowTable;