import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Divider, Form } from "semantic-ui-react";
import Facilities from "../../manageproperties/facilitiestable";
import Brands from "../../manageproperties/brandstable";
import { useForm } from "react-hook-form";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth";


const ArTours = () => {
    const [arTourForm, setArTourForm] = useState();
    const { id } = useParams();
    const { handleSubmit, register } = useForm();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${theId}/artoursform/`)
            .then(res => {
                setArTourForm(res.data.arTourForm[0])
            }).catch(err => { console.log(err) })
    }, [theId])

    const handlePutRequest = (data) => {
        if (data.number_of_facilities !== arTourForm.number_of_facilities || data.description_of_needs !== arTourForm.description_of_needs || data.publish_new_data !== arTourForm.publish_new_data) {
            axiosWithAuth()
                .put(`/artours/${arTourForm.id}`, data)
                .then(res => {
                    setArTourForm(res.data.arTourForm)
                }).catch(err => { console.log(err) })
        }
    }

    return (
        <div>
            {arTourForm && Object.keys(arTourForm).length > 0 ? (
                <Form onSubmit={handleSubmit(handlePutRequest)} style={{ width: "900px", margin: "2rem auto", textAlign: "left", display: "flex", flexDirection: "column" }}>
                    <label htmlFor="number_of_facilities">Number of Facilities</label>
                    <input
                        style={{ width: "125px", margin: ".5rem 0" }} defaultValue={arTourForm.number_of_facilities}
                        name="number_of_facilities"
                        id="number_of_facilities"
                        ref={register({
                            required: false,
                            valueAsNumber: true
                        })}
                    />
                    <label style={{ margin: "1rem 0rem" }} htmlFor="description_of_needs">Description</label>
                    <textarea
                        defaultValue={arTourForm.description_of_needs}
                        name="description_of_needs"
                        id="description_of_needs"
                        ref={register({
                            required: false,
                        })}
                    />
                    <label htmlFor="publish_new_data" style={{ margin: "1rem 0rem" }}>Publishing New Data</label>
                    <textarea
                        id="publish_new_data"
                        name="publish_new_data"
                        style={{ height: "150px" }}
                        defaultValue={arTourForm.publish_new_data}
                        ref={register({})}
                    />
                    <div style={{ margin: "1rem 0rem 0rem 0rem", display: "flex", justifyContent: "flex-end" }}>
                        <Button type="submit" color="yellow" basic>Save</Button>
                    </div>
                </Form>
            ) :
                <div style={{ padding: "5rem 0", textAlign: "center" }}>They haven't started an AR Tour form yet</div>
            }
            <Divider style={{ margin: "3rem 6rem" }} />
            <Facilities userDash={true} />
            <Divider style={{ margin: "3rem 6rem" }} />
            <Brands userDash={true} arToursFormView={true}/>
        </div>
    )
}

export default ArTours;