import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth.js";
import CryptoJS from "crypto-js";

// get request for company/login
export const FETCHING_COMPANY = "FETCHING_COMPANY";
export const GETTING_COMPANY_SUCCESS = "GETTING_COMPANY_SUCCESS";
export const GETTING_COMPANY_FAILURE = "GETTING_COMPANY_FAILURE";

//getting all companies for user
export const FETCHING_COMPANIES = "FETCHING_COMPANIES";
export const GETTING_COMPANIES_SUCCESS = "GETTING_COMPANIES_SUCCESS";
export const GETTING_COMPANIES_FAILURE = "GETTING_COMPANIES_FAILURE";

// delete request for COMPANY
export const DELTEING_COMPANY = "DELETING_COMPANY";
export const DELETING_COMPANY_SUCCESS = "DELETING_COMPANY_SUCCESS";
export const DELETING_COMPANY_FAILURE = "DELETING_COMPANY_FAILURE";

// sign up request for COMPANY
export const CREATING_NEW_COMPANY = "CREATING_NEW_COMPANY";
export const CREATING_NEW_COMPANY_SUCCESS = "CREATING_NEW_COMPANY_SUCCESS";
export const CREATING_NEW_COMPANY_FAILURE = "CREATING_NEW_COMPANY_FAILURE";

// posting a new added on service form
export const CREATING_NEW_FORM = "CREATING_NEW_FORM";
export const CREATING_NEW_FORM_SUCCESS = "CREATING_NEW_FORM_SUCCESS";
export const CREATING_NEW_FORM_FAILURE = "CREATING_NEW_FORM_FAILURE";

// actions for completes inital sign up forms
// sign up request for COMPANY
export const CREATING_NEW_ONBOARDING_FORM = "CREATING_NEW_ONBOARDING_FORM";
export const CREATING_NEW_BRANDS_FORM_SUCCESS = "CREATING_NEW_BRANDS_FORM_SUCCESS";
export const CREATING_NEW_WEBISITE_FORM_SUCCESS = "CREATING_NEW_WEBISITE_FORM_SUCCESS";
export const CREATING_NEW_SOCIALS_FORM_SUCCESS = "CREATING_NEW_SOCIALS_FORM_SUCCESS";
export const CREATING_NEW_MARKETINGEVALUATION_FORM_SUCCESS = "CREATING_NEW_MARKETINGEVALUATION_FORM_SUCCESS";
export const CREATING_NEW_ONBOARDING_FORM_FAILURE = "CREATING_NEW_ONBOARDING_FORM_FAILURE";

//user creating company 
export const ADMIN_CREATING_NEW_COMPANY = "ADMIN_CREATING_NEW_COMPANY";
export const ADMIN_CREATING_NEW_COMPANY_SUCCESS = "ADMIN_CREATING_NEW_COMPANY_SUCCESS";
export const ADMIN_CREATING_NEW_COMPANY_FAILURE = "ADMIN_CREATING_NEW_COMPANY_FAILURE";

// Actions use to submit a market mapping form
export const SUBMITTING_MARKET_MAPPING_FORM = "SUBMITTING_MARKET_MAPPING_FORM";
export const CHANNELS_SUBMITTED_SUCESSFULLY = "CHANNELS_SUBMITTED_SUCESSFULLY";
export const MARKETS_SUBMITTED_SUCESSFULLY = "MARKETS_SUBMITTED_SUCESSFULLY";
export const GEOGRAPHICS_SUBMITTED_SUCESSFULLY = "GEOGRAPHICS_SUBMITTED_SUCESSFULLY";
export const PRODUCTS_SUBMITTED_SUCESSFULLY = "PRODUCTS_SUBMITTED_SUCESSFULLY";
export const SUBMITTING_MARKET_MAPPING_FORM_FAILURE = "SUBMITTING_MARKET_MAPPING_FORM_FAILURE";

// actions used to submit ar tour forms
export const SUBMITTING_AR_TOURS_FORM = "SUBMITTING_AR_TOURS_FORM";
export const FACILITIES_SUBMITTED_SUCESSFULLY = "FACILITIES_SUBMITTED_SUCESSFULLY";
export const BRANDS_SUBMITTED_SUCESSFULLY = "BRANDS_SUBMITTED_SUCESSFULLY";
export const AR_TOUR_FORM_SUBMITTED_SUCESSFULLY = "AR_TOUR_FORM_SUBMITTED_SUCESSFULLY";
export const SUBMITTING_AR_TOURS_FAILURE = "SUBMITTING_AR_TOURS_FAILURE";

// actions used to submit Advertising form
export const SUBMITTING_ADVERTISING_FORM = "SUBMITTING_ADVERTISING_FORM";
export const WEBSITES_SUBMITTED_SUCESSFULLY = "WEBSITES_SUBMITTED_SUCESSFULLY";
export const TRADE_PUBLICATIONS_SUBMITTED_SUCESSFULLY = "TRADE_PUBLICATIONS_SUBMITTED_SUCESSFULLY";
export const ADVERTISING_FORM_SUBMITTED_SUCESSFULLY = "ADVERTISING_FORM_SUBMITTED_SUCESSFULLY";
export const SUBMITTING_ADVERTISING_FAILURE = "SUBMITTING_ADVERTISING_FAILURE";

// updating information
export const UPDATING_COMPANY = "UPDATING_COMPANY";
export const UPDATING_COMPANY_SUCCESS = "UPDATING_COMPANY_SUCCESS";
export const UPDATING_COMPANY_FAILURE = "UPDATING_COMPANY_FAILURE";

// logout 
export const COMPANY_LOGGING_OUT = "COMPANY_LOGGING_OUT";

export const companyLogin = company => (dispatch) => {
    dispatch({ type: FETCHING_COMPANY });

    return axiosWithAuth()
        .post(`auth/login`, company)
        .then(res => {
            var random = `${Math.floor(Math.random() * 100000 + 1) + res.data.user.id + Math.floor(Math.random() * 100000 + 1) / Math.floor(Math.random() * 1000 + 1)}`;
            var cipherId = CryptoJS.AES.encrypt(`${res.data.user.id}`, random).toString();
            var cipherType = CryptoJS.AES.encrypt(`${res.data.user.user_types_id}`, random).toString();

            localStorage.setItem("KTyE65sP", res.data.token);
            localStorage.setItem("JFZR5qve", cipherId);
            localStorage.setItem("SWassWQWiu", random);
            localStorage.setItem("YGVtSKYr", cipherType);

            dispatch({ type: GETTING_COMPANIES_SUCCESS, payload: res.data.user });
            return res.data;
        })
        .catch(error => {
            return error.response.data;
        });
};

export const fillOutForm = (form_name, information) => dispatch => {
    dispatch({ type: CREATING_NEW_ONBOARDING_FORM });

    return axiosWithAuth()
        .post(`${form_name}/`, information)
        .then(res => {
            if (form_name === "websites") {
                dispatch({ type: CREATING_NEW_WEBISITE_FORM_SUCCESS, payload: res.data });
            } else if (form_name === "brands") {
                dispatch({ type: CREATING_NEW_BRANDS_FORM_SUCCESS, payload: res.data });
            } else if (form_name === "socials") {
                dispatch({ type: CREATING_NEW_SOCIALS_FORM_SUCCESS, payload: res.data });
            } else {
                dispatch({ type: CREATING_NEW_MARKETINGEVALUATION_FORM_SUCCESS, payload: res.data });
            }
        }).catch(error => console.log(error));
};

export const fillOutMarketMappingForm = (form_name, information) => dispatch => {
    dispatch({ type: SUBMITTING_MARKET_MAPPING_FORM });

    return axiosWithAuth()
        .post(`${form_name}/`, information)
        .then(res => {
            if (form_name === "markets") {
                dispatch({ type: MARKETS_SUBMITTED_SUCESSFULLY, payload: res.data });
                return res;
            } else if (form_name === "channels") {
                dispatch({ type: CHANNELS_SUBMITTED_SUCESSFULLY, payload: res.data });
                return res;
            } else if (form_name === "geographics") {
                dispatch({ type: GEOGRAPHICS_SUBMITTED_SUCESSFULLY, payload: res.data });
                return res;
            } else {
                dispatch({ type: PRODUCTS_SUBMITTED_SUCESSFULLY, payload: res.data });
                return res;
            }
        }).catch(error => console.log(error));
};

export const fillOutArToursForm = (form_name, information) => dispatch => {
    dispatch({ type: SUBMITTING_AR_TOURS_FORM });

    return axiosWithAuth()
        .post(`${form_name}/`, information)
        .then(res => {
            if (form_name === "facilities") {
                dispatch({ type: FACILITIES_SUBMITTED_SUCESSFULLY, payload: res.data });
                return res;
            } else if (form_name === "brands") {
                dispatch({ type: BRANDS_SUBMITTED_SUCESSFULLY, payload: res.data });
                return res;
            } else {
                dispatch({ type: AR_TOUR_FORM_SUBMITTED_SUCESSFULLY, payload: res.data });
                return res;
            }
        }).catch(error => console.log(error));
};

export const fillOutAdvertising = (form_name, information) => dispatch => {
    dispatch({ type: SUBMITTING_ADVERTISING_FORM });

    return axiosWithAuth()
        .post(`${form_name}/`, information)
        .then(res => {
            if (form_name === "website") {
                dispatch({ type: WEBSITES_SUBMITTED_SUCESSFULLY, payload: res.data });
                return res;
            } else if (form_name === "tradepublication") {
                dispatch({ type: TRADE_PUBLICATIONS_SUBMITTED_SUCESSFULLY, payload: res.data });
                return res;
            } else {
                dispatch({ type: AR_TOUR_FORM_SUBMITTED_SUCESSFULLY, payload: res.data });
                return res;
            }
        }).catch(error => console.log(error));
};

export const getCompanyInfo = (id) => dispatch => {
    dispatch({ type: FETCHING_COMPANY });

    return axiosWithAuth()
        .get(`companies/${id}`)
        .then(company => {
            dispatch({ type: GETTING_COMPANY_SUCCESS, payload: company.data });
            return company.data;
        }).catch(error => console.log(error));
};

export const userCreatingCompany = (user) => (dispatch) => {
    dispatch({ type: CREATING_NEW_COMPANY });

    return axiosWithAuth()
        .post(`auth/registration`, { ...user, internal: true })
        .then(response => {
            dispatch({ type: CREATING_NEW_COMPANY_SUCCESS });
            return response.data;
        })
        .catch(err => {
            dispatch({ type: CREATING_NEW_COMPANY_FAILURE, payload: err });
            return err.response;
        });
};

export const creatingCompany = (user) => (dispatch) => {
    dispatch({ type: CREATING_NEW_COMPANY });

    return axiosWithAuth()
        .post(`auth/registration`, user)
        .then(res => {

            var random = `${Math.floor(Math.random() * 100000 + 1) + res.data.user.id + Math.floor(Math.random() * 100000 + 1) / Math.floor(Math.random() * 1000 + 1)}`;
            var cipherId = CryptoJS.AES.encrypt(`${res.data.user.id}`, random).toString();
            var cipherType = CryptoJS.AES.encrypt(`${res.data.user.user_types_id}`, random).toString();

            localStorage.setItem("KTyE65sP", res.data.token);
            localStorage.setItem("JFZR5qve", cipherId);
            localStorage.setItem("SWassWQWiu", random);
            localStorage.setItem("YGVtSKYr", cipherType);

            dispatch({ type: CREATING_NEW_COMPANY_SUCCESS, payload: res.data.user });
            return res.data;
        }).catch(err => { 
            debugger;
            dispatch({ type: CREATING_NEW_COMPANY_FAILURE });
            return err.response;
        });
};

export const creatingForm = (name, myBody) => (dispatch) => {
    dispatch({ type: CREATING_NEW_FORM });

    return axiosWithAuth()
        .post(`${name}/`, myBody)
        .then(res => {
            dispatch({ type: CREATING_NEW_FORM_SUCCESS, payload: res.data });
            return res.data;
        }).catch(err => console.log(err));
};

export const cancelWelcomeModal = (companyID, companyChanges) => (dispatch) => {
    dispatch({ type: UPDATING_COMPANY });

    return axiosWithAuth()
        .put(`companies/${companyID}`, companyChanges)
        .then(res => {
            dispatch({ type: UPDATING_COMPANY_SUCCESS, payload: res.data });
        }).catch(err => {
            dispatch({ type: UPDATING_COMPANY_FAILURE, payload: err });
        });
};