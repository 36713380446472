function DigitalAds({ register, errors, marketingEvaluationForm }) {
    return (
        <div style={{ marginTop: "1rem" }}>
            <div style={{ paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                <label htmlFor="digital_ads" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Are you currently set up for Digital Ads?</label>
                <select
                    style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="digital_ads"
                    id="digital_ads"
                    defaultValue={marketingEvaluationForm.digital_ads}
                    ref={register({ required: "Please indicate whether you are set up for digital ads" })}
                >
                    <option value={""} hidden>Choose One</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                {errors.digital_ads && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.digital_ads.message} *</p>}
            </div>
            <div style={{ marginTop: "1rem", paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                <label htmlFor="digital_ads_used" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Which providers do you use?</label>
                <input
                    type="text"
                    style={{ width: "30rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="digital_ads_used"
                    id="digital_ads_used"
                    defaultValue={marketingEvaluationForm.digital_ads_used}
                    placeholder="Ad Words, LinkedIn, FaceBook…"
                    ref={register({ required: false })}
                />
            </div>
        </div>
    );
}

export default DigitalAds;