import React from 'react';
import { Link } from "react-router-dom";
import { Breadcrumb } from 'semantic-ui-react';


const Breadcrumbs = ({ path }) => (
    <>
        <Breadcrumb size="massive">
            {path && path.length === 1 &&
                <React.Fragment>
                    <Breadcrumb.Section link style={{ color: "black" }}>{path[0].name}</Breadcrumb.Section>
                </React.Fragment>
            }
            {path && path.length > 1 && path.map((component, i) => (
                <React.Fragment key={i}>
                    {i !== path.length - 1 ?
                        <>
                            <Breadcrumb.Section><Link style={{ color: "black" }} to={component.url}>{component.name}</Link></Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                        </>
                        :
                        <>
                            <Breadcrumb.Section style={{ color: "rgb(255,191,77)" }}>{component.name}</Breadcrumb.Section>
                        </>
                    }
                </React.Fragment>
            ))}
        </Breadcrumb>
    </>
)

export default Breadcrumbs;