import React, { useState } from 'react'
import { Button, Modal, Form, Image } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js"

const ManageSingleService = ({ service, setServices, services }) => {

  const [open, setOpen] = React.useState(false);
  const { handleSubmit, register } = useForm();
  const [image, setImage] = useState("")

  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };


  const handlePutRequest = data => {
    if (image === "") {
      // means they aren't trying to post a image
      if (service.headline !== data.headline || service.description !== data.description) {
        axiosWithAuth()
          .put(`services/${service.id}`, data)
          .then(res => {
            setServices(services.map(serv => {
              if (serv.id !== service.id) {
                return serv
              } else {
                return res.data
              }
            }))
            setOpen(false);
          }).catch(err => console.log(err))
      }
    } else {
      // they changed the picutre and we can go and update the service
      const formData = new FormData();
      formData.append("image", image, image.name);
      formData.set("description", data.description);
      formData.set("headline", data.headline);
      axiosWithAuth()
        .put(`services/${service.id}`, formData)
        .then(res => {
          setServices(services.map(serv => {
            if (serv.id !== service.id) {
              return serv
            } else {
              return res.data
            }
          }))
          setOpen(false);
        }).catch(err => console.log(err))
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button inverted color='yellow'>Manage</Button>}
    >
      <Modal.Header>{service.name} Serivce</Modal.Header>
      <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div>
          <Form style={{ width: "700px", margin: "0 auto", display: "flex", flexDirection: "column" }}>
            <label htmlFor="name" >Name</label>
            <p style={{ margin: ".5rem 0rem", width: "550px", border: "1px solid rgb(222,222,223)", borderRadius: "5px", padding: ".5rem 0rem .5rem 1rem" }} >{service.name}</p>
            <label htmlFor="headline" >Headline</label>
            <input
              style={{ margin: ".5rem 0rem", width: "550px" }}
              defaultValue={service.headline}
              name="headline"
              id="headline"
              ref={register({
                required: false
              })}
            />
            <label htmlFor="description" >Description</label>
            <textarea
              style={{ margin: ".5rem 0rem", width: "550px", minHeight: "150px" }}
              defaultValue={service.description}
              name="description"
              id="description"
              ref={register({
                required: false
              })}
            />
            <label htmlFor="image" >Current Image</label>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "550px", border: "1px solid rgb(222,222,223)", borderRadius: "5px", marginTop: ".5rem" }}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "525px", alignItems: "baseline", margin: ".5rem 0rem" }}>
                <input
                  type="file"
                  name="image"
                  id="image"
                  onChange={handleImageUpload}
                />
              </div>
              <Image src={service.image} size="medium" />
            </div>
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" onClick={handleSubmit(handlePutRequest)} inverted color='yellow'>
          Save
        </Button>
        <Button onClick={() => setOpen(false)} inverted color='red'>
          Exit
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ManageSingleService;