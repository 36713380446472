import CompanyInfo from "./companyInfo";
import FormStatus from "./forms";
import MarketMapping from "./marketmapping";
import BrandPositioning from "./brandpositioning";
import TradeEvents from "./tradeevents";
import ArTours from "./artours";
import Advertising from "./advertising";
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function Panels({ user }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} className={classes.root} TabIndicatorProps={{ style: { background: '#424242' } }} variant="scrollable" aria-label="simple tabs example">
                    <Tab label="Company Profile" {...a11yProps(0)} />
                    <Tab label="Service Management" {...a11yProps(1)} />
                    <Tab label="Brand Positioning" {...a11yProps(2)} />
                    <Tab label="Advertising" {...a11yProps(3)} />
                    <Tab label="Market Mapping" {...a11yProps(4)} />
                    <Tab label="Trade Events" {...a11yProps(5)} />
                    <Tab label="AR Tours" {...a11yProps(6)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <CompanyInfo user={user} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FormStatus />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <BrandPositioning />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Advertising />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <MarketMapping />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <TradeEvents />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <ArTours />
            </TabPanel>
        </>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'rgb(247,197,70)',
        color: '#424242'
    },
    needsAttention: {
        display: 'flex',
        alignItems: 'center'
    }
}));