import { Button, Grid } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";


const AllCompaniesGrid = ({ company }) => {

    const history = useHistory();

    const handleDetailedView = clientsId => {
        var cipherId = CryptoJS.AES.encrypt(`${clientsId}`, localStorage.getItem("SWassWQWiu")).toString();
        history.push(`/companydetails/${encodeURIComponent(cipherId)}`)
    }

    return (
        <>
            <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", borderBottom: "1px solid grey" }}>{company.name}</Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", borderBottom: "1px solid grey" }}>{company.first_name} {company.last_name}</Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", borderBottom: "1px solid grey" }}>{company.email}</Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid grey" }}>({company.phone_number.slice(0, 3)})-{company.phone_number.slice(3, 6)}-{company.phone_number.slice(6)}</Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid grey" }}>{company.AccManagerFirstName} {company.AccManagerLastName}</Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid grey" }}>
                <Button className="grid-button" inverted color='yellow' onClick={() => { handleDetailedView(company.id) }}>Manage</Button>
            </Grid.Column>
        </>
    )
}

export default AllCompaniesGrid