import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import CurrentPageFooter from "../gettingStartedUtils/currentPageFooter.js";
import DoubleInput from "./doubleInput.js";
import { handleAddingInput, handleRemovingURL } from "../gettingStartedUtils/handleInputs.js";
import HelperButtons from "../gettingStartedUtils/brands-socials-webs-buttons";
import { checkURLsAndProcessData } from "../gettingStartedUtils/dataFormating";
import { getCompanyInfo, fillOutForm } from "../../../reduxStore/actions/companies-actions";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js";
import { Form } from "semantic-ui-react";
import CryptoJS from "crypto-js";



function Socials({ getCompanyInfo, company, fillOutForm }) {

  const { register, handleSubmit, errors } = useForm();
  const [numberURL, setNumberURL] = useState(1);
  const [inputs, setInputs] = useState([{ name: "name0", url: "url0" }]);
  const [sameURL, setSameURL] = useState(false);
  const history = useHistory();
  var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
  var theId = byteId.toString(CryptoJS.enc.Utf8);

  const checkStatus = info => {
    if (info.social_form === true) {
      if (info.marketing_evaluation_form === true) {
        history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
      } else {
        history.push("/marketingevaluation")
      }
    }
  }

  useEffect(() => {
    // Checking to see if a company has completed this form already and if the redux store has the company information or if we should reload it
    if (company && company.socials_form) {
      checkStatus(company)
    } else {
      getCompanyInfo(theId)
        .then(res => {
          if (res && res.id > 0) {
            checkStatus(res)
          }
        })
    }
  }, [])

  const handleNextForm = data => {
    // resetting the check to see if they urls are the same
    setSameURL(false);
    const directions = checkURLsAndProcessData(data, theId);
    if (directions === false) {
      setSameURL(true)
    } else {
      fillOutForm("socials", directions)
        .then(res => {
          history.push("/marketingevaluation")
        })
        .catch(err => console.log(err))
    }
  }

  const handleSkip = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .put(`/companies/${company.id}`, { social_form: true })
      .then(res => history.push("/marketingevaluation"))
      .catch(err => console.log(err))
  }

  return (
    <>
      {company && company.social_form === false && (
        <>
          <div class="getting-started-stepper">
            <div style={{ backgroundColor: "rgb(255,191,77)", width: "100%", textAlign: "center" }}>
              <div style={{ fontSize: "2rem", padding: "1rem", color: "rgb(51,51,51)" }}>Social Media Accounts</div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "space-between", minHeight: "175px", justifyContent: "space-between" }}>
              <section style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <p style={{ padding: "1rem" }}>Please add all your professional social media accounts.</p>
                <Form id="websiteForm" onSubmit={handleSubmit(handleNextForm)} style={{ width: "90%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div style={{ fontWeight: 700, fontSize: "18px", textAlign: "left", width: "95%", display: "flex", marginBottom: "-1rem" }}>
                    <p style={{ width: "37%" }}>Name</p>
                    <p style={{ width: "40%" }}>URL</p>
                  </div>
                  {inputs && inputs.map((myInput, i) => (
                    <DoubleInput
                      key={`${i},${myInput["name"]}`}
                      inputName={myInput["name"]}
                      inputURL={myInput["url"]}
                      register={register}
                      errors={errors}
                      handleRemovingURL={handleRemovingURL}
                      i={i}
                      inputs={inputs}
                      setInputs={setInputs}
                      numberURL={numberURL}
                      setNumberURL={setNumberURL}
                      numberOfInputs={2}
                    />
                  ))}
                  {sameURL === true && <p style={{ color: "red", margin: ".5rem 2rem 0rem 0rem" }}>* You entered two identical URLs *</p>}
                  <HelperButtons
                    setInputs={setInputs}
                    inputs={inputs}
                    numberURL={numberURL}
                    setNumberURL={setNumberURL}
                    handleAddingInput={handleAddingInput}
                    comp={"social"}
                    numberOfInput={2}
                    handleSkip={handleSkip}
                  />
                </Form>
              </section>
            </div>
          </div>
          <CurrentPageFooter page="socials" />
        </>
      )}
    </>
  );
}


const mapStateToProps = state => {
  return {
    company: state.companiesReducer.company,
  }
}

export default connect(mapStateToProps, { getCompanyInfo, fillOutForm })(Socials);