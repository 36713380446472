import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    body: {
        fontSize: 18
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: 0
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <>
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        </>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const InformationDialogs = ({ handleClose, open }) => {
    const classes = useStyles();

    return (
        <>
            {open === true && (
                <div>
                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
                        <DialogTitle id="customized-dialog-title" onClose={handleClose} />
                        <DialogContent style={{ padding: "3rem" }} >
                            <Typography variant="body1" align="left" className={classes.body}>
                                Thank you for filling out our Marketing Evaluation form! A member of our team will be in touch with you shortly to discuss your specific objectives and requirements.
                            </Typography>
                            <br/>
                            <Typography variant="body1" align="left" className={classes.body}>
                                In the meantime, feel free to check out some of our services below. If you’re interested in a particular service, click the green “+” sign to add it to your GrowthHive dashboard. We will discuss your selected services in further detail during our first meeting.
                            </Typography>
                            <br/>
                            <Typography variant="body1" align="left" className={classes.body}>
                                Once you’ve selected your services of interest, click on the “Advance to Dashboard” button below.
                            </Typography>
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </>
    );
}

export default InformationDialogs;