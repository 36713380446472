import { useState } from "react";
import { useForm } from 'react-hook-form';
import { Modal, Button } from "semantic-ui-react";
import Ecommerce from "./ecommerce";
import Retailers from "./retailers";
import MailingList from "./mailingList";
import DigitalAds from "./digitalAds";
import Tradeshows from "./tradeshows";
import Webinars from "./webinars";
import Covid from "./covid";
import Ganalytics from "./google";
import { axiosWithAuth } from "../../../../../helperFunctions/axiosWithAuth";


function ManageMarketingEvaluation({ marketingEvaluationForm, setMarketingEvaluationForm }) {

  const { handleSubmit, register, errors } = useForm();
  const [open, setOpen] = useState(false);

  const handlePutRequest = data => {
    axiosWithAuth()
      .put(`/marketingevaluations/${marketingEvaluationForm.id}`, data)
      .then(res => {
        setMarketingEvaluationForm(res.data[0])
        setOpen(false)
      }).catch(err => console.log(err))
  }


  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        trigger={<Button basic color='yellow'>Manage</Button>}
      >
        <Modal.Header>Manage Marketing Evaluation Form</Modal.Header>
        <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
            <form style={{ width: "100%", display: "flex", padding: "0% 5%", flexDirection: "column" }}>
              <div style={{ padding: "2%", fontSize: "1.5rem", fontWeight: 600 }}>Go-To-Market Approach:</div>
              <Ecommerce register={register} errors={errors} marketingEvaluationForm={marketingEvaluationForm} />
              <Retailers register={register} errors={errors} marketingEvaluationForm={marketingEvaluationForm} />
              <div style={{ padding: "1% 2% 2% 2%", fontSize: "1.5rem", fontWeight: 600 }}>Current communication channels:</div>
              <MailingList register={register} errors={errors} marketingEvaluationForm={marketingEvaluationForm} />
              <DigitalAds register={register} errors={errors} marketingEvaluationForm={marketingEvaluationForm} />
              <Tradeshows register={register} errors={errors} marketingEvaluationForm={marketingEvaluationForm} />
              <Webinars register={register} errors={errors} marketingEvaluationForm={marketingEvaluationForm} />
              <Covid register={register} errors={errors} marketingEvaluationForm={marketingEvaluationForm} />
              <Ganalytics register={register} errors={errors} marketingEvaluationForm={marketingEvaluationForm} />
            </form>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button type="submit" onClick={handleSubmit(handlePutRequest)} basic color='yellow'>
            Save
        </Button>
          <Button onClick={() => setOpen(false)} basic color='red'>
            Exit
        </Button>
        </Modal.Actions>
      </Modal >
    </>
  );
}

export default ManageMarketingEvaluation;