import { connect } from 'react-redux';
import { useEffect, useState } from "react";
import { useParams, Route, useRouteMatch, useHistory } from "react-router-dom";
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth";
import CompanyMission from "./companymission";
import FeaturesDifferentiation from "./featuresdifferentiation";
import PassionsAssets from "./passionsassets";
import PositioningValidation from "./positioningvalidation";
import ValuesGoals from "./valuesgoals";
import Breadcrumbs from "../breadcrumbs";
import CryptoJS from "crypto-js";

const BrandPositioning = () => {
    const { id } = useParams();
    let { path, url } = useRouteMatch();
    const [brandForm, setBrandForm] = useState({});
    const history = useHistory();
    const breadcrumbPath = [{ name: "Dashboard", url: `/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}` }, { name: "Brand Positioning" }]
    var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);


    const findLastCompletedForm = (form) => {
        // checking to make sure the user accessing this form is the owner of the form
        if (form.companies_id === Number(theId)) {
            // setting route to where the user last left off completing form
            if (form && Object.keys(form).length > 0)
                if (form.company_mission_complete === false) {
                    history.push(`${url}/companymissioncomplete`);
                } else if (form.values_goals_complete === false) {
                    history.push(`${url}/valuesgoals`);
                } else if (form.passions_assets_complete === false) {
                    history.push(`${url}/passionsassets`);
                } else if (form.features_differentiation_complete === false) {
                    history.push(`${url}/featuresdifferentiation`)
                } else if (form.positioning_validation_complete === false) {
                    history.push(`${url}/positioningvalidation`);
                } else {
                    history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
                }
        } else {
            history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
        }
    };

    useEffect(() => {
        axiosWithAuth()
            .get(`/brandpositioning/${id}`)
            .then(res => {
                setBrandForm(res.data)
                findLastCompletedForm(res.data)
            }).catch(err => history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`))
    }, [])



    return (
        <div style={{ paddingBottom: "5rem" }}>
            <div style={{ width: "90%", margin: "2rem auto 0rem auto", textAlign: "left" }}>
                <Breadcrumbs path={breadcrumbPath} />
            </div>
            <div style={{ backgroundColor: "rgb(250,250,250)", width: "70%", margin: "0 auto", boxShadow: "6px 7px 3px 1px #C6C6C6" }} >
                <div style={{ marginTop: "2rem", marginBottom: "-3rem", color: "rgb(51,51,51)", fontSize: "2rem", padding: "1rem", backgroundColor: "rgb(255,191,77)" }}>Brand Positioning Form</div>
                <Route path={`${path}/companymissioncomplete`}>
                    <CompanyMission brandForm={brandForm} setBrandForm={setBrandForm} url={url} />
                </Route>
                <Route path={`${path}/valuesgoals`}>
                    <ValuesGoals brandForm={brandForm} setBrandForm={setBrandForm} url={url} />
                </Route>
                <Route path={`${path}/passionsassets`}>
                    <PassionsAssets brandForm={brandForm} setBrandForm={setBrandForm} url={url} />
                </Route>
                <Route path={`${path}/featuresdifferentiation`}>
                    <FeaturesDifferentiation brandForm={brandForm} setBrandForm={setBrandForm} url={url} />
                </Route>
                <Route path={`${path}/positioningvalidation`}>
                    <PositioningValidation brandForm={brandForm} setBrandForm={setBrandForm} url={url} />
                </Route>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        company: state.companiesReducer.company,
        form: state.companiesReducer.form
    }
}

export default connect(mapStateToProps, {})(BrandPositioning);