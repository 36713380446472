import Markets from "../../manageproperties/marketstable";
import Channels from "../../manageproperties/channelstable";
import Geogrpahics from "../../manageproperties/geographicstable";
import Products from "../../manageproperties/productstable"
import { Divider } from "semantic-ui-react";


const MarketMapping = () => {
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Markets userDash={true}/>
                <Divider style={{margin: "1rem 5rem"}}/>
                <Channels userDash={true}/>
                <Divider style={{margin: "1rem 5rem"}}/>
                <Geogrpahics userDash={true}/>
                <Divider style={{margin: "1rem 5rem"}}/>
                <Products userDash={true}/>
            </div>
        </div>
    )
}

export default MarketMapping;