import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import Breadcrumbs from "../breadcrumbs";
import { useHistory } from "react-router-dom";
import { creatingForm } from "../../reduxStore/actions/companies-actions";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from '../../helperFunctions/axiosWithAuth';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    tableContainer: {
        maxWidth: 1200,
        margin: "2rem auto 0rem auto"
    },
    completeChip: {
        border: `${green[600]} 1px solid`,
        color: green[600]
    },
    completeButton: {
        border: `${green[600]} 1px solid`,
        color: green[600],
        opacity: ".6",
        marginRight: "1rem",
        "&:hover": {
            opacity: "1",
        }
    },
    rowContainer: {
        display: "flex",
        justifyContent: "center"
    },
    notInterestedChip: {
        color: "rgb(255, 191, 77)",
        border: "rgb(255, 191, 77) solid 1px"
    }
});

const GreenCheckbox = withStyles({
    root: {
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ManageForms = ({ creatingForm, adminView }) => {

    const [forms, setForms] = useState([]);
    const history = useHistory();
    const { id } = useParams();
    const breadcrumbPath = [{ name: "Dashboard", url: `/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}` }, { "name": "Service/Form Management" }];
    const [advertising, setAdvertising] = useState(undefined);
    const [arTours, setArtours] = useState(undefined);
    const [brandPositioning, setBrandPositioning] = useState(undefined);
    const [tradeevents, setTradeEvents] = useState(undefined);
    const [marketMapping, setMarketMapping] = useState(undefined);
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var companyID = byteId.toString(CryptoJS.enc.Utf8);
    const classes = useStyles();

    useEffect(async () => {
        var companyServiceForms = (await axiosWithAuth().get(`/companies/${companyID}/services`)).data;
        var advertisingForm = (await axiosWithAuth().get(`/companies/${companyID}/advertisingform`)).data;
        var arToursFormArr = (await axiosWithAuth().get(`/companies/${companyID}/artoursform`)).data;
        var brandPositioningForm = (await axiosWithAuth().get(`/companies/${companyID}/brandpositoningform`)).data;
        var tradeEventsForm = (await axiosWithAuth().get(`/companies/${companyID}/tradeeventsform`)).data;
        var MarketMappingForm = (await axiosWithAuth().get(`/companies/${companyID}/marketmappingform`)).data;

        setForms(companyServiceForms);
        setAdvertising(advertisingForm);
        setArtours(arToursFormArr.arTourForm && arToursFormArr.arTourForm.length > 0 ? arToursFormArr.arTourForm[0] : {});
        setBrandPositioning(brandPositioningForm.length > 0 ? brandPositioningForm[0] : {});
        setTradeEvents(tradeEventsForm.length > 0 ? tradeEventsForm[0] : {});
        setMarketMapping(MarketMappingForm.length > 0 ? MarketMappingForm[0] : {});
    }, [])

    const handleFormRouting = (name, serviceId, e) => {
        // constructing the name of the form endpoint we are going to hit
        const myName = name.split(" ").join("").toLowerCase()
        // constructing the companies_serivce/ companies id object to create a form
        const myBody = { companies_id: companyID, companies_services_id: serviceId }
        creatingForm(myName, myBody)
            .then(res => {
                history.push(`/${myName}/${res.id}`)
            })
            .catch(err => { console.log(err) })
    }

    async function ResetCompanyServiceAndDeleteForm(formState, formName, serviceID) {
        if (formState) {
            var formID = formState.id;
            var companyService = (await axiosWithAuth().put(`/servcomp/${serviceID}/`, { interested: false, disabled: false, forms_complete: false, current_service: false })).data;

            if (companyService) {
                await axiosWithAuth().delete(`/${formName}/${formID}`);

                setForms(forms.map(form => {
                    if (form.id === serviceID) {
                        return companyService;
                    }

                    return form;
                }));
            }
        }
    }


    const handleRemovingForm = (formName, id) => {
        var lowerCaseFormName = formName.toLowerCase() || "";
        var successfulRequest = true;
        switch (lowerCaseFormName) {
            case ("advertising"):
                ResetCompanyServiceAndDeleteForm(advertising, "advertising", id);
                break;
            case ("brand positioning"):
                ResetCompanyServiceAndDeleteForm(brandPositioning, "brandpositioning", id);
                break;
            case ("ar tours"):
                ResetCompanyServiceAndDeleteForm(arTours, "artours", id);
                break;
            case ("trade events"):
                ResetCompanyServiceAndDeleteForm(tradeevents, "tradeevents", id);
                break;
            case ("market mapping"):
                ResetCompanyServiceAndDeleteForm(marketMapping, "marketmapping", id);
                break;
            default:
                successfulRequest = false;
        }

        return successfulRequest;
    }

    async function handleCurrentServiceChange(serviceID, isCurrentService) {
        var companyService = (await axiosWithAuth().put(`/servcomp/${serviceID}/`, { current_service: !isCurrentService, ever_had_service: true })).data;

        if (companyService) {
            setForms(forms.map(form => {
                if (form.id === companyService.id) {
                    return companyService;
                }

                return form;
            }))
        }
    }

    return (
        <>
            {adminView === false && (
                <div style={{ width: "90%", margin: "4rem auto 6rem auto", textAlign: "left", marginTop: "2rem" }}>
                    <Breadcrumbs path={breadcrumbPath} />
                </div>
            )}
            {forms && forms.length > 0 && (
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Service Name</TableCell>
                                <TableCell align="center">Form Status</TableCell>
                                <TableCell align="center">Current Service</TableCell>
                                <TableCell align="center">Form Actions</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {forms.map(form => (
                                <TableRow key={form.id}>
                                    <TableCell >{form.name} Form</TableCell>
                                    <TableCell align="center">
                                        {form.forms_complete === true && (<Chip label="Complete" variant="outlined" className={classes.completeChip} />)}
                                        {form.interested === true && form.forms_complete === false && (<Chip label="In-Progress" variant="outlined" color="primary" />)}
                                        {form.interested === false && form.forms_complete === false && (<Chip label="Not-Interested" variant="outlined" className={classes.notInterestedChip} />)}
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControlLabel
                                            control={<GreenCheckbox checked={form.current_service} name="CurrentService" onChange={() => handleCurrentServiceChange(form.id, form.current_service)} disabled={(form.forms_complete === true && adminView === true) ? false : true} />}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        {form.forms_complete === false && adminView === false && (
                                            <Button onClick={e => handleFormRouting(form.name, form.id, e)} className={classes.completeButton} disabled={!form.interested}>Complete Form</Button>
                                        )}
                                        <Button variant="outlined" color="secondary" onClick={() => handleRemovingForm(form.name, form.id)} disabled={!form.forms_complete} >Delete</Button>
                                    </TableCell>
                                </TableRow >
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    )
}

export default connect(null, { creatingForm })(ManageForms);