import { useState } from "react";
import { useForm } from "react-hook-form";
import { Label, Button, Divider, Form } from 'semantic-ui-react';
import { useParams } from "react-router-dom";

const Channels = ({ marketMappingForm, setMarketMappingForm, url, formName, fillOutMarketMappingForm, history, originalText }) => {

    const [channels, setChannels] = useState([["name0", "percentage0"]]);
    const [validatePercentages, setValidatedPercentages] = useState(false)
    const { handleSubmit, errors, register } = useForm();
    const { id } = useParams();


    const handleAddingInput = (myI, e) => {
        e.preventDefault()
        if (myI < 5) {
            setChannels([...channels, [`name${myI + 1}`, `percentage${myI + 1}`]])
        }
    }

    const handleRemovingInput = (myI, e) => {
        e.preventDefault()
        if (channels.length > 1) {
            setChannels(channels.filter(channel => {
                if (channel[0] !== `name${myI}`) {
                    return channel
                }
            }))
        }
    }

    const handleFilteringData = data => {

        let myObject = {}
        let compId = Number(originalText)

        for (const [key, value] of Object.entries(data)) {
            //filtering and matching data
            const newKey = key.replace("name", "");
            const filteredKey = newKey.replace("percentage", "");
            if (!myObject[filteredKey]) {
                myObject[filteredKey] = value
            }
            else {
                myObject[filteredKey] = { "name": myObject[filteredKey], "percentage": value, companies_id: compId, marketing_mapping_forms_id: id };
            }
        }
        return { "channels": Object.values(myObject), isForm: true }
    }

    const handleValidationOfPercetages = data => {
        let count = 0;
        for (const [key, value] of Object.entries(data)) {
            if (key.includes("percentage")) {
                count += value
            }
        }
        if (count === 100) {
            return true
        }

        return false
    }


    const handlePost = data => {
        setValidatedPercentages(false)
        if (handleValidationOfPercetages(data) === true) {
            fillOutMarketMappingForm(formName, handleFilteringData(data))
                .then(res => {
                    setMarketMappingForm({
                        ...marketMappingForm,
                        channels_complete: 1
                    })
                    history.push(`${url}/geographics`);
                })
                .catch(err => { console.log(err) })
        } else {
            setValidatedPercentages(true)
        }
    }

    return (
        <div style={{ textAlign: "left", padding: "2rem" }}>
            <div style={{ fontSize: "2rem", margin: "1rem 0rem" }}><strong>Channels</strong></div>
            <p>Please list up to five channels through which you currently do business. Examples include: direct, distributors, sales representatives.If you only sell through one channel, please note that channel below. If you sell through multiple channels, please try to approximate what proportion of your sales are associated with each channel in the boxes to the right.</p>
            <div>
                <Form onSubmit={handleSubmit(handlePost)} style={{ display: "flex", flexDirection: "column" }}>
                    {channels && channels.map((channel, i) => (
                        <div key={i} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "columns" }}>
                            <div style={{ display: "flex", }}>
                                <label style={{ width: "40%", marginRight: "1rem" }} htmlFor={channel[0]}>Channel {i + 1}</label>
                                <label htmlFor={channel[1]}>Percentage {i + 1}</label>
                            </div>
                            <div style={{ display: "flex", margin: ".5rem 0rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "40%", marginRight: "1rem", alignItems: "center" }}>
                                    <input
                                        type="text"
                                        id={channel[0]}
                                        name={channel[0]}
                                        ref={register({
                                            required: "You must provide the name of the channel",
                                            minLength: {
                                                value: 2,
                                                message: "Channel must be at least 2 characters"
                                            }
                                        })}
                                    />
                                    {errors[channel[0]] && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem" }} pointing>{errors[channel[0]].message}</Label>}
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "40%", marginRight: "1rem", alignItems: "center" }}>
                                    <input
                                        type="text"
                                        id={channel[1]}
                                        name={channel[1]}
                                        ref={register({
                                            required: "You must provide a percentage between 1-100",
                                            valueAsNumber: true,
                                            validate: (value) => value <= 100 && value >= 1 || "You must provide a percentage between 1-100",
                                        })} />
                                    {errors[channel[1]] && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem" }} pointing>{errors[channel[1]].message}</Label>}
                                </div>
                                {i === channels.length - 1 &&
                                    <div>
                                        <Button icon="add" color="yellow" onClick={(e) => handleAddingInput(i, e)} />
                                        <Button icon="x" onClick={(e) => handleRemovingInput(i, e)}></Button>
                                    </div>}
                            </div>
                        </div>
                    ))}
                    {validatePercentages === true && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem", width: "82%" }}>All percentages together should add up to 100</Label>}
                    <Divider />
                    <div style={{ marginTop: ".5rem", textAlign: "left", display: "flex", justifyContent: "flex-end" }}>
                        <Button type="submit" color="yellow">Submit</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}


export default Channels;