import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        margin: "2rem"
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
    },
}));

export default function ServiceSelectionCard({ service, handleMakingCompanyInterested }) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader
                action= {
                    service.interested === true ?
                        <IconButton onClick={() => handleMakingCompanyInterested(service.id, { interested: false })}>
                            <RemoveCircleIcon style={{ color: red[500],  }} />
                        </IconButton> 
                    :
                        <IconButton onClick={() => handleMakingCompanyInterested(service.id, { interested: true })}>
                            <AddCircleIcon style={{ color: green[500],  }} />
                        </IconButton>
                }
                title={service.name}
                subheader={service.headline}
            />
            <CardMedia
                className={classes.media}
                image={service.image}
                title="Service image"
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {service.description}
                </Typography>
            </CardContent>
        </Card>
    );
};