import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { creatingForm, cancelWelcomeModal } from "../../reduxStore/actions/companies-actions";
import CustomizedDialogs from "./welcomeMessage";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth";
import Panels from "./panes/index";
import { useSnackbar } from 'notistack';

const CompanyDashboard = ({ company, creatingForm, cancelWelcomeModal }) => {

    const history = useHistory();
    const [extraServices, setExtraServices] = useState([]);
    const [needInfoServices, setNeedInfoServices] = useState([]);
    const [currentServices, setCurrentServices] = useState([]);

    const [open, setOpen] = useState(company ? !company.initial_welcome : false);
    var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
    var companyID = byteId.toString(CryptoJS.enc.Utf8);
    var formName = { 1: "advertising", 2: "artours", 3: "brandpositioning", 4: "marketmapping", 5: "tradeevents" };
    const { enqueueSnackbar } = useSnackbar();

    const helperFunction = services => {
        var interestedServicesArr = [];
        var extraServicesArr = [];
        var currentServicesArr = [];

        services.forEach(serv => {
            if (serv.current_service === false && serv.interested === false) {
                extraServicesArr.push(serv)
            } else if (serv.interested === true && serv.forms_complete === false && serv.disabled === false) {
                interestedServicesArr.push(serv)
            } else if (serv.current_service === true) {
                currentServicesArr.push(serv)
            }
        });
        return { interestedServicesArr, extraServicesArr, currentServicesArr };
    };

    useEffect(async () => {
        var services = (await axiosWithAuth().get(`/companies/${companyID}/services`)).data;
        var { interestedServicesArr, extraServicesArr, currentServicesArr } = helperFunction(services);
        setNeedInfoServices(interestedServicesArr);
        setExtraServices(extraServicesArr);
        setCurrentServices(currentServicesArr);
    }, []);

    async function handleMakingCompanyInterested(id, showModal = false) {
        var updatedService = (await axiosWithAuth().put(`/servcomp/${id}`, { interested: true })).data;

        if (showModal){

            setNeedInfoServices([...needInfoServices, updatedService]);
            setExtraServices(extraServices.filter(service => {
                if (service.id !== updatedService.id) {
                    return service;
                }
            }));

            enqueueSnackbar(`Your ${updatedService.name} form has been added into Needs Attention Tab`, { variant: "success" });
        }
    };

    async function handleInterestingCardAndFormPush(companiesServiceID, serviceID) {
        await handleMakingCompanyInterested(companiesServiceID);
        await formCreator(companiesServiceID, serviceID);
    };

    async function formCreator(companiesServiceID, serviceID) {
        const formInformation = { companies_id: +companyID, companies_services_id: companiesServiceID };
        var form = await creatingForm(formName[serviceID], formInformation);
        history.push(`/${formName[serviceID]}/${form.id}`);
    };

    const handleClose = () => {
        cancelWelcomeModal(company.id, { initial_welcome: true });
        setOpen(false);
    };

    return (
        <div>
            {company && company.initial_welcome === false && <CustomizedDialogs open={open} handleClose={handleClose} />}
            <Panels
                handleMakingCompanyInterested={handleMakingCompanyInterested}
                extraServices={extraServices}
                needInfoServices={needInfoServices}
                handleInterestingCardAndFormPush={handleInterestingCardAndFormPush}
                formCreator={formCreator}
                currentServices={currentServices}
            />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        company: state.companiesReducer.company,
        isLoading: state.companiesReducer.isLoading
    };
};

export default connect(mapStateToProps, { creatingForm, cancelWelcomeModal })(CompanyDashboard);