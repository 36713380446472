import React, { useEffect, useState } from 'react';
import { Container, Typography } from "@material-ui/core";
import CurrentServices from "./currentServices";
import WelcomeToDashboard from "./welcomeToDashboard";
import EmailSupport from "./emailSupport";
import OneDriveLink from "./oneDriveLink";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js";
import { connect } from "react-redux";

const Home = ({ currentServices, company }) => {
    const [oneDriveLink, setOneDriveLink] = useState([]);

    useEffect(() => {

        if (company.id) {
            axiosWithAuth()
                .get(`/companies/${company.id}/creds`)
                .then(res => {
                    setOneDriveLink(res.data.filter(cre => {
                        if (cre.name === "one drive") {
                            return cre
                        }
                    }))
                }).catch(err => { console.log(err) })
        }
    }, [company]);

    return (
        <Container>
            <Container style={{display: "flex", justifyContent: "flex-end"}}>
                <EmailSupport company={company} />
                {oneDriveLink.length > 0 && <OneDriveLink oneDriveLink={oneDriveLink} company={company} />}
            </Container>
            {currentServices && currentServices.length > 0 && (<CurrentServices currentServices={currentServices} />)}
            <WelcomeToDashboard />
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        company: state.companiesReducer.company
    }
}

export default connect(mapStateToProps, {})(Home);