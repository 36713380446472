import { useState, useEffect } from "react";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EmailIcon from '@material-ui/icons/Email';
import CancelIcon from '@mui/icons-material/Cancel';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import { Container } from "@material-ui/core";
import "../../../../App.css";
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { axiosWithAuth } from "../../../../helperFunctions/axiosWithAuth";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
        padding: "1rem"
    },
    headerText: {
        fontWeight: 900
    },
    closeContainer: {
        position: "absolute",
        top: 10,
        right: -5,
        cursor: "pointer",
        opacity: ".8",
        "&:hover": {
            opacity: "1"
        }
    }
});

const Dropdown = ({ company, open, toggleDrawer }) => {

    const [oneDriveLink, setOneDriveLink] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${company.id}/creds`)
            .then(res => {
                setOneDriveLink(res.data.filter(cre => {
                    if (cre.name === "one drive") {
                        return cre
                    }
                }))
            }).catch(err => { console.log(err) })
    }, [])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const handleLogout = () => {
        axiosWithAuth()
            .post("/auth/logout")
            .then(response => {
                localStorage.clear();
                history.push("/login");
            }).catch(err => enqueueSnackbar("Unable to logout. please try again later", { variant: "error" }));
    }

    return (
        <div>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Container align="right" className={classes.closeContainer}>
                    <CancelIcon fontSize="large" onClick={toggleDrawer(false)}/>
                </Container>
                <div className={classes.list} role="presentation" style={{ padding: "2rem 1rem 1rem 1rem", marginTop: "10px" }} >
                    <List>
                        <div className={classes.headerText}>Company Name:</div>
                        <ListItemText primary={capitalizeFirstLetter(company.name)} />
                        <div className={classes.headerText}>Username:</div>
                        <ListItemText primary={company.email} />
                    </List>
                </div>
                <Divider />
                <div className={classes.list} role="presentation" >
                    <List>
                        <ListItem
                            button
                            key="dashboard"
                            onClick={() => history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)}
                        >
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                        <ListItem
                            button
                            key="accountSettings"
                            onClick={() => history.push("/settings")}
                        >
                            <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                            <ListItemText primary="Account Settings" />
                        </ListItem>
                    </List>
                </div>
                <Divider style={{ margin: "0rem 1rem" }} />
                <div className={classes.list} role="presentation" >
                    <List>
                        <ListItem
                            button
                            key="manageForm"
                            onClick={() => { history.push(`/forms/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`) }}
                        >
                            <ListItemIcon><EditIcon /></ListItemIcon>
                            <ListItemText primary="Service/Form Management" />
                        </ListItem>
                        {company && (
                            <ListItem
                                button
                                key="email"
                                onClick={() => history.push("/settings")}
                            >
                                <ListItemIcon><EmailIcon /></ListItemIcon>
                                <ListItemText><a href={"mailto:" + company.AccManagerEmail}>E-mail Support</a></ListItemText>
                            </ListItem>
                        )}
                        {oneDriveLink && oneDriveLink.length > 0 && (
                            <ListItem
                                button
                                key="oneDrive"
                            >
                                <ListItemIcon><CloudUploadIcon /></ListItemIcon>
                                <ListItemText><a target="_blank" href={oneDriveLink[0].data}>One Drive Upload</a></ListItemText>
                            </ListItem>
                        )}
                        {company.marketing_evaluation_form === false && (
                            <ListItem
                                button
                                key="marketingEvaluation"
                                onClick={() => history.push(`/marketingevaluationform`)}
                            >
                                <ListItemIcon><FormatAlignJustifyIcon /></ListItemIcon>
                                <ListItemText primary="Get Marketing Evaulation"></ListItemText>
                            </ListItem>
                        )}
                    </List>
                    <Divider style={{ margin: "0rem 1rem" }} />
                    <List>
                        <ListItem
                            button
                            key="signout"
                            onClick={handleLogout}
                        >
                            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                            <ListItemText primary="Sign Out" />
                        </ListItem>
                    </List>
                </div>
            </Drawer>
        </div>
    )
}


export default Dropdown;