import { Table, Button, Menu, Header } from 'semantic-ui-react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ManageSingleWebsite from "./managesinglewebsite";
import AddWebsite from "./addWebsite";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const WebsitesTable = ({ userDash, AdvertisingFormView }) => {

    const [websites, setWebsites] = useState([])
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(async () => {
        var websitesArr = (await axiosWithAuth().get(`/companies/${theId}/websites`)).data;
        
        if (websitesArr.length > 0) {
            if (AdvertisingFormView) {
                setWebsites(websitesArr.filter(web => {
                    if (web.trade_advertising_forms_id !== null) {
                        return web;
                    }
                }))
            } else {
                setWebsites(websitesArr);
            }

        }

    }, []);

    const handleRemovingWebsite = (websiteid) => {
        axiosWithAuth()
            .delete(`/websites/${websiteid}`)
            .then(res => {
                setWebsites(websites.filter(web => {
                    if (web.id !== websiteid) {
                        return web
                    }
                }))
            }).catch(err => console.log(err))
    }


    return (
        <>
            {websites && websites.length > 0 ? (
                <>
                    {userDash === true && <Header style={{ textAlign: "left", width: "1100px", margin: "2rem auto -2rem auto" }}>Websites</Header>}
                    <Table celled style={{ width: "1100px", margin: "3rem auto" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>URL</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {websites.map(website => (
                                <Table.Row key={website.id}>
                                    <Table.Cell style={{ width: "75%" }}><a target="_blank" style={{ color: "black" }} href={website.url}>{website.url}</a></Table.Cell>
                                    <Table.Cell style={{ width: "25%", textAlign: "center" }}>
                                        <ManageSingleWebsite setWebsites={setWebsites} websites={websites} website={website} />
                                        <Button onClick={() => handleRemovingWebsite(website.id)} color="red" basic>
                                            Delete
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='2'>
                                    <Menu floated='right'>
                                        <AddWebsite setWebsites={setWebsites} originalText={theId} />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </>
            ) :
                <div style={{ padding: "5rem 0rem", textAlign: "center" }}>
                    <p>There are no websites in our system</p>
                    <AddWebsite setWebsites={setWebsites} originalText={theId} />
                </div>}
        </>
    )
}

export default WebsitesTable;