import { Table, Button, Menu, Header } from 'semantic-ui-react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ManageSingleMarket from "./managesinglemarket";
import AddMarket from "./addmarket";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';


const MarketsTable = ({ userDash }) => {

    const [markets, setMarkets] = useState([])
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${theId}/markets`)
            .then(res => {
                setMarkets(res.data)
            }).catch(err => console.log(err))
    }, [])

    const handleRemovingMarket = (marketId) => {
        axiosWithAuth()
            .delete(`/markets/${marketId}`)
            .then(res => {
                setMarkets(markets.filter(prod => {
                    if (prod.id !== marketId) {
                        return prod
                    }
                }))
            }).catch(err => console.log(err))
    }

    return (
        <>
            {markets && markets.length > 0 ? (
                <>
                    {userDash === true && <Header style={{ textAlign: "left", width: "1100px", margin: "2rem auto -2rem auto" }}>Markets</Header>}
                    <Table celled style={{ width: "1100px", margin: "3rem auto" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {markets.map(market => (
                                <Table.Row key={market.id}>
                                    <Table.Cell style={{ width: "70%" }}>{market.name}</Table.Cell>
                                    <Table.Cell style={{ width: "30%", textAlign: "center" }}><ManageSingleMarket market={market} markets={markets} setMarkets={setMarkets} /><Button color="red" basic onClick={() => handleRemovingMarket(market.id)}>Delete</Button></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='3'>
                                    <Menu floated='right'>
                                        <AddMarket markets={markets} setMarkets={setMarkets} originalText={theId} />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </>)
                :
                <div style={{ padding: "5rem 0rem", textAlign: "center" }}>There are no markets in our system</div>
            }
        </>
    )
}

export default MarketsTable;