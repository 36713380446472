import { useState } from "react"
import ManageSingleUser from "./manageSingleUser";
import { Button, Grid, Modal, Icon, Header } from 'semantic-ui-react';
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth.js"


const AllUsersGrid = ({ myUser, user, setUsers, users, isAdmin }) => {

    const [open, setOpen] = useState(false);

    const nameForUserTypeId = (id) => {
        if (id === 1) {
            return "Sales"
        } else if (id === 2) {
            return "Admin"
        } else {
            return "Developer"
        }
    }

    const handleDelete = () => {
        axiosWithAuth()
            .delete(`users/${myUser.id}`)
            .then(res => {
                setUsers(users.filter(us => {
                    if (us.id !== myUser.id) {
                        return us
                    }
                }))
                setOpen(false)
            }).catch(err => { console.log(err) })
    }

    return (
        <>
            <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", borderBottom: "1px solid grey" }}>{myUser.first_name} {myUser.last_name}</Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", borderBottom: "1px solid grey" }}>{myUser.email}</Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", borderBottom: "1px solid grey" }}>{myUser.position}</Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid grey" }}>{nameForUserTypeId(myUser.user_types_id)}</Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid grey" }}>{myUser.hubspot_owner_id}</Grid.Column>
            
            {isAdmin && (
                <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid grey" }}>
                    <ManageSingleUser user={myUser} personUsing={user} setUsers={setUsers} users={users} />
                    <Modal
                        basic
                        onClose={() => setOpen(false)}
                        onOpen={() => { setOpen(true) }}
                        open={open}
                        size='small'
                        trigger={<Button basic color='red' >Delete</Button>}
                    >
                        <Header icon><Icon name='trash' />Removing Company</Header>
                        <Modal.Content>
                            <p style={{ fontWeight: 700, textAlign: "center" }}>{`Are you sure you want to delete ${myUser.first_name} ${myUser.last_name} account?`}</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button basic color='red' inverted onClick={() => setOpen(false)}>
                                <Icon name='remove' /> No
                            </Button>
                            <Button color='green' inverted onClick={handleDelete}>
                                <Icon name='checkmark' /> Yes
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Grid.Column>
            )}
        </>
    )
}

export default AllUsersGrid;