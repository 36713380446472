import { Table, Button, Menu, Header } from 'semantic-ui-react';
import { useEffect, useState } from "react";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js"
import { useParams } from "react-router-dom";
import ManageSingleFacility from "./managesinglefacility";
import AddFacility from "./addfacility";
import CryptoJS from "crypto-js";


const FacilitiesTable = ({ userDash }) => {

    const [facilities, setFacilities] = useState([])
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${theId}/facilities`)
            .then(res => {
                setFacilities(res.data)
            }).catch(err => console.log(err))
    }, [])

    const handleRemovingFacility = (faciltyId) => {
        axiosWithAuth()
            .delete(`/facilities/${faciltyId}`)
            .then(res => {
                setFacilities(facilities.filter(fac => {
                    if (fac.id !== faciltyId) {
                        return fac
                    }
                }))
            }).catch(err => console.log(err))
    }

    return (
        <>
            {facilities && facilities.length > 0 ? (
                <>
                    {userDash === true && <Header style={{ textAlign: "left", width: "1100px", margin: "2rem auto -2rem auto" }}>Facilities</Header>}
                    <Table celled style={{ width: "1100px", margin: "3rem auto" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Location</Table.HeaderCell>
                                <Table.HeaderCell>SQFT</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {facilities.map(facility => (
                                <Table.Row key={facility.id}>
                                    <Table.Cell style={{ width: "25%" }}>{facility.name}</Table.Cell>
                                    <Table.Cell style={{ width: "25%" }}>{facility.location}</Table.Cell>
                                    <Table.Cell style={{ width: "25%" }}>{facility.square_footage}</Table.Cell>
                                    <Table.Cell style={{ width: "25%", textAlign: "center" }}><ManageSingleFacility facilities={facilities} setFacilities={setFacilities} facility={facility} /><Button color="red" basic onClick={() => handleRemovingFacility(facility.id)}>Delete</Button></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='4'>
                                    <Menu floated='right'>
                                        <AddFacility setFacilities={setFacilities} facilities={facilities} originalText={theId} />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </>)
                :
                <div style={{ padding: "5rem 0rem", textAlign: "center" }}>There are no facilities in our system</div>
            }
        </>
    )
}

export default FacilitiesTable;