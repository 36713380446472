import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import CompaniesOverview from "./companies";
import { connect } from 'react-redux';
import { getUserInfo } from "../../reduxStore/actions/user-actions";
import Breadcrumbs from "../breadcrumbs";
import CryptoJS from "crypto-js";

function DashboardMain({ user, getUserInfo }) {
  const history = useHistory();
  const breadcrumbPath = [{ name: "Dashboard", url: "/employeedashboard" }]
  var byteId  = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
  var theId = byteId.toString(CryptoJS.enc.Utf8);

  var byteType  = CryptoJS.AES.decrypt(localStorage.getItem("YGVtSKYr"), localStorage.getItem("SWassWQWiu"));
  var theType = byteType.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    if (theType === "4") {
      history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
    }
    getUserInfo(theId)
  }, [])

  return (
    <div style={{ marginTop: "2rem", textAlign: "left", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <CompaniesOverview />
    </div>
  );
}


const mapStateToProps = state => {
  return {
    user: state.usersReducer.user,
  }
}


export default connect(mapStateToProps, { getUserInfo })(DashboardMain)
