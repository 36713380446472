import Form from "./signUpForm";
import { connect } from 'react-redux';
import DynamicLoader from "../Loader"

function Signup({ isLoading }) {
  return (
    <>
      <DynamicLoader hide={!isLoading}/>
      <Form />
    </>
  );
}

const mapStateToProps = state => {
  return {
    isLoading: state.companiesReducer.isLoading
  }
}


export default connect(mapStateToProps, {})(Signup);