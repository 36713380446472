import { Header, Table, Menu, Button, Modal, Icon } from "semantic-ui-react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { axiosWithAuth } from "../../../../helperFunctions/axiosWithAuth.js";
import ManageMarketingEvaluation from "./managemarketingEvaluation";
import CryptoJS from "crypto-js";

const MarketingEvaluation = ({ companyDash }) => {

    const [marketingEvaluationForm, setMarketingEvaluationForm] = useState({})
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${theId}/marketingform`)
            .then(res => {
                setMarketingEvaluationForm(res.data)
            }).catch(err => { console.log(err) })
    }, [theId])

    const handleRemovingMarketingEvaluationForm = formId => {
        axiosWithAuth()
            .delete(`/marketingevaluations/${formId}`)
            .then(res => {
                setMarketingEvaluationForm({})
                // we need to change the field in user
                axiosWithAuth()
                    .put(`/companies/${theId}/`, { marketing_evaluation_form: false })
                    .then(res => {
                        setOpen(false)
                    }).catch(err => { console.log(err) })
            }).catch(err => console.log(err))
    }


    return (
        <div>
            <Header>Marketing Evaluation Form</Header>
            {marketingEvaluationForm && Object.keys(marketingEvaluationForm).length > 0 ? (
                <Table celled style={{ width: "900px", margin: "3rem auto" }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Question</Table.HeaderCell>
                            <Table.HeaderCell style={{ textAlign: "center" }}>Answer</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Do you have e-commerce?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.e_commerce === true ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>How many products or SKUs do you sell via e-commerce?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.number_of_products !== "" ? marketingEvaluationForm.number_of_products : "N/A"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Are you interested in e-commerce?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.interested_in_ecommerce === true ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Do you have distributors, retailers or agents?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.distributors_retailers_agents === true ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>How many indirect channels do you manage?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.indirect_channels !== "" ? marketingEvaluationForm.indirect_channels : "N/A"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Do you have a mailing list?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.mailing_list === true ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>How many active contacts do you currently have?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.number_of_contact !== "" ? marketingEvaluationForm.number_of_contact : "N/A"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>What email system do you use?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.contact_system !== "" ? marketingEvaluationForm.contact_system : "N/A"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Are you currently set up for Digital Ads?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.digital_ads !== false ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Which providers do you use?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.digital_ads_used !== "" ? marketingEvaluationForm.digital_ads_used : "N/A"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Are you (or were you) relying on tradeshows to generate leads?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.tradeshows !== false ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Which tradeshows?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.tradeshows_used !== "" ? marketingEvaluationForm.tradeshows_used : "N/A"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Are you relying on webinars to generate leads?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.webinars !== false ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Please provide a link to a recent event.</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.webinar_links !== "" ? <a target="_blank" rel="noopener noreferrer" href={`https://${marketingEvaluationForm.webinar_links}`}>{marketingEvaluationForm.webinar_links}</a> : "N/A"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Has COVID-19 impacted the way you generated leads?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.covid_19 !== false ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Please explain How Covid has impacted your business:</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.covid_19_reasoning !== "" ? marketingEvaluationForm.covid_19_reasoning : "N/A"}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ width: "50%" }}>Lastly, do you have an account with Google Analytics?</Table.Cell>
                            <Table.Cell style={{ width: "50%", textAlign: "center" }}>{marketingEvaluationForm.google_analytics !== false ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='2'>
                                <Menu floated='right'>
                                    <ManageMarketingEvaluation marketingEvaluationForm={marketingEvaluationForm} setMarketingEvaluationForm={setMarketingEvaluationForm} />
                                    <Modal
                                        basic
                                        onClose={() => setOpen(false)}
                                        onOpen={() => { setOpen(true) }}
                                        open={open}
                                        size='small'
                                        trigger={<Button color="red" basic >Delete</Button>}
                                    >
                                        <Header icon><Icon name='send' />Marketing Evaluation Form</Header>
                                        <Modal.Content>
                                            <p style={{ fontWeight: 700, textAlign: "center" }}>Are you sure you want to delete this Market Mapping Form?</p>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button basic color='red' inverted onClick={() => setOpen(false)}>
                                                <Icon name='remove' /> No
                                            </Button>
                                            <Button type="submit" color='green' inverted onClick={() => { handleRemovingMarketingEvaluationForm(marketingEvaluationForm.id) }}>
                                                <Icon name='checkmark' /> Yes
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>
                                </Menu>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            ) :
                <div style={{ padding: "5rem 0rem", textAlign: "center" }}>There is no Marketing Evaluation form in our system</div>
            }
        </div>
    )
}


export default MarketingEvaluation;