import chartImage from '../../internalData/chart-image.jpg';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { height } from '@material-ui/system';
import logo from "../../assets/image/img/GrowthHive-Logo_2022.png";

const useStyles = makeStyles((theme) => ({
 header:{
   background: `url(${chartImage})`,
   display: "flex", 
   justifyContent: "center", 
   background: `url(${chartImage})`,
 },
  }
)
)

function NonUserNav() {
  const classes = useStyles();
  return (
    <Container className={classes.header} maxWidth="xl">
      <img src={logo} style={{width: "30%", maxHeight: "200px", padding: "1rem 1rem"}}/>
    </Container>  
  );
}

export default NonUserNav;