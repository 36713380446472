import { useState, useEffect } from "react";
import { Label, Button, Divider, Form } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const Markets = ({ marketMappingForm, setMarketMappingForm, url, formName, fillOutMarketMappingForm, history, originalText }) => {
    const [markets, setMarkets] = useState(["name"]);
    const { handleSubmit, errors, register } = useForm();
    const { id } = useParams();

    useEffect(() => {
        if (marketMappingForm.markets_complete === true) {
            history.push(`${url}/channels`);
        } else if (marketMappingForm.channels_complete === true) {
            history.push(`${url}/geographics`);
        } else if (marketMappingForm.geographics_complete === true) {
            history.push(`${url}/productsservices`);
        } else if (marketMappingForm.products_services_complete === true) {
            history.push(`/dashboard/${localStorage.getItem("JFZR5qve")}`)
        }
    }, [])


    const handleAddingInput = (myI, e) => {
        e.preventDefault()
        if (myI < 5) {
            setMarkets([...markets, `name${markets.length}`])
        }
    }


    const handleRemovingInput = (myI, e) => {
        e.preventDefault()
        if (markets.length > 1) {
            setMarkets(markets.filter(market => {
                if (market !== `name${myI}`) {
                    return market
                }
            }))
        }
    }


    const formatData = data => {
        let formattedData = []
        for (const [key, value] of Object.entries(data)) {
            formattedData.push({ name: value, companies_id: Number(originalText), marketing_mapping_forms_id: id })
        }
        return formattedData
    }


    const handlePost = data => {
        fillOutMarketMappingForm(formName, { markets: formatData(data), isForm: true })
            .then(res => {
                setMarketMappingForm({
                    ...marketMappingForm,
                    markets_complete: 1
                })
                history.push(`${url}/channels`);
            })
            .catch(err => { console.log(err) })
    }


    return (
        <div style={{ textAlign: "left", padding: "2rem" }}>
            <div style={{ fontSize: "2rem", margin: "1rem 0rem" }}><strong>Markets</strong></div>
            <p>Please list up to seven end markets into which you currently do business. Examples include: automotive, aerospace, food and beverage, medical, military. Please list these end-markets in order of priority to your business. If you do most of your business in the oil and gas market, please list that market first below.</p>
            <div>
                <Form onSubmit={handleSubmit(handlePost)} style={{ display: "flex", flexDirection: "column" }}>
                    {markets && markets.map((market, i) => (
                        <div key={i} style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor={market}>Market {i + 1}</label>
                            <div style={{ display: "flex", margin: ".5rem 0rem" }}>
                                <input
                                    type="text"
                                    name={market}
                                    id={market}
                                    defaultValue={""}
                                    style={{ width: "85%", marginRight: "1rem" }}
                                    ref={register({
                                        required: "You must provide a the name of the market",
                                        minLength: {
                                            value: 2,
                                            message: "Market must be at least 2 characters"
                                        },
                                    })}
                                />
                                {i === markets.length - 1 &&
                                    <div>
                                        <Button icon="add" color="yellow" onClick={(e) => handleAddingInput(i, e)} />
                                        <Button icon="x" onClick={(e) => handleRemovingInput(i, e)}></Button>
                                    </div>}
                            </div>
                            {errors[market] && <Label basic color='red' style={{ textAlign: "center", width: "85%", marginBottom: ".5rem" }} pointing>{errors[market].message}</Label>}
                        </div>
                    ))}
                    <Divider />
                    <div style={{ marginTop: ".5rem", textAlign: "left", display: "flex", justifyContent: "flex-end" }}>
                        <Button type="submit" color="yellow">Submit</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}


export default Markets;