function CurrentPageFooter({ page }) {

    const chooseColor = thePage => {
        if (page === thePage) {
            return "rgb(255,191,77)"
        } else {
            return "rgb(250,250,250)"
        }
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <section style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "2rem", border: "darkgrey 1px solid", width: "14.8rem", borderRadius: "10px", overflow: "hidden" }}>
                <div style={{ backgroundColor: chooseColor("websites"), display: "flex", justifyContent: "center", alignItems: "center", padding: "0rem 1.3rem" }}>
                    <p style={{ fontWeight: 700 }}>1</p>
                </div>
                <div style={{ backgroundColor: chooseColor("brands"), display: "flex", justifyContent: "center", alignItems: "center", padding: "0rem 1.3rem", borderLeft: "darkgrey solid 1.5px" }}>
                    <p style={{ fontWeight: 700 }}>2</p>
                </div>
                <div style={{ backgroundColor: chooseColor("socials"), display: "flex", justifyContent: "center", alignItems: "center", padding: "0rem 1.3rem", borderLeft: "darkgrey solid 1.5px" }}>
                    <p style={{ fontWeight: 700 }}>3</p>
                </div>
                <div style={{ backgroundColor: chooseColor("marketingevaluation"), display: "flex", justifyContent: "center", alignItems: "center", padding: "0rem 1.3rem", borderLeft: "darkgrey solid 1.5px" }}>
                    <p style={{ fontWeight: 700 }}>4</p>
                </div>
                <div style={{ backgroundColor: chooseColor("serviceSelection"), display: "flex", justifyContent: "center", alignItems: "center", padding: "0rem 1.3rem", borderLeft: "darkgrey solid 1.5px" }}>
                    <p style={{ fontWeight: 700 }}>5</p>
                </div>
            </section>
        </div>
    );
}


export default CurrentPageFooter;