import { useHistory } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Container } from "@material-ui/core";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemText from '@material-ui/core/ListItemText';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { axiosWithAuth } from "../../../../helperFunctions/axiosWithAuth";
import { useSnackbar } from 'notistack';
import "../../../../App.css";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
        padding: "1rem"
    },
    headerText: {
        fontWeight: 900
    },
    closeContainer: {
        position: "absolute",
        top: 10,
        right: -5,
        cursor: "pointer",
        opacity: ".8",
        "&:hover": {
            opacity: "1"
        }
    }
});

const EmployeeDropdown = ({ user, open, toggleDrawer }) => {
    const history = useHistory();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const isAdmin = !(user.user_types_id === 1);

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleClick = route => {
        history.push(route)
    }

    const handleLogout = () => {
        axiosWithAuth()
            .post("/auth/logout")
            .then(response => {
                localStorage.clear();
                history.push("/employeelogin");
            }).catch(err => enqueueSnackbar("Unable to logout. please try again later", { variant: "error" }));
    }

    return (
        <div>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Container align="right" className={classes.closeContainer}>
                    <CancelIcon fontSize="large" onClick={toggleDrawer(false)} />
                </Container>
                <div className={classes.list} role="presentation" style={{ padding: "2rem 1rem 1rem 1rem", marginTop: "10px"  }} >
                    <List>
                        <div className={classes.headerText}>Username:</div>
                        <ListItemText primary={user.email} />
                        <div className={classes.headerText}>Position:</div>
                        <ListItemText primary={capitalizeFirstLetter(user.position)} />
                    </List>
                </div>
                <Divider />
                <div className={classes.list} role="presentation" >
                    <List>
                        <ListItem
                            button
                            key="dashboard"
                            onClick={() => handleClick("/employeedashboard")}
                        >
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                        <ListItem
                            button
                            key="accountSettings"
                            onClick={() => handleClick("/accountsettings")}
                        >
                            <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                            <ListItemText primary="Account Settings" />
                        </ListItem>
                    </List>
                </div>
                <Divider style={{ margin: "0rem 1rem" }} />
                <div className={classes.list} role="presentation" >
                    <List>
                        {!(user.user_types_id === 1) && (
                            <ListItem
                                button
                                key="createInternalUser"
                                onClick={() => handleClick("/addlevyuser")}
                            >
                                <ListItemIcon><PersonAddIcon /></ListItemIcon>
                                <ListItemText primary="Create Internal User" />
                            </ListItem>
                        )}
                        <ListItem
                            button
                            key="manageInternalUser"
                            onClick={() => handleClick("/managelevyusers")}
                        >
                            <ListItemIcon>{isAdmin ? <EditIcon /> : <SupervisorAccountIcon />}</ListItemIcon>
                            <ListItemText primary={`${isAdmin ? "Manage" : ""} Internal Users`} />
                        </ListItem>
                    </List>
                    <Divider style={{ margin: "0rem 1rem" }} />
                    <List>
                        <ListItem
                            button
                            key="createCompanyUser"
                            onClick={() => handleClick("/addcompany")}
                        >
                            <ListItemIcon><GroupAddIcon /></ListItemIcon>
                            <ListItemText primary="Create Company User" />
                        </ListItem>
                        <ListItem
                            button
                            key="manageService"
                            onClick={() => handleClick("/manageservices")}
                        >
                            <ListItemIcon><SettingsIcon /></ListItemIcon>
                            <ListItemText primary="Manage Services" />
                        </ListItem>
                    </List>
                    <Divider style={{ margin: "0rem 1rem" }} />
                    <List>
                        <ListItem
                            button
                            key="signout"
                            onClick={handleLogout}
                        >
                            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                            <ListItemText primary="Sign Out" />
                        </ListItem>
                    </List>
                </div>
            </Drawer>

        </div>
    )
}

export default EmployeeDropdown;