import { Container, Typography } from "@material-ui/core";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import DoneIcon from '@material-ui/icons/Done';


const CurrentServices = ({ currentServices }) => {
    return (
        <Container>
            <Typography variant="h3" >
                Current Services
            </Typography>
            <Grid container spacing={2}>
                <List >
                    {currentServices.map((service, i) => (
                        <>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar
                                        src={service.image}
                                        variant="square"
                                        alt="service image"
                                    >
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={service.name}
                                    secondary={
                                        <>
                                            <Typography
                                                sx={{ display: 'block' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {service.headline}
                                            </Typography>
                                        </>
                                    }
                                />
                                <DoneIcon style={{margin: "2rem"}}/>
                            </ListItem>
                            {currentServices.length - 1 === i ? "" : <Divider variant="inset" component="li" />}
                        </>
                    ))}
                </List>
            </Grid>
            <Divider  />
        </Container>
    )
}

export default CurrentServices;