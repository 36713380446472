import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CardMedia from '@material-ui/core/CardMedia';
import chartImage from '../../../internalData/chart-image.jpg';
import { Message } from 'semantic-ui-react';
import Dropdown from "./companyDropdown/index.js";
import EmployeeDropdown from "./employeeDropdown/index.js";
import { Button } from "semantic-ui-react";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import logo from "../../../assets/image/img/GrowthHive-Logo_2022.png";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 100
  },
  media: {
    width: "350px"
  }
}));

function UserNav({ company, user }) {
  const [mouseOverText, setMouseOverText] = useState(false);
  const [mouseOverDropdown, setMouseOverDropdown] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [myPath, setMyPath] = useState(history.location.pathname);
  const classes = useStyles();

  useEffect(() => {
    setMouseOverText(false)
    setMyPath(history.location.pathname)
  }, [history.location.pathname, myPath, setMyPath])


  const handleOpeningSettingsTab = e => {
    setMouseOverDropdown(true)
    setOpen(true);
  }


  const handleMouseOver = () => {
    setMouseOverText(true)
  }


  const handleOnMouseOut = () => {
    setMouseOverText(false)
  }

  const toggleDrawer = (close) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(close);
  };

  return (
    <div style={{ display: "flex", alignItems: "flex-end", alignItems: "center" }}>

      <Container className={classes.header} maxWidth="xl">
        <img
          className={classes.media}
          src={logo}
          alt="company Logo"
        />
        {myPath === "/websites" || myPath === "/brands" || myPath === "/socials" || myPath === "/marketingevaluation" ?
          <div onMouseOver={handleMouseOver} onMouseOut={handleOnMouseOut}>
            <Button className='fas fa-camera fa-2x' icon="sidebar" color="black" />
          </div> :
          <div style={{ cursor: "pointer" }} onClick={handleOpeningSettingsTab}>
            <Button ><MenuOpenIcon /></Button>
          </div>
        }
      </Container>

      {mouseOverText === true ? (
        <div style={{ maxWidth: "20%", position: "absolute", top: 70, right: 0}}>
          <Message warning>
            Once the onboarding process is complete, you will have access to your settings
          </Message>
        </div>)
        :
        null
      }
      {mouseOverDropdown === true && company && company.name && (
        <Dropdown company={company} open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />
      )}
      {mouseOverDropdown === true && user && user.first_name && (
        <EmployeeDropdown user={user} open={open} setOpen={setOpen} toggleDrawer={toggleDrawer} />
      )}
    </div>
  );
}

export default UserNav;