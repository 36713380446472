import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { grey } from "@material-ui/core/colors";
import Paper from '@material-ui/core/Paper';
import '@fontsource/roboto';
import '../../App.css'
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth.js";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 32,
  },
  subHeading: {
    fontWeight: 700,
    fontSize: 18,
  },
  paper: {
    backgroundColor: grey[100],
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  ryGrid: {
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formTop: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  submit: {
    marginBottom: 20
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center !important",
  }
})
)

function PasswordReset({ isInternalUser }) {
  const classes = useStyles();
  const history = useHistory();
  const { handleSubmit, errors, register, control } = useForm();
  const [errorOccured, setErrorOccured] = useState("")

  async function handleReset(data) {
    var passwordEndpoint = isInternalUser ? "/auth/passwordresetemailinternaluser" : "/auth/passwordresetemail";
    var redirectURL = isInternalUser ? "/employeelogin" : "/login";
    console.log(isInternalUser, "is internal user");
    
    return axiosWithAuth()
      .post(passwordEndpoint, data)
      .then(res => {
        alert('Thank you, your request was successful. Please check the email you provided for a link to reset your password.');
        history.push(redirectURL);
      }).catch(error => error.response.data);
  };

  return (
    <Container maxWidth="sm">
      <form class="login" onSubmit={handleSubmit(handleReset)}>
        <Paper className={classes.paper}>
          <Grid container className={classes.formTop} spacing={0}>
            <Grid item xs={12}>
              <Typography class="signup" variant="h5" component="h2"> GrowthHive Portal Password Reset </Typography>
            </Grid>
            <Grid container className={classes.ryGrid} spacing={0}>
              <Grid item xs={3}>
                <Typography className={classes.subHeading} variant="h5">Email</Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  render={(props) => <TextField {...props} required className={classes.TextField} variant="outlined" fullWidth label="Email Address" />}
                  variant="outlined"
                  fullWidth
                  rules={{
                    required: "You must enter an email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Please enter a valid email address"
                    }
                  }}

                  name="email"
                  id="email"
                />
                {errors.email && <p style={{ color: "red", margin: "0rem" }}>* {errors.email.message} *</p>}
              </Grid>
            </Grid>

            {errorOccured ? <Grid item xs={12}><p style={{ color: "red", marginBottom: "1rem" }}>* {errorOccured} *</p></Grid> : ""}
            <Grid xs={12} className={classes.buttonsContainer}>
              <Grid xs={6}>
                  <Link to={isInternalUser ? "/employeelogin" : "/login"}>Back to Login</Link>
              </Grid>
              <Grid xs={4}>
                <Button type="submit" fullWidth variant="contained" id="submit" className={classes.submit}>Request Reset</Button>
              </Grid>
            </Grid>

          </Grid>
        </Paper>
      </form>

    </Container>
  )
}

export default PasswordReset;