import { useState, useEffect } from "react";
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import CurrentPageFooter from "../gettingStartedUtils/currentPageFooter.js";
import HelperButtons from "../gettingStartedUtils/brands-socials-webs-buttons.js";
import { handleRemovingURL, handleAddingInput } from "../gettingStartedUtils/handleInputs";
import WebsiteInput from "./websiteInput.js";
import { filterData } from "../gettingStartedUtils/dataFormating";
import { getCompanyInfo, fillOutForm } from "../../../reduxStore/actions/companies-actions";
import { Form } from "semantic-ui-react";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js";

function Websites({ getCompanyInfo, company, fillOutForm }) {

  const { register, handleSubmit, errors } = useForm();
  const [numberURL, setNumberURL] = useState(1);
  const [inputs, setInputs] = useState(["url0"]);
  const [sameURL, setSameURL] = useState(false);
  const history = useHistory();
  var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
  var theId = byteId.toString(CryptoJS.enc.Utf8);

  const checkStatus = info => {
    if (info.website_form === true) {
      if (info.brand_form === true) {
        if (info.social_form === true) {
          if (info.marketing_evaluation_form === true) {
            history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
          } else {
            history.push("/marketingevaluation")
          }
        } else {
          history.push("/social")
        }
      } else {
        history.push("/brands")
      }
    }
  }

  useEffect(() => {
      getCompanyInfo(theId)
        .then(res => {
            checkStatus(res)
        })
  }, [])

  const handleNextForm = data => {
    setSameURL(false);
    const myData = filterData(data, "url", "websites", theId)
    if (myData === false) {
       setSameURL(true)
    } else {
      fillOutForm("websites", {...myData, onBoardingForm: true})
        .then(res => {
          history.push("/brands")
        }).catch(err => console.log(err))
    }
  }

  const handleSkip = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .put(`/companies/${company.id}`, { website_form: true })
      .then(res => history.push("/brands"))
      .catch(err => console.log(err))
  };

  return (
    <>
      {company && company.website_form === false && (
        <>
          <div class="getting-started-stepper">
            <div style={{ backgroundColor: "rgb(255,191,77)", width: "100%", textAlign: "center" }}>
              <div style={{ fontSize: "2rem", padding: "1rem", color: "rgb(51,51,51)" }}>Websites</div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "space-between", minHeight: "175px", justifyContent: "space-between" }}>
              <section style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <p style={{ padding: "1rem" }}>Please add all website you would like us to evaluate.</p>
                <Form id="websiteForm" onSubmit={handleSubmit(handleNextForm)} style={{ width: "90%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div style={{ fontWeight: 700, fontSize: "18px", textAlign: "left", width: "85%", marginBottom: ".5rem" }}>URLs</div>
                  {inputs && inputs.map((url, i) => (
                    <WebsiteInput
                      key={`${i}`}
                      url={url}
                      register={register}
                      errors={errors}
                      handleRemovingURL={handleRemovingURL}
                      i={i}
                      inputs={inputs}
                      setInputs={setInputs}
                      numberURL={numberURL}
                      setNumberURL={setNumberURL}
                      numberOfInput={1}
                    />
                  ))}
                  {sameURL === true && <p style={{ color: "red", margin: ".5rem 2rem 0rem 0rem" }}>* You entered two identical URLs *</p>}
                  <HelperButtons
                    setInputs={setInputs}
                    inputs={inputs}
                    numberURL={numberURL}
                    setNumberURL={setNumberURL}
                    handleAddingInput={handleAddingInput}
                    comp={"URL"}
                    numberOfInput={1}
                    handleSkip={handleSkip}
                  />
                </Form>
              </section>
            </div>
          </div>
          <CurrentPageFooter page="websites" />
        </>
      )}
    </>
  );
}


const mapStateToProps = state => {
  return {
    company: state.companiesReducer.company,
  }
}

export default connect(mapStateToProps, { getCompanyInfo, fillOutForm })(Websites);