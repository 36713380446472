import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserNav from "./user-nav/index";
import { connect } from 'react-redux';
import { getUserInfo } from '../../reduxStore/actions/user-actions';
import { getCompanyInfo } from "../../reduxStore/actions/companies-actions";
import CryptoJS from "crypto-js";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import chartImage from '../../internalData/chart-image.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundImage: `url(${chartImage}) !important`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function MainNavigation({ user, company, getCompanyInfo, getUserInfo }) {
  const history = useHistory();
  const classes = useStyles();
  const [myPath, setMyPath] = useState(history.location.pathname)

  var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
  var theId = byteId.toString(CryptoJS.enc.Utf8);

  var byteType = CryptoJS.AES.decrypt(localStorage.getItem("YGVtSKYr"), localStorage.getItem("SWassWQWiu"));
  var theType = byteType.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    if (theType === "4") {
      getCompanyInfo(theId)
      setMyPath(history.location.pathname)
    } else {
      getUserInfo(theId)
      setMyPath(history.location.pathname)
    }
  }, [history.location.pathname, myPath, setMyPath])

  return (
      <div className={classes.root}>
        <AppBar position="static" color={"transparent"}>
          <UserNav company={company} user={user} />
        </AppBar>
      </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.usersReducer.user,
    company: state.companiesReducer.company
  }
}

export default connect(mapStateToProps, { getUserInfo, getCompanyInfo })(MainNavigation);