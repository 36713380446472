import { useState } from "react";
import { connect } from 'react-redux';
import { useHistory, Link } from "react-router-dom";
import { companyLogin } from "../../reduxStore/actions/companies-actions.js";
import { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { grey } from "@material-ui/core/colors";
import Paper from '@material-ui/core/Paper';
import '@fontsource/roboto';
import '../../App.css'


const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 32,
  },
  subHeading: {
    fontWeight: 700,
    fontSize: 18,
  },
  paper: {
    backgroundColor: grey[100],
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    paddingBottom: theme.spacing(4),
  },
  ryGrid: {
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  formTop: {
    justifyContent: "center",
  },
  masterContainer: {
    paddingTop: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(5),
  }
})
)

function Login({ companyLogin }) {
  const classes = useStyles();
  const history = useHistory();
  const { handleSubmit, errors, register, control } = useForm();
  const [errorOccured, setErrorOccured] = useState("")

  const handleLogin = data => {
    setErrorOccured(false)
    companyLogin(data)
      .then(response => {
        if (response.user) {
          if (response.user.need_info === false) {
            history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
          } else if (response.user.website_form === false) {
            history.push("/websites")
          } else if (response.user.brand_form === false) {
            history.push("/brands")
          } else if (response.user.social_form === false) {
            history.push("/socials")
          } else if (response.user.marketing_evaluation_form === false) {
            history.push("/marketingevaluation")
          }
        } else {
          if (response.errorMessage) {
            setErrorOccured(response.errorMessage)
          } else {
            setErrorOccured("Unexpected Error")
          }
        }
      })
  }

  return (
    <Container maxWidth="sm">
      <form class="login" onSubmit={handleSubmit(handleLogin)}>
        <Paper className={classes.paper}>
          <Grid container className={classes.formTop} spacing={0}>
            <Grid item xs={12} >
              <Typography class="signup" variant="h5" component="h2"> GrowthHive Portal Login </Typography>
            </Grid>
            <Grid container className={classes.masterContainer} spacing={0}>
              <Grid item xs={3}>
                <Typography className={classes.subHeading} variant="h5">Email</Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  control={control}
                  render={(props) => <TextField {...props} required className={classes.TextField} variant="outlined" fullWidth label="Email Address" />}
                  variant="outlined"
                  fullWidth
                  rules={{
                    required: "You must enter an email",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Please enter a valid email address"
                    }
                  }}

                  name="email"
                  id="email"
                />
                {errors.email && <p style={{ color: "red", margin: "0rem" }}>* {errors.email.message} *</p>}
              </Grid>
            </Grid>
            <Grid container className={classes.ryGrid} spacing={0}>
              <Grid item xs={3}>
                <Typography className={classes.subHeading} variant="h5">Password</Typography>
              </Grid>
              <Grid item xs={8}>
                <Controller
                  render={(props) => <TextField {...props} required className={classes.TextField} variant="outlined" fullWidth type="password" label="Password" />}
                  control={control}
                  name="password"
                  type="password"
                  id="password"
                  variant="outlined"
                  fullWidth
                  rules={{
                    required: "You must enter a password",
                    minLength: {
                      value: 8,
                      message: "Your password must have at least 8 characters",
                    },
                  }}
                />
                {errors.password && <p style={{ color: "red", margin: "0rem" }}>* {errors.password.message} *</p>}
              </Grid>
            </Grid>
            {errorOccured ? <Grid item xs={12}><p style={{ color: "red", marginBottom: "1rem" }}>* {errorOccured} *</p></Grid> : ""}
            <Grid item xs={6} className={classes.ryGrid}>
              <Link to="/reset" variant="body2" >
                Forgot your password? Click here to reset!
              </Link>
            </Grid>
            <Grid item xs={3}>
              <Button type="submit" fullWidth variant="contained" id="submit" className={classes.submit}>Login</Button>
            </Grid>
          </Grid>
        </Paper>
      </form>

    </Container>
  )
}

export default connect(null, { companyLogin })(Login);