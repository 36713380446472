function Retailers({ register, errors, marketingEvaluationForm }) {
    return (
        <div style={{ borderBottom: "solid black 1px", marginTop: "1rem", paddingBottom: "1rem", marginBottom: "1rem" }}>
            <div style={{ paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                <label htmlFor="distributors_retailers_agents" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Do you have distributors, retailers or agents?</label>
                <select
                    style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="distributors_retailers_agents"
                    id="distributors_retailers_agents"
                    defaultValue={marketingEvaluationForm.distributors_retailers_agents}
                    ref={register({ required: "Please indicate whether you have distributors, retailers or agents" })}
                >
                    <option value={""} hidden>Choose One</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                {errors.distributors_retailers_agents && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.distributors_retailers_agents.message} *</p>}
            </div>
            <div style={{ marginTop: "1rem", paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                <label htmlFor="indirect_channels" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>How many indirect channels do you manage?</label>
                <select
                    style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="indirect_channels"
                    id="indirect_channels"
                    defaultValue={marketingEvaluationForm.indirect_channels}
                    ref={register({ required: false })}
                >
                    <option hidden value={""}>Approximately</option>
                    <option value={"1-5"}>1-5</option>
                    <option value={"5-10"}>5-10</option>
                    <option value={"15-20"}>15-20</option>
                    <option value={"20+"}>20+</option>
                </select>
            </div>
        </div>
    );
}

export default Retailers;