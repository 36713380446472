import React, { useState } from 'react'
import { Button, Modal, Form, Label, Menu } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const AddBrand = ({ setBrands, originalText }) => {

    const { handleSubmit, errors, register } = useForm();
    const [open, setOpen] = useState(false);

    const handleAddingBrand = (data) => {
        axiosWithAuth()
            .post(`/brands`, { brands: [{ ...data, companies_id: Number(originalText) }] })
            .then(res => {
                setBrands(res.data)
                setOpen(false)
            }).catch(err => { console.log(err) })
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={<Menu.Item style={{cursor: "pointer"}}>Add Brand</Menu.Item>}
        >
            <Modal.Header>Add Brand</Modal.Header>
            <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div>
                    <Form style={{ width: "700px", margin: "0 auto", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            defaultValue={""}
                            name="name"
                            id="name"
                            ref={register({
                                required: "Please provide the brand name",
                                minLength: {
                                    value: 2,
                                    message: "Brand must be at least 2 characters"
                                }
                            })}
                        />
                        {errors.name && <Label basic color='red' pointing >* {errors.name.message} *</Label>}
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button type="submit" onClick={handleSubmit(handleAddingBrand)} basic color='yellow'>
                    Save
        </Button>
                <Button onClick={() => setOpen(false)} basic color='red'>
                    Exit
        </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default AddBrand;