import { Table, Button, Menu, Header } from 'semantic-ui-react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ManageSingleGeographic from "./managesinglegeographic";
import AddGeographic from "./addgeographic";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';


const GeographicsTable = ({ userDash }) => {

    const [geographics, setGeographics] = useState([])
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var companyID = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${companyID}/geographics`)
            .then(res => {
                setGeographics(res.data)
            }).catch(err => console.log(err))
    }, [])

    const handleRemovingGeographic = (geographicId) => {
        axiosWithAuth()
            .delete(`/geographics/${geographicId}`)
            .then(res => {
                setGeographics(geographics.filter(geo => {
                    if (geo.id !== geographicId) {
                        return geo
                    }
                }))
            }).catch(err => console.log(err))
    }

    return (
        <>
            {geographics && geographics.length > 0 ? (
                <>
                    {userDash === true && <Header style={{ textAlign: "left", width: "1100px", margin: "2rem auto -2rem auto" }}>Regions</Header>}
                    <Table celled style={{ width: "1100px", margin: "3rem auto" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Percentage</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {geographics.map(geographic => (
                                <Table.Row key={geographic.id}>
                                    <Table.Cell style={{ width: "50%" }}>{geographic.name}</Table.Cell>
                                    <Table.Cell style={{ width: "20%" }}>{geographic.percentage}%</Table.Cell>
                                    <Table.Cell style={{ width: "30%", textAlign: "center" }}><ManageSingleGeographic geographic={geographic} geographics={geographics} setGeographics={setGeographics} /><Button color="red" basic onClick={() => handleRemovingGeographic(geographic.id)}>Delete</Button></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='3'>
                                    <Menu floated='right'>
                                        <AddGeographic setGeographics={setGeographics} geographics={geographics} companyID={companyID} />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </>)
                :
                <div style={{ padding: "5rem 0rem", textAlign: "center" }}>There are no regions in our system</div>
            }
        </>
    )
}

export default GeographicsTable;