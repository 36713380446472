function Tradeshows({ handleChange, inputs, register, errors }) {
    return (
        <>
            <div style={{ padding: "0% 2%", marginTop: "1rem", display: "flex", flexDirection: "column" }}>
                <label htmlFor="tradeshows" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Are you (or were you) relying on tradeshows to generate leads?</label>
                <select
                    style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="tradeshows"
                    id="tradeshows"
                    onChange={handleChange}
                    ref={register({ required: "Please indicate whether you are using or have used tradeshows in the past" 
                })}
                >
                    <option value={""} hidden>Choose One</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                {errors.tradeshows && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.tradeshows.message} *</p>}
            </div>
            {inputs.tradeshows === true && (
                <div style={{ marginTop: "1rem", paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                    <label htmlFor="tradeshows_used" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Which tradeshows?</label>
                    <input
                      class="market-eval-text"
                        type="text"
                        name="tradeshows_used"
                        id="tradeshows_used"
                        
                        ref={register({ required: "Please provide the names of those tradeshows", maxLength: 100 })}
                    />
                    {errors.tradeshows_used && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.tradeshows_used.message} *</p>}
                </div>
            )}
        </>
    );
}

export default Tradeshows;