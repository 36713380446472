import { Table, Button, Menu, Header } from 'semantic-ui-react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddBrand from "./addbrand";
import ManageSingleBrand from "./managesinglebrand";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';


const BrandsTable = ({ userDash, arToursFormView }) => {

    const [brands, setBrands] = useState([])
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect( async () => {
        var brandsArr = (await axiosWithAuth().get(`/companies/${theId}/brands`)).data;

        if (brandsArr.length > 0) {
            if (arToursFormView) {
                setBrands(brandsArr.filter(brand => {
                    if (brand.ar_tours_forms_id !== null) {
                        return brand;
                    }
                }))
            } else {
                setBrands(brandsArr);
            }
        }
    }, [])

    const handleRemovingBrand = (brandid) => {
        axiosWithAuth()
            .delete(`/brands/${brandid}`)
            .then(res => {
                setBrands(brands.filter(bran => {
                    if (bran.id !== brandid) {
                        return bran
                    }
                }))
            }).catch(err => console.log(err))
    }

    return (
        <>
            {brands && brands.length > 0 &&(
                <>
                    {userDash === true && <Header style={{ textAlign: "left", width: "1100px", margin: "2rem auto -2rem auto" }}>Company Brands</Header>}
                    <Table celled style={{ width: "1100px", margin: "3rem auto" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {brands.map(brand => (
                                <Table.Row key={brand.id}>
                                    <Table.Cell style={{ width: "75%" }}>{brand.name}</Table.Cell>
                                    <Table.Cell style={{ width: "25%", textAlign: "center" }}><ManageSingleBrand brands={brands} setBrands={setBrands} brand={brand} /><Button color="red" basic onClick={() => handleRemovingBrand(brand.id)}>Delete</Button></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='2'>
                                    <Menu floated='right'>
                                        <AddBrand setBrands={setBrands} originalText={theId} />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </>
            )}
            {brands && brands.length === 0 &&(
                <div style={{ padding: "5rem 0rem", textAlign: "center" }}>
                    <p>There are no brands in our system</p>
                    <AddBrand setBrands={setBrands} originalText={theId} />
                </div>
            )}
        </>
    )
}

export default BrandsTable;