import React, { useState } from 'react'
import { Button, Modal, Form, Label } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const ManageSingleMarket = ({ market, setMarkets, markets }) => {

  const originalMarketsName = market.name;
  const { handleSubmit, errors, register } = useForm();
  const [open, setOpen] = useState(false);

  const handleUpdatingMarket = (data) => {
    if (data.name !== originalMarketsName) {
      axiosWithAuth()
        .put(`/markets/${market.id}`, data)
        .then(res => {
          setMarkets(markets.map(mark => {
            if (mark.id === res.data.id) {
              return res.data
            } else {
              return mark
            }
          }))
          setOpen(false)
        }).catch(err => { console.log(err) })
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button onClick={e => e.preventDefault()} basic color='yellow'>Manage</Button>}
    >
      <Modal.Header>Manage Market</Modal.Header>
      <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div>
          <Form style={{ width: "700px", margin: "0 auto", display: "flex", flexDirection: "column" }}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              defaultValue={market.name}
              name="name"
              id="name"
              ref={register({
                required: "You must provide a market name",
                minLength: {
                  value: 2,
                  message: "Market name must be at least 2 characters"
                }
              })}
            />
            {errors.name && <Label basic color='red' pointing >* {errors.name.message} *</Label>}
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" onClick={handleSubmit(handleUpdatingMarket)} basic color='yellow'>
          Save
        </Button>
        <Button onClick={() => setOpen(false)} basic color='red'>
          Exit
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ManageSingleMarket;