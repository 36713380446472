import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { userCreatingCompany } from "../../reduxStore/actions/companies-actions";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Label, Divider, Icon, Popup } from 'semantic-ui-react';
import Breadcrumbs from "../breadcrumbs"
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth.js";
import CustomLoader from "../Loader";
import { useSnackbar } from 'notistack';

const AddCompany = ({ userCreatingCompany }) => {

    const [accountManagers, setAccountManagers] = useState([]);
    const [checkBox, setCheckBox] = useState(true);
    const [err, setErr] = useState("");
    const [services, setServices] = useState([]);
    const history = useHistory();
    const { handleSubmit, errors, register, watch } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const password = useRef({});
    password.current = watch("password", "");
    const breadcrumbPath = [{ name: "Dashboard", url: "/employeedashboard" }, { name: "Create Company" }];
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        axiosWithAuth()
            .get(`users/`)
            .then(res => {
                setAccountManagers(res.data);
            }).catch(err => console.log(err));
    }, []);

    useEffect(() => {
        axiosWithAuth()
            .get(`services/`)
            .then(res => {
                setServices(res.data);
            }).catch(err => console.log(err))
    }, []);

    const handleDataFormatting = data => {
        var service_of_interest = {};

        for (const [key, value] of Object.entries(data)) {
            if (typeof value === "boolean") {
                service_of_interest[key] = value;
                delete data[key];
            }
        }

        return { ...data, service_of_interest, need_info: checkBox };
    };

    const handleSignup = data => {
        setIsLoading(true);
        var oneDriveURL = data["oneDrive"];
        delete data["confirm_password"];
        delete data["oneDrive"];

        userCreatingCompany(handleDataFormatting(data))
            .then(response => {

                if (response.hubspotError) {
                    enqueueSnackbar(`User has been created for ${response.user.name}, but an error occured while communicating with HubSpot`, { variant: "warning" });
                } else {
                    enqueueSnackbar(`User has been created for ${response.user.name} has been created successfully`, { variant: "success" });
                }
                
                if (oneDriveURL !== "") {
                    axiosWithAuth()
                        .post(`/creds`, {"name": "one drive", "data": oneDriveURL, "companies_id": response.user.id })
                        .then(res => {
                            history.push("/employeedashboard");
                            setIsLoading(false);
                        }).catch(err => {
                            history.push("/employeedashboard");
                            setIsLoading(false);
                        });
                } else {
                    history.push("/employeedashboard");
                    setIsLoading(false);
                }
            }).catch(err => {
                enqueueSnackbar(err.errorMessage, { variant: "error" });
                setIsLoading(false);
            })
    };

    const handleCheckBox = () => {
        setCheckBox(!checkBox);
    };

    return (
        <div>
            <CustomLoader hide={!isLoading} />
            <div style={{ width: "90%", margin: "0 auto", textAlign: "left", marginTop: "2rem" }} hidden={isLoading}>
                <Breadcrumbs path={breadcrumbPath} />
            </div>
            <div style={{ paddingBottom: "5rem" }} >
                <div style={{ width: "60%", display: "flex", flexDirection: "column", alignItems: "space-between", backgroundColor: "rgb(250,250,250)", boxShadow: "6px 7px 3px 1px #C6C6C6", margin: "3rem auto" }}>
                    <div style={{ backgroundColor: "rgb(255,191,77)", width: "100%", textAlign: "center" }}>
                        <div style={{ fontSize: "2rem", padding: "1rem", color: "rgb(51,51,51)" }}>Create Company</div>
                    </div>
                    <section style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center", marginTop: ".5rem" }}>
                        <form onSubmit={handleSubmit(handleSignup)} autoComplete="off" style={{ display: "flex", flexDirection: "column", width: "95%", justifyContent: "space-evenly" }}>
                            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    <label style={{ fontWeight: 700, fontSize: "18px", textAlign: "left", marginLeft: "1rem" }} htmlFor="first_name">First Name <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            defaultValue={""}
                                            ref={register({
                                                required: "You must provide a first name",
                                                minLength: {
                                                    value: 2,
                                                    message: "First name must be at least 2 characters"
                                                }
                                            })} />

                                        {errors.first_name && <Label basic color='red' pointing>{errors.first_name.message}</Label>}
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    <label style={{ fontWeight: 700, fontSize: "18px", textAlign: "left", marginLeft: "1rem" }} htmlFor="last_name">Last Name <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            defaultValue={""}
                                            ref={register({
                                                required: "You must provide a last name",
                                                minLength: {
                                                    value: 2,
                                                    message: "Last name must be at least 2 characters"
                                                }
                                            })} />
                                        {errors.last_name && <Label basic color='red' pointing>{errors.last_name.message}</Label>}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="name">Company Name<span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="text"
                                            name="name"
                                            id="name"
                                            defaultValue={""}
                                            ref={register({
                                                required: "You must provide a company name",
                                                minLength: {
                                                    value: 2,
                                                    message: "Company name must be at least 2 characters"
                                                }
                                            })} />
                                        {errors.name && <Label basic color='red' pointing>{errors.name.message} </Label>}
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="email">Email <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }} onClick={() => err ? setErr(""): null}>
                                        {/*Input and error handling goes in here */}
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="text"
                                            name="email"
                                            id="email"
                                            defaultValue={""}
                                            autoComplete="new-email"
                                            ref={register({
                                                required: "You must enter an email",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Please enter a valid email address"
                                                }
                                            })} />
                                        {errors.email && <Label basic color='red' pointing>{errors.email.message}</Label>}
                                        {err && <Label basic color='red' pointing>{err}</Label>}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="phone_number">Phone Number <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        {/*Input and error handling goes in here */}
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="tel"
                                            name="phone_number"
                                            id="phone_number"
                                            defaultValue={""}
                                            ref={register({
                                                required: "You must enter a valid phone number",
                                                pattern: {
                                                    value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                                                    message: "Please enter a valid phone number"
                                                }
                                            })} />
                                        {errors.phone_number && <Label basic color='red' pointing>{errors.phone_number.message}</Label>}
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="users_id">Account Manager <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        {/*Input and error handling goes in here */}
                                        <select
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            name="users_id"
                                            id="users_id"
                                            ref={register({
                                                required: "You must specify who the account manager will be", valueAsNumber: true
                                            })}>
                                            <option hidden value={""}>Choose One</option>
                                            {accountManagers && accountManagers.length > 0 && accountManagers.map(manager => (
                                                <option key={manager.id} value={manager.id}>{manager.first_name} {manager.last_name}</option>
                                            ))}

                                        </select>
                                        {errors.users_id && <Label basic color='red' pointing>{errors.users_id.message}</Label>}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="password">Password <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        {/*Input and error handling goes in here */}
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="password"
                                            name="password"
                                            id="password"
                                            defaultValue={""}
                                            ref={register({
                                                required: "You must enter a password",
                                                minLength: {
                                                    value: 8,
                                                    message: "Your password must have at least 8 characters",
                                                },
                                            })} />
                                        {errors.password && <Label basic color='red' pointing>{errors.password.message}</Label>}
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "baseline", width: "50%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="confirm_password">Confirm Password <span style={{ color: "red" }}>*</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        {/*Input and error handling goes in here */}
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="password"
                                            name="confirm_password"
                                            id="confirm_password"
                                            defaultValue={""}
                                            ref={register({
                                                required: false,
                                            })} />
                                        {errors.confirm_password && <Label basic color='red' pointing>{errors.confirm_password.message}</Label>}
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: "flex", marginTop: "1rem" }}>
                                <div style={{ display: "flex", alignItems: "baseline", width: "100%", flexDirection: "column" }}>
                                    {/*label goes here*/}
                                    <label style={{ fontWeight: 700, fontSize: "18px", marginLeft: "1rem", textAlign: "left" }} htmlFor="oneDrive">One Drive URL <span style={{fontSize: "12px"}}>(optional)</span></label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "95%", margin: "0 auto" }}>
                                        {/*Input and error handling goes in here */}
                                        <input
                                            style={{ height: "1.75rem", borderRadius: "4px", border: "1px solid #ccc", fontSize: "1.2rem", width: "100%", marginTop: ".5rem" }}
                                            type="text"
                                            name="oneDrive"
                                            id="oneDrive"
                                            defaultValue={""}
                                            ref={register({
                                                pattern: {
                                                    value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,
                                                    message: "Please provide a valid url starting with HTTP or HTTPS"
                                                }
                                            })} />
                                        {errors.oneDrvie && <Label basic color='red' pointing>{errors.oneDrive.message}</Label>}
                                    </div>
                                </div>
                            </div>


                            <Divider style={{ margin: "1rem 0rem" }} />
                            <p style={{ textAlign: "left", marginLeft: "1rem" }}><strong>If the client has expressed interest in any of our extra services, then select all that apply</strong></p>
                            <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "2rem" }}>
                                {services && services.length > 0 && services.map((service =>
                                    <div key={service.id}>
                                        <input
                                            type="checkbox"
                                            name={service.id}
                                            id={service.id}
                                            style={{ marginRight: ".5rem" }}
                                            ref={register({
                                                required: false
                                            })}
                                        />
                                        <label htmlFor={service.id}>{service.name}</label>
                                    </div>
                                ))}
                            </div>
                            <Divider style={{ margin: ".5rem 0rem" }} />
                            <div style={{ textAlign: "left", marginLeft: "1rem", marginTop: "1rem" }}>
                                <input
                                    type="checkbox"
                                    name="need_info"
                                    id="need_info"
                                    checked={checkBox}
                                    onChange={handleCheckBox}
                                    style={{ marginRight: ".5rem" }}
                                />
                                <label style={{ marginRight: ".5rem" }} htmlFor="need_info" >Collect Inital Onboarding Information</label>
                                <Popup content='If you uncheck this box, the company will not have to fill out the inital onboarding information' offset={[0, 10]} position='bottom center' trigger={<Icon name="info circle" color='yellow' />} />

                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-end", width: "97%", margin: "3rem auto" }}>
                                <button type="submit" style={{ width: "13rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer" }}>Create</button>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        company: state.companiesReducer.company
    };
};

export default connect(mapStateToProps, { userCreatingCompany })(AddCompany);