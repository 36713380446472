import { useForm } from "react-hook-form";
import { Label, Modal, Button, Icon, Header, Divider } from 'semantic-ui-react';
import { useState, useRef } from "react"
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js";


const GeneralInfomation = ({ setArToursForm, formId, history }) => {

    const { handleSubmit, errors, register, watch } = useForm();
    const [open, setOpen] = useState(false);
    const [myData, setMyData] = useState({})
    // Watching our two uncontrolled inputs to make sure the user does leave them blank
    const validation = useRef({});
    validation.current = watch("description_of_needs", "");

    const handleDataFormatting = data => {
        data["form_complete"] = true;
        setMyData(data)
    }

    const handleMyDataPut = () => {
        axiosWithAuth()
            .put(`/artours/${formId}`, myData)
            .then(res => {
                setArToursForm(res.data)
                history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
            }).catch(err => { console.log(err) })
    }

    return (
        <form onSubmit={handleSubmit(handleDataFormatting)}>
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "0 auto 2rem auto" }}>
                <div style={{ fontSize: "2rem", margin: "3rem 0rem" }}>Additional Information</div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="description_of_needs" style={{ marginBottom: "1rem" }}><strong>A brief description of your needs: Rapid tour of main aisles with a few deep dives (most common) or a complete detailed scan</strong></label>
                    <textarea
                        id="description_of_needs"
                        name="description_of_needs"
                        style={{ height: "150px" }}
                        defaultValue={""}
                        ref={register({
                            required: "You must provide us with a description of your company's needs",
                        }
                        )} />
                    {errors.description_of_needs && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.description_of_needs.message} </Label>}
                </div>
            </section>
            <Divider section style={{ margin: "0 3rem" }} />
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "2rem auto" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="publish_new_data" style={{ marginBottom: "1rem" }}><strong>Note: Augmented Reality elements must be published with an URL to be picked up by the system. We usually use LinkedIn for key staff, YouTube videos from your channel or website elements featuring key aspects of your company. If we need to create/publish new data, please tell us what your requirements are:</strong></label>
                    <textarea
                        id="publish_new_data"
                        name="publish_new_data"
                        style={{ height: "150px" }}
                        defaultValue={""}
                        ref={register({})}
                    />
                    {errors.publish_new_data && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.publish_new_data.message} </Label>}
                </div>
            </section>
            <div style={{ width: "80%", margin: "0 auto", textAlign: "right" }}>
                <Modal
                    basic
                    onClose={() => setOpen(false)}
                    onOpen={() => {
                        // checking to make sure the text areas are completed before rendering the confirm submission modal
                        if (validation.current.length > 0) {
                            setOpen(true)
                        }
                    }}
                    open={open}
                    size='small'
                    trigger={<button style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginBottom: "2rem" }}>Submit</button>}
                >
                    <Header icon><Icon name='send' />AR Tours Form</Header>
                    <Modal.Content>
                        <p style={{ fontWeight: 700 }}>After submission, this form will be sent for review and will only be accessible in the form management tab located in settings. Are you sure you want to submit?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic color='red' inverted onClick={() => setOpen(false)}>
                            <Icon name='remove' /> No
                        </Button>
                        <Button type="submit" color='green' inverted onClick={() => {
                            handleMyDataPut()
                            setOpen(false)
                        }}>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </form>
    )
}

export default GeneralInfomation;