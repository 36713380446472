import chartImage from '../../internalData/chart-image.jpg';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box/'
import { grey } from "@material-ui/core/colors";
import Typography  from "@material-ui/core/Typography";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import Link from '@material-ui/core/Link'
const useStyles = makeStyles((theme) => ({
 header:{
   display: "flex", 
   justifyContent: "center", 
   padding: theme.spacing(3),
   textAlign: "center",
   alignItems: "center",
   alignContent: "center",
   
 },
 link:{
     paddingRight: theme.spacing(2),
    color: grey[400],
 },
 grey:{
    color: grey[600],
    
 }
  }
)
)
const Footer = () => {
    const classes = useStyles();
        // <footer style={{ position: "absolute", bottom: 0, height: "75px", margin: "0 auto", textAlign: "center", display: "flex", justifyContent: "space-evenly", width: "100%", alignItems: "center", backgroundColor: "rgb(250,250,250)", borderTop: "rgb(212,212,212) 1px solid" }}>
        //     <p style={{ width: "30%", color: "grey" }}>Copyright © 2021, LEVY Marketers for Industry + Tech </p>
        //     <div style={{ width: "40%" }}>
        //         <a href="https://www.linkedin.com/company/levy-ind" rel="noreferrer" target="_blank" ><Icon size='big' color='grey' name="linkedin square" /></a>
        //         <a href="https://www.facebook.com/LEVYmarketers/" rel="noreferrer" target="_blank" ><Icon size='big' color='grey' name="facebook" /></a>
        //         <a href="https://twitter.com/Levy_ind" rel="noreferrer" target="_blank" ><Icon size='big' color='grey' name="twitter square" /></a>
        //         <a href="https://www.instagram.com/levymarketers/" rel="noreferrer" target="_blank" ><Icon size='big' color='grey' name="instagram" /></a>
        //     </div>
        //     <div style={{ display: "flex", justifyContent: "space-evenly", width: "30%" }}>
        //         <p><a style={{ color: "grey" }} rel="noreferrer" href="https://f.hubspotusercontent00.net/hubfs/4675051/GrowthHive%20Documents/Privacy%20Policy_GrowthHive%20by%20LEVY.pdf" target="_blank">Privacy Policy</a></p>
        //         <p><a style={{ color: "grey" }} rel="noreferrer" href="https://f.hubspotusercontent00.net/hubfs/4675051/GrowthHive%20Documents/Terms%20and%20Conditions_GrowthHive%20by%20LEVY%20(August%2011%202020).pdf" target="_blank">Terms of Use</a></p>
        //         <p><a style={{ color: "grey" }} rel="noreferrer" href="https://f.hubspotusercontent00.net/hubfs/4675051/GrowthHive%20Documents/GrowthHive-by-LEVY-Code-of-Ethics.pdf" target="_blank">Ethics Policy</a></p>
        //     </div>
        // </footer>

        return (
          <Box borderTop={1} borderColor="grey.500">
        <Container className={classes.header} maxWidth="xl">
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Typography variant="body1">Copyright © 2021, GrowthHive </Typography>
            </Grid>
            <Grid item xs={6}>
            <a href="https://www.linkedin.com/company/levy-ind" rel="noreferrer" target="_blank"> <LinkedInIcon className={classes.grey} fontSize="large"/> </a>
            <a href="https://www.facebook.com/LEVYmarketers/" rel="noreferrer" target="_blank">  <FacebookIcon className={classes.grey}  fontSize="large" /> </a>
            <a href="https://twitter.com/Levy_ind" rel="noreferrer" target="_blank" > <TwitterIcon className={classes.grey}  fontSize="large"/> </a>
            <a href="https://www.instagram.com/levymarketers/" rel="noreferrer" target="_blank"> <InstagramIcon className={classes.grey}  fontSize="large" />   </a>
            </Grid>
            <Grid item xs={3}> 
            <Link  className={classes.link} href="https://f.hubspotusercontent00.net/hubfs/4675051/GrowthHive%20Documents/Privacy%20Policy_GrowthHive%20by%20LEVY.pdf" target="_blank"> Privacy Policy</Link>
            <Link className={classes.link} href="https://f.hubspotusercontent00.net/hubfs/4675051/GrowthHive%20Documents/Terms%20and%20Conditions_GrowthHive%20by%20LEVY%20(August%2011%202020).pdf" target="_blank"> Terms of Use</Link>
            <Link  className={classes.link} href="https://f.hubspotusercontent00.net/hubfs/4675051/GrowthHive%20Documents/GrowthHive-by-LEVY-Code-of-Ethics.pdf" target="_blank"> Ethics Policy</Link>
            </Grid>
        </Grid>
          </Container>
          </Box>
    )
}

export default Footer;