import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'rgb(247,197,70)',
        color: '#424242'
    },
    needsAttention: {
        display: 'flex',
        alignItems: 'center'
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomAppBar = ({ handleChange, TabDisplay, needInfoServices, value }) => {

    const classes = useStyles();

    return (
        <AppBar position="static">
            <Tabs value={value} onChange={handleChange} className={classes.root} TabIndicatorProps={{ style: { background: '#424242' } }} variant="scrollable" aria-label="simple tabs example">
                <Tab label="Home" {...a11yProps(0)} />
                <Tab label={needInfoServices && needInfoServices.length > 0 ? <div className={classes.needsAttention} >Needs Attention <Badge badgeContent={needInfoServices.length} color="rgb(247, 197, 70)"><InfoIcon style={{ marginLeft: "10px" }} /></Badge></div> : <div>Needs Attention</div>} {...a11yProps(1)} />
                <Tab label="Company Information" {...a11yProps(2)} />
                <Tab label="Brand Positioning" {...a11yProps(3)} />
                <Tab label="Advertising" {...a11yProps(4)} />
                <Tab label="Market Mapping" {...a11yProps(5)} />
                <Tab label="Trade Events" {...a11yProps(6)} />
                <Tab label="AR Tours" {...a11yProps(7)} />
                <Tab label="Available Services" {...a11yProps(8)} />
            </Tabs>
        </AppBar>
    )
}

export default CustomAppBar;
