import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Label, Button, Input, Divider, Form } from 'semantic-ui-react';

const Facilitiies = ({ arToursform, setArToursForm, url, formName, fillOutArToursForm, history, formId, originalText }) => {

    const [facilities, setFacilities] = useState([["name0", "location0", "square_footage0"]]);
    const { handleSubmit, errors, register } = useForm();

    useEffect(() => {
        if (arToursform.facilities_complete === 1) {
            history.push(`${url}/brands`)
        }
    }, [])

    const handleAddingInput = (myI, e) => {
        e.preventDefault()
        if (myI < 5) {
            setFacilities([...facilities, [`name${myI + 1}`, `location${myI + 1}`, `square_footage${myI + 1}`]])
        }
    }

    const handleRemovingInput = (myI, e) => {
        e.preventDefault()
        if (facilities.length > 1) {
            setFacilities(facilities.filter(facility => {
                if (facility[0] !== `name${myI}`) {
                    return facility
                }
            }))
        }
    }

    const handleFilteringData = data => {

        let myObject = {}
        let compId = Number(originalText)

        for (const [key, value] of Object.entries(data)) {
            if (!myObject[key.slice(-1)]) {
                if (key.includes("name")) {
                    myObject[key.slice(-1)] = { name: value, companies_id: compId, ar_tours_forms_id: Number(formId) }
                } else if (key.includes("location")) {
                    myObject[key.slice(-1)] = { location: value, companies_id: compId }
                } else {
                    myObject[key.slice(-1)] = { square_footage: value, companies_id: compId }
                }
            }
            else {
                if (key.includes("name")) {
                    myObject[key.slice(-1)] = { ...myObject[key.slice(-1)], name: value }
                } else if (key.includes("location")) {
                    myObject[key.slice(-1)] = { ...myObject[key.slice(-1)], location: value }
                } else {
                    myObject[key.slice(-1)] = { ...myObject[key.slice(-1)], square_footage: value }
                }
            }
        }
        return { "facilities": Object.values(myObject), isForm: true }
    }

    const handlePost = data => {
        const formattedData = handleFilteringData(data);

        fillOutArToursForm(formName, formattedData)
            .then(res => {
                setArToursForm({
                    ...arToursform,
                    facilities_complete: true
                })
                history.push(`${url}/brands`);
            }).catch(err => { console.log(err) })
    }

    return (
        <div style={{ textAlign: "left", padding: "2rem" }}>
            <div style={{ fontSize: "2rem", margin: "1rem 0rem" }}><strong>Facilities</strong></div>
            <p>Has it been difficult to get people to visit your facility lately? Do you “get the order” when they visit you? We have a product that might help. We took an efficient tool used by realtors and adapted it to our needs with augmented reality elements such as key staff bios, pictures, videos and more. Think of it as an immersive 360° Virtual Tour of your facilities.</p>
            <div>
                <Form onSubmit={handleSubmit(handlePost)} style={{ display: "flex", flexDirection: "column" }}>
                    {facilities && facilities.map((facility, i) => (
                        <div key={i} style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", }}>
                                <label style={{ width: "35%", marginRight: "1rem" }} htmlFor={facility[0]}>Facility Name</label>
                                <label htmlFor={facility[1]} style={{ width: "32%" }}>Street Address</label>
                                <label htmlFor={facility[1]}>Square Feet</label>
                            </div>
                            <div style={{ display: "flex", margin: ".5rem 0rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "35%", marginRight: "1rem", alignItems: "center" }}>
                                    <input
                                        type="text"
                                        id={facility[0]}
                                        name={facility[0]}
                                        ref={register({
                                            required: "You must provide a Facility name",
                                            minLength: {
                                                value: 2,
                                                message: "Facility name must be at least 2 characters"
                                            }
                                        })}
                                    />
                                    {errors[facility[0]] && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem" }} pointing>{errors[facility[0]].message}</Label>}
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "30%", marginRight: "1rem", alignItems: "center" }}>
                                    <input
                                        type="text"
                                        id={facility[1]}
                                        name={facility[1]}
                                        ref={register({
                                            required: "You must provide the location",
                                            minLength: {
                                                min: 5,
                                                message: "Streey Address must be at least 5 characters"
                                            }
                                        })} />
                                    {errors[facility[1]] && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem" }} pointing>{errors[facility[1]].message}</Label>}
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "12%", marginRight: "1rem", alignItems: "center" }}>
                                    <input
                                        type="text"
                                        id={facility[2]}
                                        name={facility[2]}
                                        ref={register({
                                            required: "Required",
                                            valueAsNumber: true,
                                            validate: (value) => value > 1 || "Required",
                                        })} />
                                    {errors[facility[2]] && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem" }} pointing>{errors[facility[2]].message}</Label>}
                                </div>
                                {i === facilities.length - 1 &&
                                    <div>
                                        <Button icon="add" color="yellow" onClick={(e) => handleAddingInput(i, e)} />
                                        <Button icon="x" onClick={(e) => handleRemovingInput(i, e)}></Button>
                                    </div>}
                            </div>
                        </div>
                    ))}
                    <Divider />
                    <div style={{ marginTop: ".5rem", textAlign: "left", display: "flex", justifyContent: "flex-end" }}>
                        <Button type="submit" color="yellow">Submit</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}


export default Facilitiies;