// Used for formatting brands and wesbites data for post request
export const filterData = (data, inputName, table, company_id) => {
    const my_dictionary = {}
    const the_table_name = []
    const urlCheckDictionary = {}

    for (const [key, value] of Object.entries(data)) {
        if (inputName === "name") {
            the_table_name.push({ name: value, companies_id: company_id })
        } else {
            if (urlCheckDictionary[value]) {
                return false
            }
            else {
                urlCheckDictionary[value] = value
                the_table_name.push({ url: value, companies_id: company_id })
            }
        }
    }
    my_dictionary[table] = the_table_name;
    return my_dictionary;
}


// used in socials component to validate URLS and format data
export const checkURLsAndProcessData = (data, companies_id) => {
    const my_dictionary = {}
    const urlCheckDictionary = {}
    for (const [key, value] of Object.entries(data)) {
        //Checkiing for the identical urls
        if (key.includes("url")) {
            if (urlCheckDictionary[value]) {
                return false
            }
            else {
                urlCheckDictionary[value] = value
            }
        }
        //filtering and matching data
        const newKey = key.replace("name", "");
        const filteredKey = newKey.replace("url", "");

        if (!my_dictionary[filteredKey]) {
            my_dictionary[filteredKey] = value
        }
        else {
            my_dictionary[filteredKey] = { "name": my_dictionary[filteredKey], "url": value, companies_id: companies_id }
        }
    }
    return { "socials": Object.values(my_dictionary) }
}