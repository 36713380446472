function MailingList({ handleChange, inputs, register, errors }) {
    return (
        <>
            <div style={{ padding: "0% 2%", display: "flex", flexDirection: "column" }}>
                <label htmlFor="mailing_list" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Do you have a mailing list?</label>
                <select
                    style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="mailing_list"
                    id="mailing_list"
                    onChange={handleChange}
                    ref={register({ required: "Please indicate whether you have a mailing list"
                })}>
                    <option value={""} hidden>Choose One</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                {errors.mailing_list && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.mailing_list.message} *</p>}
            </div>
            {inputs.mailing_list === true && (
                <div style={{ marginTop: "1rem", paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                    <label htmlFor="number_of_contact" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>How many active contacts do you currently have?</label>
                    <select
                        style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                        name="number_of_contact"
                        id="number_of_contact"
                        ref={register({ required: "Please provide an approximate number of your active contacts" })}
                    >
                        <option hidden value={""}>Approximately</option>
                        <option value={"Less than 1,000"}>Less than 1,000</option>
                        <option value={"1,000 to 5,000"}>1,000 to 5,000</option>
                        <option value={"5,000 to 10,000"}>5,000 to 10,000</option>
                        <option value={"More than 10,000"}>More than 10,000</option>
                    </select>
                    {errors.number_of_contact && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.number_of_contact.message} *</p>}
                </div>
            )}
            {inputs.mailing_list === true && (
                <div style={{ marginTop: "1rem", paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                    <label htmlFor="contact_system" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600, }}>What email system do you use?</label>
                    <input
                        type="text"
                        name="contact_system"
                        id="contact_system"
                        class="market-eval-text"
                        placeholder="MailChimp, iContact, Constant Contact…"
                    
                        ref={register({ required: "Please provide the name of your current email system", maxLength: 60 })}
                    />
                    {errors.contact_system && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.contact_system.message} *</p>}
                </div>

            )}
        </>
    );
}

export default MailingList;