import ManageForms from "../../manageforms";


const FormStatus = () => {


    return (
        <ManageForms adminView={true}/>
    )
}


export default FormStatus;