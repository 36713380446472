import { connect } from 'react-redux';
import { useEffect, useState } from "react";
import { useParams, Route, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import Breadcrumbs from "../breadcrumbs";
import Markets from "./markets";
import Channels from "./channels";
import Geographics from "./geographics";
import ProductsServices from "./productsservices";
import { fillOutMarketMappingForm } from "../../reduxStore/actions/companies-actions";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from '../../helperFunctions/axiosWithAuth';

const MarketMapping = ({ fillOutMarketMappingForm }) => {

    const { id } = useParams();
    let { path, url } = useRouteMatch();
    const [marketMappingForm, setMarketMappingForm] = useState({});
    const history = useHistory();
    const breadcrumbPath = [{ name: "Dashboard", url: `/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}` }, { name: "Market Mapping" }]
    const location = useLocation();
    var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    const findLastCompletedForm = (form) => {
        // checking to make sure the user accessing this form is the owner of the form
        if (form.companies_id === Number(theId)) {
            // setting route to where the user last left off completing form
            if (form && Object.keys(form).length > 0)
                if (form.markets_complete === false) {
                    history.push(`${url}/markets`);
                } else if (form.channels_complete === false) {
                    history.push(`${url}/channels`);
                } else if (form.geographics_complete === false) {
                    history.push(`${url}/geographics`);
                } else if (form.products_services_complete === false) {
                    history.push(`${url}/productsservices`)
                } else {
                    history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
                }
        } else {
            history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
        }
    };

    useEffect(() => {
        axiosWithAuth()
            .get(`/marketmapping/${id}`)
            .then(res => {
                // checking to make sure the default component isn't rendering without one of the nested routes
                if (location.pathname === `/marketmapping/${id}` || location.pathname === `/marketmapping/${id}/markets` || location.pathname === `/marketmapping/${id}/channels` || location.pathname === `/marketmapping/${id}/geographics` || location.pathname === `/marketmapping/${id}/productsservices`) {
                    setMarketMappingForm(res.data)
                    findLastCompletedForm(res.data)
                }
            })
            .catch(err => history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`))
    }, [url.path, location.pathname])


    return (
        <div style={{ paddingBottom: "5rem" }}>
            <div style={{ width: "90%", margin: "0 auto", textAlign: "left", marginTop: "2rem" }}>
                <Breadcrumbs path={breadcrumbPath} />
            </div>
            <div style={{ backgroundColor: "rgb(250,250,250)", width: "55%", margin: "0 auto", boxShadow: "6px 7px 3px 1px #C6C6C6" }} >
                <div style={{ marginTop: "2rem", color: "rgb(51,51,51)", fontSize: "2rem", padding: "1rem", backgroundColor: "rgb(255,191,77)" }}>Market Mapping Form</div>
                <Route path={`${path}/markets`}>
                    <Markets marketMappingForm={marketMappingForm} setMarketMappingForm={setMarketMappingForm} url={url} formName="markets" fillOutMarketMappingForm={fillOutMarketMappingForm} history={history} originalText={theId} />
                </Route>
                <Route path={`${path}/channels`}>
                    <Channels marketMappingForm={marketMappingForm} setMarketMappingForm={setMarketMappingForm} url={url} formName="channels" fillOutMarketMappingForm={fillOutMarketMappingForm} history={history} originalText={theId} />
                </Route>
                <Route path={`${path}/geographics`}>
                    <Geographics marketMappingForm={marketMappingForm} setMarketMappingForm={setMarketMappingForm} url={url} formName="geographics" fillOutMarketMappingForm={fillOutMarketMappingForm} history={history} originalText={theId} />
                </Route>
                <Route path={`${path}/productsservices`}>
                    <ProductsServices marketMappingForm={marketMappingForm} setMarketMappingForm={setMarketMappingForm} url={url} formName="products" fillOutMarketMappingForm={fillOutMarketMappingForm} history={history} originalText={theId} />
                </Route>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        company: state.companiesReducer.company,
    }
}


export default connect(mapStateToProps, { fillOutMarketMappingForm })(MarketMapping);