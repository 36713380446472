import { useForm } from "react-hook-form";
import { Label, Divider } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth";


const ValuesGoals = ({ brandForm, setBrandForm, url }) => {

    const { handleSubmit, errors, register } = useForm();
    const history = useHistory();

    const handlePut = data => {
        data["values_goals_complete"] = true;
        axiosWithAuth()
            .put(`/brandpositioning/${brandForm.id}`, data)
            .then(res => {
                setBrandForm(res.data)
                history.push(`${url}/passionsassets`);
            }).catch(err => { console.log(err) })

    }

    return (
        <form onSubmit={handleSubmit(handlePut)}>
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "5rem auto 3rem auto" }}>
                <h2>3. What are your Core Values?</h2>
                <p style={{ marginBottom: "0" }}><strong>Definition:</strong> Fundamental beliefs on which your business is based.</p>
                <h5><strong>Examples:</strong></h5>
                <ul style={{ marginTop: "0" }}>
                    <li>Caring</li>
                    <li>Commitment to Customer Loyalty</li>
                    <li>Creativity</li>
                    <li>Flexibility</li>
                    <li>Inclusiveness</li>
                    <li>etc..</li>
                </ul>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="core_values" style={{ marginBottom: "1rem" }}><strong>What are your company’s top 6 to 12 Core Values?</strong></label>
                    <textarea
                        id="core_values"
                        name="core_values"
                        style={{ height: "150px" }}
                        defaultValue={brandForm.core_values}
                        ref={register({
                            required: "You must provide your companies core values",
                        }
                        )} />
                    {errors.core_values && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.core_values.message} </Label>}
                </div>
            </section>
            <Divider section style={{ margin: "0 3rem" }} />
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "2rem auto" }}>
                <h2>4. What are your top 3-5 Organizational Goals?</h2>
                <p style={{ marginBottom: "0" }}><strong>Definition:</strong> Objectives you want to meet over specific periods of time.</p>
                <h5><strong>Examples:</strong></h5>
                <ul style={{ marginTop: "0" }}>
                    <li>Increase profitability by 25% over the next year</li>
                    <li>Eliminate workplace accidents over the next 18 months</li>
                    <li>Double production capacity in 3 years</li>
                    <li>Become the global market leader in 5 years</li>
                    <li>etc..</li>
                </ul>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label htmlFor="organizational_goals" style={{ marginBottom: "1rem" }}><strong>What are your company’s top 4 to 6 Organizational Goals?</strong></label>
                    <textarea
                        id="organizational_goals"
                        name="organizational_goals"
                        style={{ height: "150px" }}
                        defaultValue={brandForm.organizational_goals}
                        ref={register({
                            required: "You must provide your companies organizational goals",
                        }
                        )} />
                    {errors.organizational_goals && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.organizational_goals.message} </Label>}
                </div>
            </section>
            <div style={{ width: "80%", margin: "0 auto", textAlign: "right" }}>
                <button style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginBottom: "2rem", marginRight: "1rem" }} onClick={() => { history.push(`${url}/companymissioncomplete`); }}>Previous</button>
                <button type="submit" style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginBottom: "2rem" }}>Next</button>
            </div>
        </form>
    )
}

export default ValuesGoals;