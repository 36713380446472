import { Table, Button, Menu, Header } from 'semantic-ui-react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js"
import ManageSingleSocial from "./managesinglesocial";
import AddSocial from "./addsocial";
import CryptoJS from "crypto-js";


const SocialsTable = ({ userDash }) => {

    const [socials, setSocials] = useState([])
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${theId}/socials`)
            .then(res => {
                setSocials(res.data)
            }).catch(err => console.log(err))
    }, [])


    function capitalizeFirstLetter(string) {
        if (string === "linkedin") {
            const newLinked = string.charAt(0).toUpperCase() + string.slice(1)
            return newLinked.slice(0, 6) + newLinked.charAt(6).toUpperCase() + string.slice(7)
        } else {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }

    const handleRemovingSocial = (socialid) => {
        axiosWithAuth()
            .delete(`/socials/${socialid}`)
            .then(res => {
                setSocials(socials.filter(soc => {
                    if (soc.id !== socialid) {
                        return soc
                    }
                }))
            }).catch(err => console.log(err))
    }

    return (
        <>
            {socials && socials.length > 0 ? (
                <>
                    {userDash === true && <Header style={{ textAlign: "left", width: "1100px", margin: "2rem auto -2rem auto" }}>Social Media</Header>}
                    <Table celled style={{ width: "1100px", margin: "3rem auto" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Platform</Table.HeaderCell>
                                <Table.HeaderCell>URL</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {socials.map(social => (
                                <Table.Row key={social.id}>
                                    <Table.Cell style={{ width: "20%" }}>{capitalizeFirstLetter(social.name)}</Table.Cell>
                                    <Table.Cell style={{ width: "50%" }}>{social.url}</Table.Cell>
                                    <Table.Cell style={{ width: "30%", textAlign: "center" }}><ManageSingleSocial socials={socials} social={social} setSocials={setSocials} /><Button color="red" basic onClick={() => handleRemovingSocial(social.id)}>Delete</Button></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='3'>
                                    <Menu floated='right'>
                                        <AddSocial setSocials={setSocials} originalText={theId} />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </>)
                :
                <div style={{ padding: "5rem 0rem", textAlign: "center" }}>
                    <p>There are no socials in our system</p>
                    <AddSocial setSocials={setSocials} originalText={theId} />
                </div>
            }
        </>
    )
}

export default SocialsTable;