import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const HelperButtons = ({ setInputs, inputs, numberURL, setNumberURL, handleAddingInput, comp, numberOfInput, handleSkip }) => {

    return (
        <>
            <div style={{ width: "100%", marginTop: "1rem", display: "flex", justifyContent: "flex-end", borderBottom: "grey solid 1px", paddingBottom: "1rem" }}>
                <button
                    onClick={e => handleAddingInput(e, setInputs, inputs, numberURL, setNumberURL, numberOfInput)}
                    style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1rem", fontSize: ".8rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", margin: ".2rem", marginRight: "3rem" }}>
                    <FontAwesomeIcon className='top-links' icon={faPlus} /> Add {comp}
                </button>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginRight: "5%" }}>
                <section style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button onClick={(e) => handleSkip(e)} style={{ width: "6rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1rem", fontSize: "1rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", margin: "1rem .2rem" }}>Skip</button>
                    <button type='submit' style={{ width: "6rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1rem", fontSize: "1rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", margin: "1rem .2rem" }}>Next</button>
                </section>
            </div>
        </>
    )
}

export default HelperButtons;