import Brands from "../../manageproperties/brandstable";
import Websites from "../../manageproperties/websitestable";
import Socials from "../../manageproperties/socialstables";
import MarketingEvaluation from "./marketevaluation";
import { Divider } from "semantic-ui-react";

const OnBoardingInformation = ({ companyDash }) => {
    return (
        <div style={{paddingBottom: "4rem"}}>
                <Brands userDash={true} companyDash={true}/>
                <Divider style={{margin: "1rem 3rem 3rem 3rem"}}/>
                <Websites userDash={true} companyDash={true}/>
                <Divider style={{margin: "1rem 3rem 3rem 3rem"}}/>
                <Socials userDash={true} companyDash={true}/>
                <Divider style={{margin: "1rem 3rem 3rem 3rem"}}/>
                <MarketingEvaluation />
        </div>
    )
}

export default OnBoardingInformation;