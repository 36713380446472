import {
    FETCHING_USER,
    GETTING_USER_SUCCESS,
    GETTING_USER_FAILURE,
    DELTEING_USER,
    DELETING_USER_SUCCESS,
    DELETING_USER_FAILURE,
    CREATING_NEW_USER,
    CREATING_NEW_USER_SUCCESS,
    CREATING_NEW__FAILURE,
    UPDATING_USER,
    UPDATING_USER_SUCCESS,
    UPDATING_USER_FAILURE,
    USER_LOGGING_OUT,
} from "../actions/user-actions";

const initialState = {
    user: {},
    isLoading: false,
    error: ''
}

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_USER:
            return {
                ...state,
                isLoading: true
            }
        case GETTING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload
            }
        case GETTING_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        case DELTEING_USER:
            return {
                ...state,
                isLoading: true
            }
        case DELETING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload
            }
        case DELETING_USER_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        case USER_LOGGING_OUT:
            return {
                user: {},
                isLoading: false,
                error: ''
            }
        case UPDATING_USER:
            return {
                ...state,
                isLoading: true
            }
        case UPDATING_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload
            }
        case UPDATING_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case CREATING_NEW_USER:
            return {
                ...state,
                isLoading: true,
            }
        case CREATING_NEW_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case CREATING_NEW__FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        default:
            return state
    }
}