import { useState } from 'react'
import { Container, Typography } from "@material-ui/core";
import { Collapse } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "4rem",
        marginBottom: "4rem"
    },
    header: {
        margin: "2rem 0rem"
    },
    body: {
        margin: "2rem 0rem",
        fontSize: "20px"
    },
    readMore: {
        fontWeight: 800,
        fontSize: "1.4em",
        "&:hover": {
            textDecoration: 'underline',
            cursor: 'pointer',
          }
    }
}));

const WelcomeToDashboard = () => {

    const classes = useStyles();
    const [readMoreOpen, setReadMoreOpen] = useState(false);

    return (
        <Container className={classes.root}>
            <Typography variant="h3" className={classes.header}>Welcome to Your Dashboard!</Typography>
            <Typography variant="body1" align="left" className={classes.body}>
                This is your central location for all things related to your GrowthHive engagement. You can login anytime using your username and password.
            </Typography>
            <Typography variant="body1" align="left" className={classes.body}>
                Here you’ll find past forms that you’ve filled out, including the Marketing Evaluation form as well as the services that you’ve selected in the top navigation menu. If you need to update any of the entries on your original Marketing Evaluation form, you can edit any field by clicking “Company Information” in the main menu.
            </Typography>
            <Collapse in={readMoreOpen}>
                <Typography variant="body1" align="left" className={classes.body}>
                    If you selected services after you filled out your Marketing evaluation form, you can now fill out the details by clicking on the “Needs Attention” tab in the main menu. The “Needs Attention” tab will notify you when an item needs to be completed.
                </Typography>
                <Typography variant="body1" align="left" className={classes.body}>
                    You can also explore our other services in the main menu under the “Available Services” tab and fill out the corresponding forms to let us know what you’re looking for.
                </Typography>
                <Typography variant="body1" align="left" className={classes.body}>
                    There is also an Email Support button in the top right-hand corner if you have any questions, or need any additional information about our platform.
                </Typography>
                <Typography variant="body1" align="left" className={classes.body}>
                    We can’t wait to get started! – the GrowthHive Team
                </Typography>
            </Collapse>
            <Typography className={classes.readMore} onClick={() => setReadMoreOpen(!readMoreOpen)}>
               {readMoreOpen ? "Read Less" :  "Read More"}
            </Typography>
        </Container>
    )
}

export default WelcomeToDashboard;