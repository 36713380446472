import { useState, useEffect } from "react";
import { Label, Button, Divider, Form } from 'semantic-ui-react';
import { useForm } from "react-hook-form";

const TradePublications = ({ advertising, setAdvertising, url, formId, formName, fillOutAdvertising, history, originalText }) => {

    const [tradePublications, setTradePublications] = useState(["url"]);
    const { handleSubmit, errors, register } = useForm();

    useEffect(() => {
        if (advertising.trade_publications_complete === true) {
            history.push(`${url}/typeofadvertising`)
        }
    }, [])


    const handleAddingInput = (myI, e) => {
        e.preventDefault()
        if (myI < 5) {
            setTradePublications([...tradePublications, `name${tradePublications.length}`])
        }
    }


    const handleRemovingInput = (myI, e) => {
        e.preventDefault()
        if (tradePublications.length > 1) {
            setTradePublications(tradePublications.filter(trade => {
                if (trade !== `name${myI}`) {
                    return trade
                }
            }))
        }
    }


    const formatData = data => {
        let formattedData = []
        for (const [key, value] of Object.entries(data)) {
            formattedData.push({ name: value, companies_id: Number(originalText), "trade_advertising_forms_id": Number(formId) })
        }
        return { "trade_publications": formattedData, "isForm": true }
    }


    const handlePost = data => {
        fillOutAdvertising(formName, formatData(data))
            .then(res => {
                setAdvertising({
                    ...advertising,
                    tradePublications_complete: true
                })
                history.push(`${url}/typeofadvertising`);
            })
            .catch(err => { console.log(err) })
    }


    return (
        <div style={{ textAlign: "left", padding: "2rem" }}>
            <div style={{ fontSize: "2rem", margin: "1rem 0rem" }}><strong>Trade Publications</strong></div>
            <p>Please List up to six trade publications.</p>
            <div>
                <Form onSubmit={handleSubmit(handlePost)} style={{ display: "flex", flexDirection: "column" }}>
                    {tradePublications && tradePublications.map((trade, i) => (
                        <div key={i} style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor={trade}>Trade Pubilcation {i + 1}</label>
                            <div style={{ display: "flex", margin: ".5rem 0rem" }}>
                                <input
                                    type="text"
                                    name={trade}
                                    id={trade}
                                    defaultValue={""}
                                    style={{ width: "85%", marginRight: "1rem" }}
                                    ref={register({
                                        required: "You must provide the trade publication name",
                                        minLength: {
                                            value: 2,
                                            message: "trade publication must be at least 2 characters"
                                        },
                                    })}
                                />
                                {i === tradePublications.length - 1 &&
                                    <div>
                                        <Button icon="add" color="yellow" onClick={(e) => handleAddingInput(i, e)} />
                                        <Button icon="x" onClick={(e) => handleRemovingInput(i, e)}></Button>
                                    </div>}
                            </div>
                            {errors[trade] && <Label basic color='red' style={{ textAlign: "center", width: "85%", marginBottom: ".5rem" }} pointing>{errors[trade].message}</Label>}
                        </div>
                    ))}
                    <Divider />
                    <div style={{ marginTop: ".5rem", textAlign: "left", display: "flex", justifyContent: "flex-end" }}>
                        <Button type="submit" color="yellow">Submit</Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}


export default TradePublications;