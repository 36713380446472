import React, { useState } from 'react'
import { Button, Modal, Form, Label } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const ManageSingleTradeshow = ({ tradeshow, setTradeshows, tradeshows }) => {

  const originalTradeshowName = tradeshow.name;
  const originalTradeshowType = tradeshow.type_of_show;
  const originalTradeshowboothNumber = tradeshow.booth_number;
  const originalTradeshowDimensions = tradeshow.approximate_dimensions;
  const { handleSubmit, errors, register } = useForm();
  const [open, setOpen] = useState(false);

  const handleUpdatingTradeshow = (data) => {
    if (data.name !== originalTradeshowName || data.type_of_show !== originalTradeshowType || data.booth_number !== originalTradeshowboothNumber || data.approximate_dimensions !== originalTradeshowDimensions) {
      axiosWithAuth()
        .put(`/tradeshows/${tradeshow.id}`, data)
        .then(res => {
          setTradeshows(tradeshows.map(trade => {
            if (trade.id === res.data.tradeShowArr[0].id) {
              return res.data.tradeShowArr[0]
            } else {
              return trade
            }
          }))
          setOpen(false)
        }).catch(err => { console.log(err) })
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button onClick={e => e.preventDefault()} basic color='yellow'>Manage</Button>}
    >
      <Modal.Header>Manage Tradeshow</Modal.Header>
      <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div>
          <Form style={{ width: "700px", margin: "0 auto", display: "flex", flexDirection: "column" }}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              defaultValue={tradeshow.name}
              name="name"
              id="name"
              ref={register({
                required: "You must provide a tradeshow name",
                minLength: {
                  value: 2,
                  message: "Tradeshow name must be at least 2 characters"
                }
              })}
            />
            {errors.name && <Label basic color='red' pointing >* {errors.name.message} *</Label>}
            <label htmlFor="type_of_show" >Tradeshow Event Type</label>
            <select
              type="text"
              name="type_of_show"
              id="type_of_show"
              ref={register({
                required: "Please indicate the type of tradeshow "
              })}
              defaultValue={tradeshow.type_of_show}
            >
              <option value="In-Person">In-Person</option>
              <option value="Virtual" >Virtual</option>
            </select>
            {errors.type_of_show && <Label basic color='red' pointing >* {errors.type_of_show.message} *</Label>}
            <label htmlFor="booth_number" >Booth Number</label>
            <input
              type="text"
              name="booth_number"
              defaultValue={tradeshow.booth_number}
              id="booth_number"
              ref={register({
                required: false,
                valueAsNumber: "the value must be a number"
              })}
            />
            {errors.booth_number && <Label basic color='red' pointing >* {errors.booth_number.message} *</Label>}
            <label htmlFor="approximate_dimensions" >Approximate Booth Dimensions</label>
            <input
              type="text"
              defaultValue={tradeshow.approximate_dimensions}
              name="approximate_dimensions"
              id="approximate_dimensions"
              ref={register({
                required: false
              })}
            />
            {errors.approximate_dimensions && <Label basic color='red' pointing >* {errors.approximate_dimensions.message} *</Label>}
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" onClick={handleSubmit(handleUpdatingTradeshow)} basic color='yellow'>
          Save
        </Button>
        <Button onClick={() => setOpen(false)} basic color='red'>
          Exit
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ManageSingleTradeshow;