import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Divider, Form } from "semantic-ui-react";
import Wesbites from "../../manageproperties/websitestable";
import TradePublications from "../../manageproperties/tradepublicationstables";
import { useForm } from "react-hook-form";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth";

const Advertising = () => {

    const [advertisingForm, setAdvertisingForm] = useState();
    const { handleSubmit, register } = useForm();
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${theId}/advertisingform/`)
            .then(res => {
                setAdvertisingForm(res.data)
            }).catch(err => { console.log(err) })
    }, [theId])

    const handlePutRequest = (data) => {
        if (advertisingForm.type_of_advertsing !== data.type_of_advertsing) {
            axiosWithAuth()
                .put(`/advertising/${advertisingForm.id}`, data)
                .then(res => {
                    setAdvertisingForm(res.data[0])
                }).catch(err => console.log(err))
        }
    }

    return (
        <div>
            {advertisingForm && Object.keys(advertisingForm).length > 0 ? (
                <Form onSubmit={handleSubmit(handlePutRequest)} style={{ margin: "3rem auto", width: "400px", textAlign: "left" }}>
                    <label>Type of Service</label>
                    <select
                        defaultValue={advertisingForm.type_of_advertsing}
                        style={{ marginTop: "1rem" }}
                        name="type_of_advertsing"
                        id="type_of_advertsing"
                        ref={register({
                            required: true,
                        })}>
                        <option value={"Concierge Service"}>{"Concierge Service"}</option>
                        <option value={"A la Carte"}>{"A la Carte"}</option>
                    </select>
                    <div style={{ marginTop: "1rem", display: "flex", justifyContent: "flex-end" }}>
                        <Button color="yellow" basic>Save</Button>
                    </div>
                </Form>
            ) :
                <div style={{ padding: "5rem 0rem", textAlign: "center" }}>There isn't an Advertising form in our system</div>
            }
            <Divider style={{ margin: "3rem 6rem" }} />
            <TradePublications userDash={true} />
            <Divider style={{ margin: "3rem 6rem" }} />
            <Wesbites userDash={true} AdvertisingFormView={true}/>
        </div>
    )
}

export default Advertising;