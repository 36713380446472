import {
    FETCHING_COMPANY,
    GETTING_COMPANY_SUCCESS,
    GETTING_COMPANY_FAILURE,
    FETCHING_COMPANIES,
    GETTING_COMPANIES_SUCCESS,
    GETTING_COMPANIES_FAILURE,
    DELTEING_COMPANY,
    DELETING_COMPANY_SUCCESS,
    DELETING_COMPANY_FAILURE,
    CREATING_NEW_COMPANY,
    CREATING_NEW_COMPANY_SUCCESS,
    CREATING_NEW_COMPANY_FAILURE,
    UPDATING_COMPANY,
    UPDATING_COMPANY_SUCCESS,
    UPDATING_COMPANY_FAILURE,
    COMPANY_LOGGING_OUT,
    ADMIN_CREATING_NEW_COMPANY,
    ADMIN_CREATING_NEW_COMPANY_SUCCESS,
    ADMIN_CREATING_NEW_COMPANY_FAILURE,
    CREATING_NEW_ONBOARDING_FORM,
    CREATING_NEW_BRANDS_FORM_SUCCESS,
    CREATING_NEW_WEBISITE_FORM_SUCCESS,
    CREATING_NEW_SOCIALS_FORM_SUCCESS,
    CREATING_NEW_MARKETINGEVALUATION_FORM_SUCCESS,
    CREATING_NEW_ONBOARDING_FORM_FAILURE,
    CREATING_NEW_FORM,
    CREATING_NEW_FORM_SUCCESS,
    CREATING_NEW_FORM_FAILURE,
    SUBMITTING_MARKET_MAPPING_FORM,
    CHANNELS_SUBMITTED_SUCESSFULLY,
    MARKETS_SUBMITTED_SUCESSFULLY,
    GEOGRAPHICS_SUBMITTED_SUCESSFULLY,
    PRODUCTS_SUBMITTED_SUCESSFULLY,
    SUBMITTING_MARKET_MAPPING_FORM_FAILURE,
    SUBMITTING_AR_TOURS_FORM,
    FACILITIES_SUBMITTED_SUCESSFULLY,
    BRANDS_SUBMITTED_SUCESSFULLY,
    AR_TOUR_FORM_SUBMITTED_SUCESSFULLY,
    SUBMITTING_AR_TOURS_FAILURE,
    SUBMITTING_ADVERTISING_FORM,
    WEBSITES_SUBMITTED_SUCESSFULLY,
    TRADE_PUBLICATIONS_SUBMITTED_SUCESSFULLY,
    ADVERTISING_FORM_SUBMITTED_SUCESSFULLY,
    SUBMITTING_ADVERTISING_FAILURE,
} from "../actions/companies-actions";
import { USER_LOGGING_OUT } from '../actions/user-actions'


const initialState = {
    company: {},
    websites: [],
    socials: [],
    brands: [],
    marketing_evaluation_form: {},
    isLoading: false,
    error: '',
    companies: [],
    form: []
}


export const companiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_COMPANY:
            return {
                ...state,
                isLoading: true
            }
        case GETTING_COMPANY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                company: action.payload
            }
        case GETTING_COMPANY_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        case DELTEING_COMPANY:
            return {
                ...state,
                isLoading: true
            }
        case DELETING_COMPANY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                company: action.payload
            }
        case DELETING_COMPANY_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        case COMPANY_LOGGING_OUT:
            return {
                company: {},
                isLoading: false,
                error: ''
            }
        case UPDATING_COMPANY:
            return {
                ...state,
                isLoading: true
            }
        case UPDATING_COMPANY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                company: action.payload
            }
        case UPDATING_COMPANY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case CREATING_NEW_COMPANY:
            return {
                ...state,
                isLoading: true,
            }
        case CREATING_NEW_COMPANY_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case CREATING_NEW_COMPANY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case FETCHING_COMPANIES:
            return {
                ...state,
                isLoading: true
            }
        case GETTING_COMPANIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                companies: action.payload
            }
        case GETTING_COMPANIES_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        case ADMIN_CREATING_NEW_COMPANY:
            return {
                ...state,
                isLoading: true
            }
        case ADMIN_CREATING_NEW_COMPANY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                companies: [...state.companies, action.payload]
            }
        case ADMIN_CREATING_NEW_COMPANY_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        case CREATING_NEW_ONBOARDING_FORM:
            return {
                ...state,
                isLoading: true
            }
        case CREATING_NEW_WEBISITE_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                websites: action.payload
            }
        case CREATING_NEW_BRANDS_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                websites: action.payload
            }
        case CREATING_NEW_SOCIALS_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                websites: action.payload
            }
        case CREATING_NEW_MARKETINGEVALUATION_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                websites: action.payload
            }
        case CREATING_NEW_ONBOARDING_FORM_FAILURE:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            }
        case USER_LOGGING_OUT:
            return {
                company: {},
                isLoading: false,
                error: '',
                companies: []
            }
        case CREATING_NEW_FORM:
            return {
                ...state,
                isLoading: true,
            }
        case CREATING_NEW_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                form: action.payload
            }
        case CREATING_NEW_FORM_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SUBMITTING_MARKET_MAPPING_FORM:
            return {
                ...state,
                isLoading: true,
            }
        case CHANNELS_SUBMITTED_SUCESSFULLY:
            return {
                ...state,
                isLoading: false,
            }
        case MARKETS_SUBMITTED_SUCESSFULLY:
            return {
                ...state,
                isLoading: false
            }
        case GEOGRAPHICS_SUBMITTED_SUCESSFULLY:
            return {
                ...state,
                isLoading: false,
            }
        case PRODUCTS_SUBMITTED_SUCESSFULLY:
            return {
                ...state,
                isLoading: false
            }
        case SUBMITTING_MARKET_MAPPING_FORM_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case SUBMITTING_AR_TOURS_FORM:
            return {
                ...state,
                isLoading: true,
            }
        case FACILITIES_SUBMITTED_SUCESSFULLY:
            return {
                ...state,
                isLoading: false,
            }
        case BRANDS_SUBMITTED_SUCESSFULLY:
            return {
                ...state,
                isLoading: false
            }
        case AR_TOUR_FORM_SUBMITTED_SUCESSFULLY:
            return {
                ...state,
                isLoading: false
            }
        case SUBMITTING_AR_TOURS_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        case SUBMITTING_ADVERTISING_FORM:
            return {
                ...state,
                isLoading: true,
            }
        case WEBSITES_SUBMITTED_SUCESSFULLY:
            return {
                ...state,
                isLoading: false,
            }
        case TRADE_PUBLICATIONS_SUBMITTED_SUCESSFULLY:
            return {
                ...state,
                isLoading: false
            }
        case ADVERTISING_FORM_SUBMITTED_SUCESSFULLY:
            return {
                ...state,
                isLoading: false
            }
        case SUBMITTING_ADVERTISING_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}