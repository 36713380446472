function Ganalytics({ handleChange, register, errors }) {
    return (
        <>
            <div style={{ padding: "0% 2%", marginTop: "1rem", display: "flex", flexDirection: "column" }}>
                <label htmlFor="google_analytics" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Lastly, do you have an account with Google Analytics?</label>
                <select
                    style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="google_analytics"
                    id="google_analytics"
                    onChange={handleChange}
                    ref={register({ required: "Please indicate whether you have a Google Analytics account" 
                })}
                >
                    <option value={""} hidden>Choose One</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                {errors.google_analytics && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.google_analytics.message} *</p>}
            </div>
        </>
    );
}

export default Ganalytics;