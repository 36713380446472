import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth.js";
import CryptoJS from "crypto-js";

// get request for user/login
export const FETCHING_USER = "FETCHING_USER";
export const GETTING_USER_SUCCESS = "GETTING_USER_SUCCESS";
export const GETTING_USER_FAILURE = "GETTING_USER_FAILURE";

// delete request for user
export const DELTEING_USER = "DELETING_USER";
export const DELETING_USER_SUCCESS = "DELETING_USER_SUCCESS";
export const DELETING_USER_FAILURE = "DELETING_USER_FAILURE";

// sign up request for user
export const CREATING_NEW_USER = "CREATING_NEW_USER";
export const CREATING_NEW_USER_SUCCESS = "CREATING_NEW_USER_SUCCESS";
export const CREATING_NEW__FAILURE = "CREATING_NEW__FAILURE";

// updating information
export const UPDATING_USER = "UPDATING_USER";
export const UPDATING_USER_SUCCESS = "UPDATING_USER_SUCCESS";
export const UPDATING_USER_FAILURE = "UPDATING_USER_FAILURE";

// logout 
export const USER_LOGGING_OUT = "USER_LOGGING_OUT";

export const login = (user) => (dispatch) => {
    dispatch({ type: FETCHING_USER });

    return axiosWithAuth()
        .post("auth/employeelogin", user)
        .then(res => {
            var random = `${Math.floor(Math.random() * 100000 + 1) + res.data.user.id + Math.floor(Math.random() * 100000 + 1) % Math.floor(Math.random() * 1000 + 1)}`;
            var cipherId = CryptoJS.AES.encrypt(`${res.data.user.id}`, random).toString();
            var cipherType = CryptoJS.AES.encrypt(`${res.data.user.user_types_id}`, random).toString();

            localStorage.setItem("KTyE65sP", res.data.token);
            localStorage.setItem("JFZR5qve", cipherId);
            localStorage.setItem("SWassWQWiu", random);
            localStorage.setItem("YGVtSKYr", cipherType);

            dispatch({ type: GETTING_USER_SUCCESS, payload: res.data.user });
            return res.data;
        })
        .catch(error => {
            return error.response.data;
        });
};

export const getUserInfo = (userId) => (dispatch) => {
    dispatch({ type: FETCHING_USER });

    return axiosWithAuth()
        .get(`users/${userId}`)
        .then(res => {
            dispatch({ type: GETTING_USER_SUCCESS, payload: res.data });
        })
        .catch(err => {
            dispatch({ type: GETTING_USER_FAILURE, payload: err });
        });
};

export const creatingUser = (user) => (dispatch) => {
    dispatch({ type: CREATING_NEW_USER });

    return axiosWithAuth()
        .post(`auth/registeremployee`, user)
        .then(res => {
            dispatch({ type: CREATING_NEW_USER_SUCCESS });
            return res.data;
        })
};
