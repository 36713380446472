import { useEffect, useState, useRef } from "react";
import Breadcrumbs from "../breadcrumbs";
import { useHistory } from "react-router-dom";
import { Divider, Form, Button, Label, Modal, Icon, Header } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth";



function UserSettings() {

    const breadcrumbPath = [{ name: "Dashboard", url: "/employeedashboard" }, { "name": "Account Settings" }]
    const { handleSubmit, errors, register, watch } = useForm();
    const history = useHistory();
    const [userTypes, setUserTypes] = useState([]);
    const [user, setUser] = useState({})
    const [passwordChange, setPasswordChange] = useState(false)
    const [open, setOpen] = useState(false);
    const password = useRef({});
    password.current = watch("password", "");
    var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/users/${theId}`)
            .then(res => {
                setUser(res.data)
            }).catch(err => console.log(err))
    }, [])


    useEffect(() => {
        axiosWithAuth()
            .get(`/usertypes/`)
            .then(res => {
                setUserTypes(res.data)
            }).catch(err => { console.log(err) })
    }, [setUser])

    const handlePutRequest = data => {
        if (data["confirm_password"]) {
            delete data["confirm_password"]
        }
        if (data.first_name !== user.first_name || data.last_name !== user.last_name || data.phone_number !== user.phone_number || data.position !== user.position || (data.hubspot_owner_id && data.hubspot_owner_id !== user.hubspot_owner_id) || (data.user_types_id && data.user_types_id !== user.user_types_id) || passwordChange === true) {
            axiosWithAuth()
                .put(`/users/${user.id}`, data)
                .then(res => {
                    setUser(res.data)
                    history.push("/employeedashboard")
                }).catch(err => { console.log(err) })
        }
    }

    const handleRemovingUser = () => {
        axiosWithAuth()
            .delete(`/users/${user.id}`)
            .then(res => {
                localStorage.clear()
                history.push("/employeelogin")
            }).catch(err => { console.log(err) })
    }


    return (
        <>
            { user && Object.keys(user).length > 0 && (
                <div>
                    <div style={{ width: "90%", margin: "4rem auto 2rem auto", textAlign: "left", marginTop: "2rem" }}>
                        <Breadcrumbs path={breadcrumbPath} />
                    </div>
                    <div style={{ width: "60%", margin: "1rem auto", textAlign: "left" }}>
                        <div style={{ fontWeight: 800, fontSize: "3rem", margin: "4rem 0rem 1rem 0rem" }}>{user && user.email}</div>
                        <p>{user && user.first_name}'s Account</p>
                    </div>
                    <Divider />

                    <Form style={{ width: "60%", margin: "1rem auto", textAlign: "left", display: "flex", flexDirection: "column", padding: "1rem 0rem 5rem 0rem" }}>
                        <label htmlFor="first_name">First Name</label>
                        <input
                            style={{ width: "500px", margin: ".5rem 0rem" }}
                            defaultValue={user.first_name}
                            name="first_name"
                            id="first_name"
                            ref={register({
                                required: "You must provide a first name",
                                minLength: {
                                    value: 2,
                                    message: "First name must be at least 2 characters"
                                }
                            })}
                        />
                        {errors.first_name && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.first_name.message}</Label>}
                        <label htmlFor="last_name">Last Name</label>
                        <input
                            style={{ width: "500px", margin: ".5rem 0rem" }}
                            defaultValue={user.last_name}
                            name="last_name"
                            id="last_name"
                            ref={register({
                                required: "You must provide a last name",
                                minLength: {
                                    value: 2,
                                    message: "Last name must be at least 2 characters"
                                }
                            })}
                        />
                        {errors.last_name && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.last_name.message}</Label>}
                        <label htmlFor="phone_number">Phone Number</label>
                        <input
                            style={{ width: "500px", margin: ".5rem 0rem" }}
                            defaultValue={user.phone_number}
                            name="phone_number"
                            id="phone_number"
                            ref={register({
                                required: "You must enter a valid phone number",
                                pattern: {
                                    value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                                    message: "Please enter a valid phone number"
                                }
                            })}
                        />
                        {errors.phone_number && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.phone_number.message} </Label>}
                        <label htmlFor="position">Position</label>
                        <input
                            style={{ width: "500px", margin: ".5rem 0rem" }}
                            defaultValue={user.position}
                            name="position"
                            id="position"
                            ref={register({
                                required: "You must enter a provide the user's current position",
                            })}
                        />
                        {errors.position && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.position.message}</Label>}
                        {user.user_types_id === 2 || user.user_types_id === 3 && (
                            <>
                                <label htmlFor="hubspot_owner_id">Hubspot Owner Id</label>
                                <input
                                    style={{ width: "500px", margin: ".5rem 0rem" }}
                                    defaultValue={user.hubspot_owner_id}
                                    name="hubspot_owner_id"
                                    id="hubspot_owner_id"
                                    ref={register({
                                        required: "You must enter a valid 8 digit HubSpot owner ID",
                                        valueAsNumber: true,
                                        minLength: {
                                            value: 8,
                                            message: "Your password must be an 8 digit number",
                                        },
                                        maxLength: {
                                            value: 8,
                                            message: "Your password must be an 8 digit number",
                                        }
                                    })}
                                />
                                {errors.hubspot_owner_id && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.hubspot_owner_id.message}</Label>}
                            </>
                        )}
                        {passwordChange === true ? (
                            <>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                                        <label htmlFor="password" >Password</label>
                                        <input
                                            type="password"
                                            style={{ width: "500px", margin: ".5rem 0rem" }}
                                            defaultValue=""
                                            placeholder="New password"
                                            name="password"
                                            id="password"
                                            ref={register({
                                                required: "You must enter a password",
                                                minLength: {
                                                    value: 8,
                                                    message: "Your password must have at least 8 characters",
                                                },
                                            })}
                                        />
                                    </div>
                                    <p style={{ marginLeft: "1rem", marginTop: "1rem", cursor: "pointer" }} onClick={() => setPasswordChange(!passwordChange)}>Cancel</p>
                                </div>
                                {errors.password && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.password.message}</Label>}
                                <label htmlFor="confirm_password" >Confirm Password</label>
                                <input
                                    type="password"
                                    style={{ width: "500px", margin: ".5rem 0rem" }}
                                    defaultValue={""}
                                    placeholder="Confirm password"
                                    name="confirm_password"
                                    id="confirm_password"
                                    ref={register({
                                        validate: (value) =>
                                            value === password.current || "Your passwords do not match",
                                    })}
                                />
                                {errors.confirm_password && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.confirm_password.message}</Label>}
                            </>) :
                            (<div onClick={() => setPasswordChange(!passwordChange)} style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                                    <label htmlFor="password" >Password</label>
                                    <input
                                        type="password"
                                        style={{ width: "500px", margin: ".5rem 0rem" }}
                                        defaultValue=""
                                        placeholder="New password"
                                    />
                                </div>
                                <p style={{ marginLeft: "1rem", marginTop: "1rem", cursor: "pointer" }} >Change Password</p>
                            </div>)
                        }
                        {(user.user_types_id === 2 || user.user_types_id === 3) && userTypes && userTypes.length > 0 && (
                            <>
                                <label htmlFor="user_types_id">User Type</label>
                                <select
                                    style={{ width: "500px", margin: ".5rem 0rem" }}
                                    defaultValue={user.user_types_id}
                                    name="user_types_id"
                                    id="user_types_id"
                                    ref={register({
                                        required: "You must specify user premissions", valueAsNumber: true
                                    })}
                                >
                                    {userTypes.map(type => (
                                        <option key={type.id} value={type.id}>{type.name.charAt(0).toUpperCase() + type.name.slice(1)}</option>
                                    ))}
                                </select>
                                {errors.user_types_id && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.user_types_id.message}</Label>}
                            </>
                        )}
                        <div style={{ marginTop: "2rem" }}>
                            <Button color="yellow" type="submit" basic onClick={handleSubmit(handlePutRequest)}>Update Account</Button>
                            {(user.user_types_id === 2 || user.user_types_id === 3) && (
                                <Modal
                                    basic
                                    onClose={() => setOpen(false)}
                                    onOpen={() => { setOpen(true) }}
                                    open={open}
                                    size='small'
                                    trigger={<Button color="red" basic >Delete Account</Button>}
                                >
                                    <Header icon><Icon name='trash' />Removing Company</Header>
                                    <Modal.Content>
                                        <p style={{ fontWeight: 700, textAlign: "center" }}>Are you sure you want to delete your Account?</p>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='red' inverted onClick={() => setOpen(false)}>
                                            <Icon name='remove' /> No
                                        </Button>
                                        <Button color='green' inverted onClick={() => {
                                            handleRemovingUser()
                                            setOpen(false)
                                        }}>
                                            <Icon name='checkmark' /> Yes
                                        </Button>
                                    </Modal.Actions>
                                </Modal>
                            )}
                        </div>
                    </Form>
                </div>
            )}
        </>
    );
}

export default UserSettings;