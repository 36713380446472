function Webinars({ register, errors, marketingEvaluationForm }) {
    return (
        <>
            <div style={{ padding: "0% 2%", marginTop: "1rem", display: "flex", flexDirection: "column" }}>
                <label htmlFor="webinars" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Are you relying on webinars to generate leads?</label>
                <select
                    style={{ width: "15rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    name="webinars"
                    id="webinars"
                    defaultValue={marketingEvaluationForm.webinars}
                    ref={register({ required: "Please indicate whether you are relying on webinars to generate leads" })}
                >
                    <option value={""} hidden>Choose One</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                </select>
                {errors.webinars && <p style={{ color: "red", margin: ".4rem 0rem" }}>* {errors.webinars.message} *</p>}
            </div>
            <div style={{ marginTop: "1rem", paddingLeft: "2%", display: "flex", flexDirection: "column" }}>
                <label htmlFor="webinar_links" style={{ fontSize: "1.1rem", marginRight: "2rem", fontWeight: 600 }}>Please provide a link to a recent event.</label>
                <input
                    type="text"
                    name="webinar_links"
                    id="webinar_links"
                    defaultValue={marketingEvaluationForm.webinar_links}
                    style={{ width: "30rem", height: "1.75rem", fontSize: "1.1rem", marginTop: "1rem" }}
                    ref={register({
                        required: false,
                        maxLength: 200,
                        pattern: {
                            value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
                            message: "Please provide a valid url"
                        }
                    })}
                />
            </div>
        </>
    );
}

export default Webinars;