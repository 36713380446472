import { useEffect, useState, useRef } from "react";
import { Divider, Form, Button, Label, Modal, Header, Icon } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth.js";
import Breadcrumbs from "../breadcrumbs";
import CryptoJS from "crypto-js";


function Settings() {

  const { handleSubmit, errors, register, watch } = useForm();
  const [passwordChange, setPasswordChange] = useState(false);
  const [open, setOpen] = useState(false);
  const [company, setCompany] = useState({})
  const history = useHistory();
  const password = useRef({});
  password.current = watch("password", "");
  const breadcrumbPath = [{ name: "Dashboard", url: `/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}` }, { "name": "Account Settings" }];
  var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
  var theId = byteId.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    axiosWithAuth()
      .get(`/companies/${theId}`)
      .then(res => {
        setCompany(res.data)
      }).catch(err => console.log(err))
  }, [])

  const handlePutRequest = data => {
    if (data["confirm_password"]) {
      delete data["confirm_password"]
    }
    if (data.name !== company.name || data.first_name !== company.first_name || data.last_name !== company.last_name || data.phone_number !== company.phone_number || passwordChange === true) {
      axiosWithAuth()
        .put(`/companies/${company.id}`, data)
        .then(res => {
          setCompany(res.data)
          history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
        }).catch(err => { console.log(err) })
    }
  }

  const handleRemovingCompany = () => {
    axiosWithAuth()
      .delete(`/companies/${company.id}`)
      .then(res => {
        localStorage.clear()
        history.push("/")
      }).catch(err => { console.log(err) })
  }


  return (
    <div>
      <div style={{ width: "90%", margin: "4rem auto 2rem auto", textAlign: "left", marginTop: "2rem" }}>
        <Breadcrumbs path={breadcrumbPath} />
      </div>
      {company && Object.keys(company).length > 0 && (
        <>
          <div style={{ width: "850px", margin: "1rem auto", textAlign: "left" }}>
            <div style={{ fontWeight: 800, fontSize: "3rem", margin: "4rem 0rem 1rem 0rem" }}>{company && company.email}</div>
            <p>{company && company.name}'s Account Information</p>
          </div>
          <Divider />
          <Form style={{ width: "850px", margin: "1rem auto", textAlign: "left", display: "flex", flexDirection: "column", }}>
            <label htmlFor="name" >Comapny Name</label>
            <input
              style={{ width: "500px", margin: ".5rem 0rem" }}
              defaultValue={company.name}
              name="name"
              id="name"
              ref={register({
                required: "You must provide a company name",
                minLength: {
                  value: 2,
                  message: "Company name must be at least 2 characters"
                }
              })}

            />
            {errors.name && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.name.message} </Label>}
            <label htmlFor="first_name" >First Name</label>
            <input
              style={{ width: "500px", margin: ".5rem 0rem" }}
              defaultValue={company.first_name}
              name="first_name"
              id="first_name"
              ref={register({
                required: "You must provide a first name",
                minLength: {
                  value: 2,
                  message: "First name must be at least 2 characters"
                }
              })}
            />
            {errors.first_name && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.first_name.message}</Label>}
            <label htmlFor="last_name" >Last Name</label>
            <input
              style={{ width: "500px", margin: ".5rem 0rem" }}
              defaultValue={company.last_name}
              name="last_name"
              id="last_name"
              ref={register({
                required: "You must provide a last name",
                minLength: {
                  value: 2,
                  message: "Last name must be at least 2 characters"
                }
              })}
            />
            {errors.last_name && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.last_name.message}</Label>}
            <label htmlFor="phone_number" >Phone Number</label>
            <input
              style={{ width: "500px", margin: ".5rem 0rem" }}
              defaultValue={company.phone_number}
              name="phone_number"
              id="phone_number"
              ref={register({
                required: "You must enter an email",
                pattern: {
                  value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                  message: "Please enter a valid phone number"
                }
              })}
            />
            {errors.phone_number && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.phone_number.message}</Label>}
            {passwordChange === true ? (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                    <label htmlFor="password" >Password</label>
                    <input
                      type="password"
                      style={{ width: "100%", margin: ".5rem 0rem" }}
                      defaultValue=""
                      placeholder="New password"
                      name="password"
                      id="password"
                      ref={register({
                        required: "You must enter a password",
                        minLength: {
                          value: 8,
                          message: "Your password must have at least 8 characters",
                        },
                      })}
                    />
                  </div>
                  <p style={{ marginLeft: "1rem", marginTop: "1rem", cursor: "pointer" }} onClick={() => setPasswordChange(!passwordChange)}>Cancel</p>
                </div>
                {errors.password && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.password.message}</Label>}
                <label htmlFor="confirm_password" >Confirm Password</label>
                <input
                  type="password"
                  style={{ width: "500px", margin: ".5rem 0rem" }}
                  defaultValue={""}
                  placeholder="Confirm password"
                  name="confirm_password"
                  id="confirm_password"
                  ref={register({
                    validate: (value) =>
                      value === password.current || "Your passwords do not match",
                  })}
                />
                {errors.confirm_password && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.confirm_password.message}</Label>}
              </>) :
              (<div onClick={() => setPasswordChange(!passwordChange)} style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                  <label htmlFor="password" >Password</label>
                  <input
                    type="password"
                    style={{ width: "500px", margin: ".5rem 0rem" }}
                    defaultValue=""
                    placeholder="New password"
                  />
                </div>
                <p style={{ marginLeft: "1rem", marginTop: "1rem", cursor: "pointer" }} >Change Password</p>
              </div>)
            }
            <div style={{ marginTop: "2rem" }}>
              <Button type="submit" color="yellow" basic onClick={handleSubmit(handlePutRequest)}>Update Account</Button>
              <Modal
                basic
                onClose={() => setOpen(false)}
                onOpen={() => { setOpen(true) }}
                open={open}
                size='small'
                trigger={<Button color="red" basic >Delete Account</Button>}
              >
                <Header icon><Icon name='trash' />Removing Company</Header>
                <Modal.Content>
                  <p style={{ fontWeight: 700, textAlign: "center" }}>Are you sure you want to delete company Account?</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button basic color='red' inverted onClick={() => setOpen(false)}>
                    <Icon name='remove' /> No
                        </Button>
                  <Button color='green' inverted onClick={() => {
                    handleRemovingCompany()
                    setOpen(false)
                  }}>
                    <Icon name='checkmark' /> Yes
                        </Button>
                </Modal.Actions>
              </Modal>
            </div>
          </Form>
        </>
      )}
    </div>
  );
}


export default Settings;