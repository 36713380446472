import Tradeshow from "../../manageproperties/tradeshowstable";


const TradeEvents = () => {

    return (
        <>
            <Tradeshow userDash={true}/>
        </>
    )
}

export default TradeEvents;