import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { grey } from "@material-ui/core/colors";
import Paper from '@material-ui/core/Paper';
import '@fontsource/roboto';
import '../../App.css'
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth.js";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 32,
  },
  subHeading: {
    fontWeight: 700,
    fontSize: 18,
  },
  paper: {
    backgroundColor: grey[100],
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  ryGrid: {
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formTop: {
    justifyContent: "center",
  }
})
)

function PasswordForm({ isInternalUser }) {
  const classes = useStyles();
  const history = useHistory();
  const { handleSubmit, errors, register, watch, control, rules } = useForm();
  const [errorOccured, setErrorOccured] = useState("")
  const password = useRef({});
  password.current = watch("password", "");

  async function handlePasswordChange(data){
    const url = window.location.href;
    var passwordEndpoint = isInternalUser ? "passwordresetinternaluser" : "passwordreset";
    var redirectURL = isInternalUser ? "/employeelogin" : "/login";
    const token = url.replace(`${process.env.REACT_APP_BASE_URL || "http://localhost:3000/"}${passwordEndpoint}/`, "")
    delete data['confirm_password'];
    console.log(process.env.REACT_APP_BASE_URL, "react app base url")
    console.log(token, "this is my token")
    console.log(url.replace(`${process.env.REACT_APP_BASE_URL || "http://localhost:3000/"}${passwordEndpoint}/`, ""), "this is returned")
    debugger;
    return axiosWithAuth()
        .post(`/auth/${passwordEndpoint}?token=${token}`, data)
        .then(res => {
            alert(`Your password has been reset!`);
            history.push(redirectURL);
        }).catch(error => error.response.data); 
  }

  return (
    <Container maxWidth="sm">
      <form class="login" onSubmit={handleSubmit(handlePasswordChange)}>
        <Paper className={classes.paper}>
          <Grid container className={classes.formTop} spacing={2}>
            <Grid item xs={12}>
              <Typography class="signup" variant="h5" component="h2"> GrowthHive Portal Password Reset </Typography>
            </Grid>
            <Grid container className={classes.ryGrid} spacing={2}>
              <Grid item xs={3}>
              </Grid>
                <Grid item xs={10}>
                  <Controller
                    render={(props) => <TextField {...props} required className={classes.TextField} variant="outlined" fullWidth type="password" label="Password" />}
                    control={control}
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    rules={{
                      required: "You must enter a password",
                      minLength: {
                        value: 8,
                        message: "Your password must have at least 8 characters",
                      },
                    }}
                  />
                  {errors.password && <p style={{ color: "red", margin: "0rem" }}>* {errors.password.message} *</p>}
                </Grid>
                <Grid item xs={10}>
                  <Controller
                    render={(props) => <TextField {...props} required variant="outlined" className={classes.TextField} fullWidth type="password" label="Confirm Password" />}
                    control={control}
                    fullWidth
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    defaultValue={""}
                    rules={{
                      validate: (value) =>
                        value === password.current || "Your passwords do not match",
                    }}
                  />
                  {errors.confirm_password && <p style={{ color: "red", margin: "0rem" }}>* {errors.confirm_password.message} *</p>}
                </Grid>
            </Grid>
          
            {errorOccured ? <Grid item xs={12}><p style={{ color: "red", marginBottom: "1rem" }}>* {errorOccured} *</p></Grid> : ""}
            <Grid item xs={3}> <Button style={{ marginBottom: '15px'}} type="submit" fullWidth variant="contained" id="submit" className={classes.submit}>Reset</Button></Grid>
           
          </Grid>
        </Paper>
        </form>
      
    </Container>
  )
}

export default PasswordForm;