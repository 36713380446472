import { useEffect, useState } from "react";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth";
import OnBoardingInformation from "../../companyDetails/onboardinginfo";
import BrandPositioning from "../../companyDetails/brandpositioning";
import Advertising from "../../companyDetails/advertising";
import MarketMapping from "../../companyDetails/marketmapping";
import TradeEvents from "../../companyDetails/tradeevents";
import ServiceCard from "../serviceCard";
import InterestCard from "../interestedCard";
import ArTours from "../../companyDetails/artours";
import Home from "../home"
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import InfoIcon from '@material-ui/icons/Info';
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import CustomAppBar from "./customAppBar";

export default function Panels({ handleMakingCompanyInterested, extraServices, needInfoServices, handleInterestingCardAndFormPush, formCreator, currentServices }) {

    const [value, setValue] = React.useState(0);
    const { id } = useParams();


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {extraServices && needInfoServices && (
                <div>
                    <CustomAppBar handleChange={handleChange} value={value} needInfoServices={needInfoServices} />
                    <TabPanel value={value} index={0}>
                        <Home currentServices={currentServices} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Typography variant="h3" style={{ marginTop: "2rem" }}>Incomplete Forms</Typography>
                        {needInfoServices.map(service => (
                            <InterestCard
                                key={service.id}
                                companiesServiceID={service.id}
                                name={service.name}
                                description={service.description}
                                headline={service.headline}
                                image={service.image}
                                serviceID={service.services_id}
                                companyID={service.companies_id}
                                formCreator={formCreator}
                            />
                        ))}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <OnBoardingInformation companyDash={true} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <BrandPositioning />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <Advertising />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <MarketMapping />
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        <TradeEvents />
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                        <ArTours />
                    </TabPanel>
                    <TabPanel value={value} index={8}>
                        <Typography variant="h3" style={{ marginTop: "2rem" }} >GrowthHive Services</Typography>
                        {extraServices.map(service => (
                            <ServiceCard
                                key={service.id}
                                name={service.name}
                                companiesServiceID={service.id}
                                serviceID={service.services_id}
                                description={service.description}
                                headline={service.headline}
                                image={service.image}
                                handleMakingCompanyInterested={handleMakingCompanyInterested}
                                handleInterestingCardAndFormPush={handleInterestingCardAndFormPush}
                            />
                        ))}
                    </TabPanel>
                </div>
            )}
        </>
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};