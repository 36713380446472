import React, { useState } from 'react'
import { Button, Modal, Form, Label, Menu } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const AddSocial = ({ setSocials, originalText }) => {

    const { handleSubmit, errors, register } = useForm();
    const [open, setOpen] = useState(false);
    const media = ["linkedin", "facebook", "instagram", "twitter", "youtube", "reddit", "pinterest", "quora", "other"];

    function capitalizeFirstLetter(string) {
        if (string === "linkedin") {
            const newLinked = string.charAt(0).toUpperCase() + string.slice(1)
            return newLinked.slice(0, 6) + newLinked.charAt(6).toUpperCase() + string.slice(7)
        } else {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }

    const handleAddingBrand = (data) => {
        axiosWithAuth()
            .post(`/socials`, { socials: [{ ...data, companies_id: Number(originalText) }] })
            .then(res => {
                setSocials(res.data)
                setOpen(false)
            }).catch(err => { console.log(err) })
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={<Menu.Item>Add Social</Menu.Item>}
        >
            <Modal.Header>Add Social</Modal.Header>
            <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div>
                    <Form style={{ width: "700px", margin: "0 auto", display: "flex", flexDirection: "column" }}>
                        <label htmlFor={"name"}>Platform</label>
                        <select
                            style={{ width: "100%" }}
                            name={"name"}
                            id={"name"}
                            defaultValue={""}
                            ref={register({ required: "Required field" })}
                        >
                            <option hidden value={""}>Choose Option</option>
                            {media && media.map(media => (<option key={media} value={media}>{capitalizeFirstLetter(media)}</option>))}
                        </select>
                        {errors.name && <p style={{ color: "red", margin: ".2rem 0rem" }}>* {errors.name.message} *</p>}
                        <label htmlFor="url" style={{ marginTop: "1rem" }}>URL</label>
                        <input
                            type="text"
                            defaultValue={""}
                            name="url"
                            id="url"
                            ref={register({
                                required: "Required field",
                                pattern: {
                                    value: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
                                    message: "Please provide a valid url"
                                }
                            })
                            }
                        />
                        {errors.name && <Label basic color='red' pointing >* {errors.name.message} *</Label>}
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button type="submit" onClick={handleSubmit(handleAddingBrand)} basic color='yellow'>
                    Save
        </Button>
                <Button onClick={() => setOpen(false)} basic color='red'>
                    Exit
        </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default AddSocial;