import { Table, Button, Menu, Header } from 'semantic-ui-react';
import { useEffect, useState } from "react";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js";
import { useParams } from "react-router-dom";
import ManageSingleProduct from "./managesingleproduct";
import AddProduct from "./addproduct";
import CryptoJS from "crypto-js";


const ProductsTable = ({ userDash }) => {

    const [products, setProducts] = useState([])
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var companyID = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${companyID}/productsservices`)
            .then(res => {
                setProducts(res.data)
            }).catch(err => console.log(err))
    }, [])


    const handleRemovingProduct = (productId) => {
        axiosWithAuth()
            .delete(`/products/${productId}`)
            .then(res => {
                setProducts(products.filter(prod => {
                    if (prod.id !== productId) {
                        return prod
                    }
                }))
            }).catch(err => console.log(err))
    }

    return (
        <>
            {products && products.length > 0 ? (
                <>
                    {userDash === true && <Header style={{ textAlign: "left", width: "1100px", margin: "2rem auto -2rem auto" }}>Products</Header>}
                    <Table celled style={{ width: "1100px", margin: "3rem auto" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Percentage</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {products.map(product => (
                                <Table.Row key={product.id}>
                                    <Table.Cell style={{ width: "50%" }}>{product.name}</Table.Cell>
                                    <Table.Cell style={{ width: "20%" }}>{product.percentage}%</Table.Cell>
                                    <Table.Cell style={{ width: "30%", textAlign: "center" }}><ManageSingleProduct product={product} products={products} setProducts={setProducts} /><Button color="red" basic onClick={() => handleRemovingProduct(product.id)}>Delete</Button></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='3'>
                                    <Menu floated='right'>
                                        <AddProduct setProducts={setProducts} companyID={companyID} />
                                    </Menu>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </>)
                :
                <div style={{ padding: "5rem 0rem", textAlign: "center" }}>There are no product or services in our system</div>
            }
        </>
    )
}



export default ProductsTable;