import { Form, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";


function LoginForm({ handleLogin, user, handleChange, loginError }) {
    return (
        <div style={{ width: "700px", marginLeft: "1.5rem", marginTop: "3rem", display: "flex", flexDirection: "column", alignItems: "space-evenly", backgroundColor: "rgb(250,250,250)", boxShadow: "6px 7px 3px 1px #C6C6C6", height: "300px" }}>
            <div style={{ backgroundColor: "rgb(255,191,77)", width: "100%", textAlign: "center" }}>
                <div style={{ fontSize: "2rem", padding: "1rem", color: "rgb(51,51,51)" }}>GrowthHive Employee Login</div>
            </div>
            {/* Forms will be put in here, we are going to use react forms*/}
            <section style={{ height: "85%", display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center", marginTop: ".5rem" }}>
                <Form style={{ display: "flex", flexDirection: "column", width: "80%", height: "100%", justifyContent: "space-evenly" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <label style={{ fontWeight: 700, fontSize: "18px" }} htmlFor="email">Email <span style={{ color: "red" }}>*</span></label>
                        <input style={{ width: "75%" }} type="text" name="email" id="email" value={user.email} onChange={handleChange} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <label style={{ fontWeight: 700, fontSize: "18px" }} htmlFor="password">Password <span style={{ color: "red" }}>*</span></label>
                        <input style={{ width: "75%" }} type="password" name="password" id="password" value={user.password} onChange={handleChange} />
                    </div>
                </Form>
                {loginError && <Label basic color='red' style={{ textAlign: "center", margin: "0rem 0rem 1.5rem 0rem", width: "400px" }} pointing>{loginError}, Please try again!</Label>}
                <div style={{ display: "flex", justifyContent: "space-between", width: "80%", paddingBottom: "2rem" }}>
                    <Link to="/resetinternaluser" variant="body2" >
                        Forgot your password? Click here to reset!
                    </Link>
                    <button style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer" }} onClick={handleLogin}>Login</button>
                </div>
            </section>
        </div>
    );
}

export default LoginForm;