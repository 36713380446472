import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header, Button, Form, Label } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth";


function BrandPositioning() {
    const [brandpositioningForm, setBrandPositioningForm] = useState({});
    const { handleSubmit, errors, register } = useForm();
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${theId}/brandpositoningform`)
            .then(res => {
                setBrandPositioningForm(res.data[0])
            }).catch(err => { })
    }, [theId])


    const handlePostRequest = (data) => {
        axiosWithAuth()
            .put(`/brandpositioning/${brandpositioningForm.id}`, data)
            .then(res => {
                setBrandPositioningForm(res.data)
            }).catch(err => {
                console.log(err)
            })

    }

    return (
        <Form onSubmit={handleSubmit(handlePostRequest)} style={{ margin: "2rem auto", width: "60%" }}>
            {brandpositioningForm && Object.keys(brandpositioningForm).length > 0 ? (
                <>
                    <div >
                        <Header style={{ textAlign: "left" }}>1. What is your Vision as a company?</Header>
                        <textarea
                            style={{ minHeight: 125, width: "100%" }}
                            id="company_vision"
                            name="company_vision"
                            defaultValue={brandpositioningForm.company_vision}
                            ref={register({
                                required: "You must provide your company vision",
                                minLength: {
                                    value: 20,
                                    message: "Company vision must be at least 20 words long"
                                }
                            })}
                        />
                        {errors.company_vision && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.company_vision.message} </Label>}
                    </div>
                    <div >
                        <Header style={{ textAlign: "left", marginTop: "1rem", marginTop: "1rem" }}>2. What is your Corporate Mission Statement?</Header>
                        <textarea
                            style={{ minHeight: 125, width: "100%" }}
                            id="mission_statement"
                            name="mission_statement"
                            defaultValue={brandpositioningForm.mission_statement}
                            ref={register({
                                required: "You must provide your company mission statement",
                                minLength: {
                                    value: 30,
                                    message: "Company mission statement must be at least 30 words long"
                                }
                            })}
                        />
                        {errors.mission_statement && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.mission_statement.message} </Label>}
                    </div>
                    <div >
                        <Header style={{ textAlign: "left", marginTop: "1rem" }}>3. What are your Core Values?</Header>
                        <textarea
                            style={{ minHeight: 125, width: "100%" }}
                            id="core_values"
                            name="core_values"
                            defaultValue={brandpositioningForm.core_values}
                            ref={register({
                                required: "You must provide your companies core values",
                            })}
                        />
                        {errors.core_values && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.core_values.message} </Label>}
                    </div>
                    <div >
                        <Header style={{ textAlign: "left", marginTop: "1rem" }}>4. What are your top 3-5 Organizational Goals?</Header>
                        <textarea
                            style={{ minHeight: 125, width: "100%" }}
                            id="organizational_goals"
                            name="organizational_goals"
                            defaultValue={brandpositioningForm.organizational_goals}
                            ref={register({
                                required: "You must provide your companies organizational goals",
                            })}
                        />
                        {errors.organizational_goals && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.organizational_goals.message} </Label>}
                    </div>
                    <div >
                        <Header style={{ textAlign: "left", marginTop: "1rem" }}>5. What are the 10-15 most important Passions of your organization?</Header>
                        <textarea
                            style={{ minHeight: 125, width: "100%" }}
                            id="passions"
                            name="passions"
                            defaultValue={brandpositioningForm.passions}
                            ref={register({
                                required: "You must provide the passions of your company",
                            })}
                        />
                        {errors.passions && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.passions.message} </Label>}
                    </div>
                    <div >
                        <Header style={{ textAlign: "left", marginTop: "1rem" }}>6. What are your most important Assets besides your physical plant, if applicable?</Header>
                        <textarea
                            style={{ minHeight: 125, width: "100%" }}
                            id="assets"
                            name="assets"
                            defaultValue={brandpositioningForm.assets}
                            ref={register({
                                required: "You must provide your company us with your company assets",
                            })}
                        />
                        {errors.assets && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.assets.message} </Label>}
                    </div>
                    <div >
                        <Header style={{ textAlign: "left", marginTop: "1rem" }}>7. What are the most important Features of your business, and of the products or services you offer?</Header>
                        <textarea
                            style={{ minHeight: 125, width: "100%" }}
                            id="business_features"
                            name="business_features"
                            defaultValue={brandpositioningForm.business_features}
                            ref={register({
                                required: "You must provide the business features of your company",
                            })}
                        />
                        {errors.business_features && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.business_features.message} </Label>}
                    </div>
                    <div >
                        <Header style={{ textAlign: "left", marginTop: "1rem" }}>8. What are your Points of Differentiation?</Header>
                        <textarea
                            style={{ minHeight: 125, width: "100%" }}
                            id="differentiation_points"
                            name="differentiation_points"
                            defaultValue={brandpositioningForm.differentiation_points}
                            ref={register({
                                required: "You must provide your company us with your company differentiation points",
                            })}
                        />
                        {errors.differentiation_points && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.differentiation_points.message} </Label>}
                    </div>
                    <div >
                        <Header style={{ textAlign: "left", marginTop: "1rem" }}>9. What are your Validation Points?</Header>
                        <textarea
                            style={{ minHeight: 125, width: "100%" }}
                            id="validation_points"
                            name="validation_points"
                            defaultValue={brandpositioningForm.validation_points}
                            ref={register({
                                required: "You must provide the validation_points of your company",
                            })}
                        />
                        {errors.validation_points && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.validation_points.message} </Label>}
                    </div>
                    <div >
                        <Header style={{ textAlign: "left", marginTop: "1rem" }}>10. What is the Positioning Statement for each brand you manufacture or carry?</Header>
                        <textarea
                            style={{ minHeight: 125, width: "100%" }}
                            id="positioning_statements"
                            name="positioning_statements"
                            defaultValue={brandpositioningForm.positioning_statements}
                            ref={register({
                                required: "You must provide your company us with your company positioning_statements",
                            })}
                        />
                        {errors.positioning_statements && <Label basic color='red' style={{ textAlign: "center" }} pointing>{errors.positioning_statements.message} </Label>}
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button type="submit" color="yellow" basic style={{ marginTop: "2rem" }}>Save</Button>
                    </div>
                </>

            ) :
                <div style={{ padding: "15rem 0rem", textAlign: "center" }}>There hasn't been a form started or completed yet</div>}
        </Form >
    )
}

export default BrandPositioning;