import React, { useState, useEffect, useRef } from 'react'
import { Button, Modal, Form, Label } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const ManageSingleUser = ({ user, personUsing, setUsers, users }) => {

  const [open, setOpen] = React.useState(false);
  const { handleSubmit, errors, register, watch } = useForm();
  const [userTypes, setUserTypes] = useState([]);
  const [passwordChange, setPasswordChange] = useState(false);
  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    axiosWithAuth()
      .get(`/usertypes/`)
      .then(res => {
        setUserTypes(res.data)
      }).catch(err => { console.log(err) })
  }, []);

  const handlePutRequest = data => {
    if (data["confirm_password"]) {
      delete data["confirm_password"]
    }
    if (data.first_name !== user.first_name || data.last_name !== user.last_name || data.phone_number !== user.phone_number || data.position !== user.position || (data.hubspot_owner_id && data.hubspot_owner_id !== user.hubspot_owner_id) || (data.user_types_id && data.user_types_id !== user.user_types_id) || passwordChange === true) {
      axiosWithAuth()
        .put(`/users/${user.id}`, data)
        .then(res => {
          setUsers(users.map(us => {
            if (us.id !== res.data[0].id) {
              return us
            } else {
              return res.data[0]
            }
          }))
          setOpen(false)
        }).catch(err => { console.log(err) })
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={<Button basic color='yellow'>Manage</Button>}
    >
      <Modal.Header>{user.first_name}'s Account Settings</Modal.Header>
      <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div>
          <Form style={{ margin: "1rem auto", textAlign: "left", display: "flex", flexDirection: "column", }}>
            <label htmlFor="first_name">First Name</label>
            <input
              style={{ width: "500px", margin: ".5rem 0rem" }}
              defaultValue={user.first_name}
              name="first_name"
              id="first_name"
              ref={register({
                required: "You must provide a first name",
                minLength: {
                  value: 2,
                  message: "First name must be at least 2 characters"
                }
              })}
            />
            {errors.first_name && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.first_name.message}</Label>}
            <label htmlFor="last_name">Last Name</label>
            <input
              style={{ width: "500px", margin: ".5rem 0rem" }}
              defaultValue={user.last_name}
              name="last_name"
              id="last_name"
              ref={register({
                required: "You must provide a last name",
                minLength: {
                  value: 2,
                  message: "Last name must be at least 2 characters"
                }
              })}
            />
            {errors.last_name && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.last_name.message}</Label>}
            <label htmlFor="phone_number">Phone Number</label>
            <input
              style={{ width: "500px", margin: ".5rem 0rem" }}
              defaultValue={user.phone_number}
              name="phone_number"
              id="phone_number"
              ref={register({
                required: "You must enter a valid phone number",
                pattern: {
                  value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                  message: "Please enter a valid phone number"
                }
              })}
            />
            {errors.phone_number && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.phone_number.message} </Label>}
            <label htmlFor="position">Position</label>
            <input
              style={{ width: "500px", margin: ".5rem 0rem" }}
              defaultValue={user.position}
              name="position"
              id="position"
              ref={register({
                required: "You must enter a provide the user's current position",
              })}
            />
            {errors.position && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.position.message}</Label>}
            {(personUsing.user_types_id === 2 || personUsing.user_types_id === 3) && (
              <>
                <label htmlFor="hubspot_owner_id">Hubspot Owner Id</label>
                <input
                  style={{ width: "500px", margin: ".5rem 0rem" }}
                  defaultValue={user.hubspot_owner_id}
                  name="hubspot_owner_id"
                  id="hubspot_owner_id"
                  ref={register({
                    required: "You must enter a valid 8 digit HubSpot owner ID",
                    valueAsNumber: true,
                    minLength: {
                      value: 8,
                      message: "Your password must be an 8 digit number",
                    },
                    maxLength: {
                      value: 8,
                      message: "Your password must be an 8 digit number",
                    }
                  })}
                />
                {errors.hubspot_owner_id && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.hubspot_owner_id.message}</Label>}
              </>
            )}
            {passwordChange === true ? (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                    <label htmlFor="password" >Password</label>
                    <input
                      type="password"
                      style={{ width: "500px", margin: ".5rem 0rem" }}
                      defaultValue=""
                      placeholder="New password"
                      name="password"
                      id="password"
                      ref={register({
                        required: "You must enter a password",
                        minLength: {
                          value: 8,
                          message: "Your password must have at least 8 characters",
                        },
                      })}
                    />
                  </div>
                  <p style={{ marginLeft: "1rem", marginTop: "1rem", cursor: "pointer" }} onClick={() => setPasswordChange(!passwordChange)}>Cancel</p>
                </div>
                {errors.password && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.password.message}</Label>}
                <label htmlFor="confirm_password" >Confirm Password</label>
                <input
                  type="password"
                  style={{ width: "500px", margin: ".5rem 0rem" }}
                  defaultValue={""}
                  placeholder="Confirm password"
                  name="confirm_password"
                  id="confirm_password"
                  ref={register({
                    validate: (value) =>
                      value === password.current || "Your passwords do not match",
                  })}
                />
                {errors.confirm_password && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.confirm_password.message}</Label>}
              </>) :
              (<div onClick={() => setPasswordChange(!passwordChange)} style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                  <label htmlFor="password" >Password</label>
                  <input
                    type="password"
                    style={{ width: "500px", margin: ".5rem 0rem" }}
                    defaultValue=""
                    placeholder="New password"
                  />
                </div>
                <p style={{ marginLeft: "1rem", marginTop: "1rem", cursor: "pointer" }} >Change Password</p>
              </div>)
            }
            {(personUsing.user_types_id === 2 || personUsing.user_types_id === 3) && userTypes && userTypes.length > 0 && (
              <>
                <label htmlFor="user_types_id">User Type</label>
                <select
                  style={{ width: "500px", margin: ".5rem 0rem" }}
                  defaultValue={user.user_types_id}
                  name="user_types_id"
                  id="user_types_id"
                  ref={register({
                    required: "You must specify user premissions", valueAsNumber: true
                  })}
                >
                  {userTypes.map(type => (
                    <option key={type.id} value={type.id}>{type.name.charAt(0).toUpperCase() + type.name.slice(1)}</option>
                  ))}
                </select>
                {errors.user_types_id && <Label basic style={{ width: "500px", textAlign: "center", margin: ".5rem 0rem" }} color='red' pointing>{errors.user_types_id.message}</Label>}
              </>
            )}
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" onClick={handleSubmit(handlePutRequest)} inverted color='yellow'>
          Save
        </Button>
        <Button onClick={() => setOpen(false)} inverted color='red'>
          Exit
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ManageSingleUser;