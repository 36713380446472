import React, { useState } from 'react'
import { Button, Modal, Form, Label } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { axiosWithAuth } from '../../../helperFunctions/axiosWithAuth';

const ManageSingleFacility = ({ facility, setFacilities, facilities }) => {

    const orginialFacilityName = facility.name;
    const orginialFacilityLocation = facility.location;
    const orginialFacilitySqft = facility.square_footage;
    const { handleSubmit, errors, register } = useForm();
    const [open, setOpen] = useState(false);

    const handleUpdatingFacility = (data) => {
        if (data.name !== orginialFacilityName || data.location !== orginialFacilityLocation || data.square_footage !== orginialFacilitySqft) {
            axiosWithAuth()
                .put(`/facilities/${facility.id}`, data )
                .then(res => {
                    setFacilities(res.data);
                    setOpen(false);
                }).catch(err => { console.log(err) });
        }
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={<Button onClick={e => e.preventDefault()} basic color='yellow'>Manage</Button>}
        >
            <Modal.Header>Manage Facility</Modal.Header>
            <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div>
                    <Form style={{ width: "700px", margin: "0 auto", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            defaultValue={facility.name}
                            name="name"
                            id="name"
                            ref={register({
                                required: "You must provide a Facility name",
                                minLength: {
                                    value: 2,
                                    message: "Facility name must be at least 2 characters"
                                }
                            })}
                        />
                        {errors.name && <Label basic color='red' pointing >* {errors.name.message} *</Label>}
                        <label htmlFor="location" style={{ marginTop: "1rem" }}>Location</label>
                        <input
                            type="text"
                            defaultValue={facility.location}
                            name="location"
                            id="location"
                            ref={register({
                                required: "You must provide the location",
                                minLength: {
                                    value: 2,
                                    message: "Location must be at least 2 characters"
                                }
                            })}
                        />
                        {errors.location && <Label basic color='red' pointing >* {errors.location.message} *</Label>}
                        <label htmlFor="square_footage" style={{ marginTop: "1rem" }}>Square Footage</label>
                        <input
                            type="text"
                            defaultValue={facility.square_footage}
                            name="square_footage"
                            id="square_footage"
                            ref={register({
                                required: "Required",
                                valueAsNumber: true,
                                validate: (value) => value > 1 || "Required",
                            })}
                        />
                        {errors.square_footage && <Label basic color='red' pointing >* {errors.square_footage.message} *</Label>}
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button type="submit" onClick={handleSubmit(handleUpdatingFacility)} basic color='yellow'>
                    Save
                </Button>
                <Button onClick={() => setOpen(false)} basic color='red'>
                    Exit
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ManageSingleFacility;