import { useForm } from "react-hook-form";
import { Label, Modal, Button, Icon, Header, Divider } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { useState, useRef } from "react"
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth";



const TypeOfAdvertising = ({ formId }) => {

    const { handleSubmit, register, watch } = useForm();
    const [open, setOpen] = useState(false);
    const [myData, setMyData] = useState({})
    const [validationError, setValidationError] = useState(false)
    const history = useHistory();

    // Watching our two uncontrolled inputs to make sure the user does leave them blank
    const concierge = useRef({});
    const carte = useRef({});
    concierge.current = watch("concierge", false);
    carte.current = watch("carte", false);

    const handleDataFormatting = data => {
        if (data["concierge"] === true) {
            setMyData({ form_complete: true, type_of_advertsing: "Concierge Service" })
        } else {
            setMyData({ form_complete: true, type_of_advertsing: "A la Carte" })
        }
    }

    const handleMyDataPut = () => {
        axiosWithAuth()
            .put(`/advertising/${formId}`, myData)
            .then(res => {
                history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
            }).catch(err => { console.log(err) })
    }

    return (
        <form onSubmit={handleSubmit(handleDataFormatting)}>
            <section style={{ display: "flex", flexDirection: "column", textAlign: "left", width: "80%", margin: "2rem auto 3rem auto" }}>
                <p ><strong>Please select the category that best applies to your advertising goals.</strong></p>
                <div style={{ display: "flex", flexDirection: "column", marginTop: "1rem", marginBottom: "1rem" }}>
                    <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            id="concierge"
                            name="concierge"
                            ref={register({
                                required: false,
                            })} />
                        <label htmlFor="concierge" style={{ marginBottom: "1rem", marginLeft: "1rem" }}><strong>Concierge Service</strong></label>
                    </div>
                    <p>If you want us handle everything related to advertising, let us know because we’ve done it all. Let us do the research to let you know what it’s going to cost and what kind of payback you can get expect.</p>
                </div>
                <Divider />
                <div style={{ display: "flex", flexDirection: "column", marginTop: "1rem" }}>
                    <div style={{ display: "flex" }}>
                        <input
                            type="checkbox"
                            name="carte"
                            id="carte"
                            ref={register({
                                required: false,
                            })} />
                        <label htmlFor="carte" style={{ marginBottom: "1rem", marginLeft: "1rem" }}><strong>A la Carte</strong></label>
                    </div>
                    <p>If you want us to push existing content, we can do that too. Let us do the research to let you know what it’s going to cost and what kind of payback you can expect.</p>
                </div>
            </section>
            {validationError && <Label basic color='red' style={{ textAlign: "center", width: "85%", marginBottom: ".5rem" }}> Please choose one</Label>}
            <Divider />
            <div style={{ width: "80%", margin: "0 auto", textAlign: "right" }}>
                <Modal
                    basic
                    onClose={() => setOpen(false)}
                    onOpen={() => {
                        setValidationError(false)
                        // checking to make sure the text areas are completed before rendering the confirm submission modal
                        if ((concierge.current === true && carte.current === false) || (concierge.current === false && carte.current === true)) {
                            setOpen(true)
                        } else {
                            setValidationError(true)
                        }
                    }}
                    open={open}
                    size='small'
                    trigger={<button style={{ width: "8rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1.5rem", fontSize: "1.2rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", marginBottom: "2rem" }}>Submit</button>}
                >
                    <Header icon><Icon name='send' />Advertising Form</Header>
                    <Modal.Content>
                        <p style={{ fontWeight: 700 }}>After submission, this form will be sent for review and will only be accessible in the form management tab located in settings. Are you sure you want to submit?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic color='red' inverted onClick={() => setOpen(false)}>
                            <Icon name='remove' /> No
                        </Button>
                        <Button type="submit" color='green' inverted onClick={() => {
                            handleMyDataPut()
                            setOpen(false)
                        }}>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </form>
    )
}

export default TypeOfAdvertising;