import { useEffect, useState, useRef } from "react";
import { Divider, Form, Button, Label, Modal, Header, Icon } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from "../../../../helperFunctions/axiosWithAuth";

const GeneralInformation = () => {
    const { handleSubmit, errors, register, watch } = useForm();
    const [passwordChange, setPasswordChange] = useState(false);
    const [user, setUser] = useState({})
    const [open, setOpen] = useState(false);
    const [company, setCompany] = useState({})
    const history = useHistory();
    const [accountManagers, setAccountManager] = useState([]);
    const password = useRef({});
    password.current = watch("password", "");
    const { id } = useParams()
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var companiesId = byteId.toString(CryptoJS.enc.Utf8);
    var byteUserId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
    var userId = byteUserId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`companies/${companiesId}`)
            .then(res => {
                setCompany(res.data)
            }).catch(err => console.log(err))
    }, [companiesId])

    useEffect(() => {
        axiosWithAuth()
            .get(`users`)
            .then(res => {
                setAccountManager(res.data)
            }).catch(err => { console.log(err) })
    }, [])

    useEffect(() => {
        axiosWithAuth()
            .get(`users/${userId}`)
            .then(res => {
                setUser(res.data)
            }).catch(err => { console.log(err) })
    }, [userId])

    const handlePutRequest = data => {
        if (data["confirm_password"]) {
            delete data["confirm_password"]
        }
        delete data["users_id"];

        if (data.name !== company.name || data.first_name !== company.first_name || data.last_name !== company.last_name || data.phone_number !== company.phone_number || passwordChange === true) {
            axiosWithAuth()
                .put(`companies/${company.id}`, data)
                .then(res => {
                    setCompany(res.data)
                    history.push(`/employeedashboard`)
                }).catch(err => { console.log(err) })
        }
    }

    const handleRemovingCompany = () => {
        axiosWithAuth()
            .delete(`companies/${company.id}`)
            .then(res => {
                history.push("/employeedashboard")
            }).catch(err => { console.log(err) })
    }

    return (
        <>
            {company && Object.keys(company).length > 0 && (
                <>
                    <div style={{ width: "850px", margin:"0rem auto", textAlign: "left" }}>
                        <div style={{ fontWeight: 800, fontSize: "3rem"}}>{company && company.email}</div>
                        <p>{company && company.name}'s Account Information</p>
                    </div>
                    <Divider />
                    {accountManagers && accountManagers.length > 0 && (
                        <Form style={{ width: "850px", margin: "1rem auto", textAlign: "left", display: "flex", flexDirection: "column", }}>
                            <label htmlFor="name" >Company Name</label>
                            <input
                                style={{ width: "500px", margin: ".5rem 0rem" }}
                                defaultValue={company.name}
                                name="name"
                                id="name"
                                ref={register({
                                    required: "You must provide a company name",
                                    minLength: {
                                        value: 2,
                                        message: "Company name must be at least 2 characters"
                                    }
                                })}

                            />
                            {errors.name && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.name.message} </Label>}
                            <label htmlFor="first_name" >First Name</label>
                            <input
                                style={{ width: "500px", margin: ".5rem 0rem" }}
                                defaultValue={company.first_name}
                                name="first_name"
                                id="first_name"
                                ref={register({
                                    required: "You must provide a first name",
                                    minLength: {
                                        value: 2,
                                        message: "First name must be at least 2 characters"
                                    }
                                })}
                            />
                            {errors.first_name && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.first_name.message}</Label>}
                            <label htmlFor="last_name" >Last Name</label>
                            <input
                                style={{ width: "500px", margin: ".5rem 0rem" }}
                                defaultValue={company.last_name}
                                name="last_name"
                                id="last_name"
                                ref={register({
                                    required: "You must provide a last name",
                                    minLength: {
                                        value: 2,
                                        message: "Last name must be at least 2 characters"
                                    }
                                })}
                            />
                            {errors.last_name && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.last_name.message}</Label>}
                            <label htmlFor="phone_number" >Phone Number</label>
                            <input
                                style={{ width: "500px", margin: ".5rem 0rem" }}
                                defaultValue={company.phone_number}
                                name="phone_number"
                                id="phone_number"
                                ref={register({
                                    required: "You must enter an email",
                                    pattern: {
                                        value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                                        message: "Please enter a valid phone number"
                                    }
                                })}
                            />
                            {errors.phone_number && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.phone_number.message}</Label>}
                            {user && Object.keys(user).length > 0 && (user.user_types_id === 2 || user.user_types_id === 3) && (
                                <>
                                    <label htmlFor="users_id" >Account Manager</label>
                                    <select
                                        defaultValue={company.users_id}
                                        style={{ width: "500px", margin: ".5rem 0rem" }}
                                        name="users_id"
                                        id="users_id"
                                        ref={register({
                                            required: "You must specify who the account manager will be", valueAsNumber: true
                                        })}
                                    >
                                        {accountManagers.map(manager => (
                                            <option key={manager.id} value={manager.id}>{manager.first_name} {manager.last_name}</option>
                                        ))}
                                    </select>
                                    {errors.users_id && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.users_id.message}</Label>}
                                </>
                            )}
                            {passwordChange === true ? (
                                <>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                                            <label htmlFor="password" >Password</label>
                                            <input
                                                type="password"
                                                style={{ width: "100%", margin: ".5rem 0rem" }}
                                                defaultValue=""
                                                placeholder="New password"
                                                name="password"
                                                id="password"
                                                ref={register({
                                                    required: "You must enter a password",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Your password must have at least 8 characters",
                                                    },
                                                })}
                                            />
                                        </div>
                                        <p style={{ marginLeft: "1rem", marginTop: "1rem", cursor: "pointer" }} onClick={() => setPasswordChange(!passwordChange)}>Cancel</p>
                                    </div>
                                    {errors.password && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.password.message}</Label>}
                                    <label htmlFor="confirm_password" >Confirm Password</label>
                                    <input
                                        type="password"
                                        style={{ width: "500px", margin: ".5rem 0rem" }}
                                        defaultValue={""}
                                        placeholder="Confirm password"
                                        name="confirm_password"
                                        id="confirm_password"
                                        ref={register({
                                            validate: (value) =>
                                                value === password.current || "Your passwords do not match",
                                        })}
                                    />
                                    {errors.confirm_password && <Label basic style={{ width: "500px", textAlign: "center" }} color='red' pointing>{errors.confirm_password.message}</Label>}
                                </>) :
                                (<div onClick={() => setPasswordChange(!passwordChange)} style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column", width: "500px" }}>
                                        <label htmlFor="password" >Password</label>
                                        <input
                                            type="password"
                                            style={{ width: "100%", margin: ".5rem 0rem" }}
                                            defaultValue=""
                                            placeholder="New password"
                                        />
                                    </div>
                                    <p style={{ marginLeft: "1rem", marginTop: "1rem", cursor: "pointer" }} >Change Password</p>
                                </div>)
                            }
                            <div style={{ marginTop: "2rem" }}>
                                <Button type="submit" color="yellow" onClick={handleSubmit(handlePutRequest)} basic >Update Account</Button>
                                <Modal
                                    basic
                                    onClose={() => setOpen(false)}
                                    onOpen={() => { setOpen(true) }}
                                    open={open}
                                    size='small'
                                    trigger={<Button color="red" basic >Delete Account</Button>}
                                >
                                    <Header icon><Icon name='trash' />Removing Company</Header>
                                    <Modal.Content>
                                        <p style={{ fontWeight: 700, textAlign: "center" }}>Are you sure you want to delete company Account?</p>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='red' inverted onClick={() => setOpen(false)}>
                                            <Icon name='remove' /> No
                                        </Button>
                                        <Button color='green' inverted onClick={() => {
                                            handleRemovingCompany()
                                            setOpen(false)
                                        }}>
                                            <Icon name='checkmark' /> Yes
                                        </Button>
                                    </Modal.Actions>
                                </Modal>
                            </div>
                        </Form>
                    )}
                </>
            )}
        </>
    )
}

export default GeneralInformation;