import { Container, Typography, Chip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "2rem 0rem 2rem 0rem",
        width: 200,
        //border: "solid 2px purple"
    },
    chipCss: {
        padding: "1rem",
        fontSize: "16px",
        backgroundColor: "rgb(247,197,70)",
        cursor: "pointer",
        opacity: ".8",
        "&:hover": {
            opacity: "1",
        }
    }
}));

const OneDriveLink = ({ company, oneDriveLink }) => {

    const classes = useStyles();

    return (
        <Container className={classes.root} align="right">
            <a href={oneDriveLink[0].data} target="_blank">
                <Chip
                    icon={<CloudUploadIcon />}
                    label={"One Drive"}
                    className={classes.chipCss}
                />
            </a>
        </Container>
    )
}

export default OneDriveLink;