import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Label, Button, Divider, Form, Modal, Icon, Header } from 'semantic-ui-react';
import { useParams } from "react-router-dom";

const ProductServices = ({ marketMappingForm, setMarketMappingForm, formName, fillOutMarketMappingForm, history, originalText }) => {

    const [products, setProducts] = useState([["name0", "percentage0"]]);
    const [validatePercentages, setValidatedPercentages] = useState(false)
    const { handleSubmit, errors, register, watch } = useForm();
    const [myData, setMyData] = useState({})
    const [open, setOpen] = useState(false);
    const myError = useRef();
    myError.current = watch(errors)
    const { id } = useParams();

    const handleAddingInput = (myI, e) => {
        e.preventDefault()
        if (myI < 5) {
            setProducts([...products, [`name${myI + 1}`, `percentage${myI + 1}`]])
        }
    }

    const handleRemovingInput = (myI, e) => {
        e.preventDefault()
        if (products.length > 1) {
            setProducts(products.filter(channel => {
                if (channel[0] !== `name${myI}`) {
                    return channel
                }
            }))
        }
    }
    const handleFilteringData = data => {

        let myObject = {}
        let compId = Number(originalText)

        for (const [key, value] of Object.entries(data)) {
            //filtering and matching data
            const newKey = key.replace("name", "");
            const filteredKey = newKey.replace("percentage", "");
            if (!myObject[filteredKey]) {
                myObject[filteredKey] = value
            }
            else {
                myObject[filteredKey] = { "name": myObject[filteredKey], "percentage": value, companies_id: compId, marketing_mapping_forms_id: id };
            }
        }
        return { "products": Object.values(myObject), isForm: true }
    }


    const handleValidationOfPercetages = data => {
        let count = 0;
        for (const [key, value] of Object.entries(data)) {
            if (key.includes("percentage")) {
                count += value
            }
        }
        if (count === 100) {
            return true
        }

        return false
    }

    const HandleValidation = data => {
        setValidatedPercentages(false)
        setMyData(data)
        if (handleValidationOfPercetages(data) === true) {
            setMyData(data)
            document.getElementById("submit-button").click()
        } else {
            setValidatedPercentages(true)
        }
    }

    const handlePost = data => {
        fillOutMarketMappingForm(formName, handleFilteringData(data))
            .then(res => {
                setMarketMappingForm({
                    ...marketMappingForm,
                    products_services_complete: 1,
                    all_forms_complete: 1
                })
                history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`);
            })
            .catch(err => { console.log(err) })
    }

    return (
        <div style={{ textAlign: "left", padding: "2rem" }}>
            <div style={{ fontSize: "2rem", margin: "1rem 0rem" }}><strong>Products and Services</strong></div>
            <p>Please list up to 6 products and/or services that you currently sell or are hoping to sell. Examples include: aluminum wheels, adhesives, software (products) or installation, repair, consultation (services). If you primarily sell one product or service, please note that in the area below. If you sell multiple products or services, please try to approximate what proportion of your sales are associated with each product or service in the boxes to the right.</p>
            <div>
                <Form onSubmit={handleSubmit(HandleValidation)} style={{ display: "flex", flexDirection: "column" }}>
                    {products && products.map((product, i) => (
                        <div key={i} style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", }}>
                                <label style={{ width: "40%", marginRight: "1rem" }} htmlFor={product[0]}>Product/Service {i + 1}</label>
                                <label htmlFor={product[1]}>Percentage {i + 1}</label>
                            </div>
                            <div style={{ display: "flex", margin: ".5rem 0rem" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "40%", marginRight: "1rem", alignItems: "center" }}>
                                    <input
                                        type="text"
                                        id={product[0]}
                                        name={product[0]}
                                        ref={register({
                                            required: "You must provide a product or service name",
                                            minLength: {
                                                value: 2,
                                                message: "product or service must be at least 2 characters"
                                            }
                                        })}
                                    />
                                    {errors[product[0]] && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem" }} pointing>{errors[product[0]].message}</Label>}
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "40%", marginRight: "1rem", alignItems: "center" }}>
                                    <input
                                        type="text"
                                        id={product[1]}
                                        name={product[1]}
                                        ref={register({
                                            required: "You must provide a percentage between 1-100",
                                            valueAsNumber: true,
                                            validate: (value) => value <= 100 && value >= 1 || "You must provide a percentage between 1-100",
                                        })} />
                                    {errors[product[1]] && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem" }} pointing>{errors[product[1]].message}</Label>}
                                </div>
                                {i === products.length - 1 &&
                                    <div>
                                        <Button icon="add" color="yellow" onClick={(e) => handleAddingInput(i, e)} />
                                        <Button icon="x" onClick={(e) => handleRemovingInput(i, e)}></Button>
                                    </div>}
                            </div>
                        </div>
                    ))}
                    {validatePercentages === true && <Label basic color='red' style={{ textAlign: "center", marginBottom: ".5rem", marginRight: "1rem", width: "82%" }}>All percentages together should add up to 100</Label>}
                    <Divider />
                    <div style={{ marginTop: ".5rem", textAlign: "left", display: "flex", justifyContent: "flex-end" }}>
                        <Button className="submit-button" type="submit" color="yellow">Submit</Button>
                        <Modal
                            basic
                            onClose={() => setOpen(false)}
                            onOpen={() => {
                                setOpen(true)
                            }}
                            open={open}
                            size='small'
                            trigger={<Button id="submit-button" style={{ display: "none" }} color="yellow">Submit</Button>}
                        >
                            <Header icon><Icon name='send' />Market Mapping Form</Header>
                            <Modal.Content>
                                <p style={{ fontWeight: 700 }}>After submission, this form will be sent for review and will only be accessible in the form management tab located in settings. Are you sure you want to submit?</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button basic color='red' inverted onClick={() => setOpen(false)}>
                                    <Icon name='remove' /> No
                        </Button>
                                <Button type="submit" color='green' inverted onClick={() => {
                                    handlePost(myData)
                                    setOpen(false)
                                }}>
                                    <Icon name='checkmark' /> Yes
                        </Button>
                            </Modal.Actions>
                        </Modal>
                    </div>
                </Form>
            </div>
        </div>
    )
}


export default ProductServices;