import { useState } from "react";
import { axiosWithAuth } from "../../../helperFunctions/axiosWithAuth.js";
import GeneralInformation from "./generalInformation/index.js";
import OnBoardingInformation from "../onboardinginfo/index.js";
import { Button } from "semantic-ui-react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OneDrive from "./onedrive";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(30),
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    accordionBody: {
        display: "flex",
        flexDirection: "column",
        margin: "0rem auto",
    },
    passwordResetContainer: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "1em 2em 2em 2em"
    }
}));


function CompanyInformation({ user }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const handlePasswordResetEmail = () => {
        axiosWithAuth()
          .post("/auth/passwordresetemail", { email: user.email, accManagerRequest: true })
          .then(res => {
            enqueueSnackbar('Password reset email was sent successfully.', { variant: "success" });
          })
      };

    return (
        <div className={classes.root}>
            <div className={classes.passwordResetContainer}>
                <Button color="yellow" onClick={() => handlePasswordResetEmail()} basic >Send Login Email</Button>
            </div>
            <Accordion expanded={expanded === 'generalInformation'} onChange={handleChange('generalInformation')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>General Information</Typography>
                    <Typography className={classes.secondaryHeading}></Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionBody}>
                    <GeneralInformation />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'onboardingInformation'} onChange={handleChange('onboardingInformation')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography className={classes.heading}>On-boarding Profile</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionBody}>
                    <OnBoardingInformation companyDash={true} />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'credentials'} onChange={handleChange('credentials')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                >
                    <Typography className={classes.heading}>Company Credentials</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionBody}>
                    <OneDrive />
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default CompanyInformation;