import ManageSingleService from "./managesingleservice";
import { useState } from "react"
import { Button, Grid, Image, Modal, Icon, Header } from 'semantic-ui-react';
import { axiosWithAuth } from "../../helperFunctions/axiosWithAuth";


const AllServicesGrid = ({ service, setServices, services }) => {

    const [open, setOpen] = useState(false);

    const handleDelete = () => {
        axiosWithAuth()
            .delete(`/services/${service.id}`)
            .then(res => {
                setServices(services.filter(serv => {
                    if (serv.id !== service.id) {
                        return serv
                    }
                }))
            }).catch(err => console.log(err))
    }

    return (
        <>
            <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "center", borderBottom: "1px solid grey" }}><Image src={service.image} size="tiny" /></Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", borderBottom: "1px solid grey" }}>{service.name}</Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", borderBottom: "1px solid grey" }}>{service.headline}</Grid.Column>
            <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid grey" }}>
                <ManageSingleService service={service} setServices={setServices} services={services} />
                <Modal
                    basic
                    onClose={() => setOpen(false)}
                    onOpen={() => { setOpen(true) }}
                    open={open}
                    size='small'
                    trigger={<Button onClick={() => setOpen(false)} inverted color='red'>Delete</Button>}
                >
                    <Header icon><Icon name='trash' />{service.name} Service</Header>
                    <Modal.Content>
                        <p style={{ fontWeight: 700, textAlign: "center" }}> Are you sure you want to delete the {service.name} service?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic color='red' inverted onClick={() => setOpen(false)}>
                            <Icon name='remove' /> No
                        </Button>
                        <Button type="submit" color='green' inverted onClick={() => {
                            handleDelete()
                            setOpen(false)
                        }}>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Grid.Column >
        </>
    )
}

export default AllServicesGrid;