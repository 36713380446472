import { useState, useEffect, useRef } from "react";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import CurrentPageFooter from "../gettingStartedUtils/currentPageFooter.js";
import Ecommerce from "./ecommerce";
import Retailers from "./retailers";
import MailingList from "./mailingList";
import DigitalAds from "./digitalAds";
import Tradeshows from "./tradeshows";
import Webinars from "./webinars";
// import Covid from "./covid";
import Ganalytics from "./google";
import { getCompanyInfo, fillOutForm } from "../../../reduxStore/actions/companies-actions";
import CryptoJS from "crypto-js";
import '../../../App.css'

function MarketingEval({ company, getCompanyInfo, fillOutForm, AfterInitialSignUp }) {

  const { handleSubmit, register, errors } = useForm();
  const history = useHistory();
  const [inputs, setInputs] = useState({
    e_commerce: undefined,
    distributors_retailers_agents: undefined,
    mailing_list: undefined,
    digital_ads: undefined,
    tradeshows: undefined,
    webinars: undefined,
    convid_19: undefined,
    google: undefined
  });
  var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
  var theId = byteId.toString(CryptoJS.enc.Utf8);

  const checkStatus = company => {
    if (company.marketing_evaluation_form === true) {
      history.push(`/serviceselection`)
    }
  }

  useEffect(() => {
    // Checking to see if a company has completed this form already and if the redux store has the company information or if we should reload it
    if (company && company.marketing_evaluation_form) {
      checkStatus(company)
    } else {
      getCompanyInfo(theId)
        .then(res => {
          if (res && res.id > 0){
            checkStatus(res)
          }
        })
    }
  }, [])


  const handleChange = (e) => {
    let formattedInput; 

    // Checking to see if the input needs to be a boolean
    if (e.target.name in inputs){
      if (e.target.value === "true"){
        formattedInput = true;
      } else {
        formattedInput = false;
      }
    } else {
      formattedInput = e.target.value
    }
    
    setInputs({
      ...inputs,
      [e.target.name]: formattedInput
    })
  };


  const handleNextForm = data => {
    fillOutForm("marketingevaluations", { ...data, companies_id: Number(theId) })
      .then(res => {
        history.push("/serviceSelection")
      }).catch(err => console.log(err));
  }


  return (
    <>
      { company && company.marketing_evaluation_form === false && (
        <>
          <div class="market-eval-div">
            <div style={{ backgroundColor: "rgb(255,191,77)", width: "100%", textAlign: "center" }}>
              <div style={{ fontSize: "2rem", padding: "1rem", color: "rgb(51,51,51)" }}>Marketing Evaluation Form</div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "space-between", minHeight: "225px", justifyContent: "space-between" }}>
              <section style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <form id="websiteForm" onSubmit={handleSubmit(handleNextForm)} style={{ width: "100%", display: "flex", padding: "0% 5%", flexDirection: "column" }}>
                  <div style={{ width: "100%", display: "flex", borderBottom: "black solid 1px", paddingBottom: "1rem", marginBottom: ".5rem", flexDirection: "column", textAlign: "left", justifyContent: "space-evenly" }}>
                    <div style={{ padding: "2%", fontSize: "1.5rem", fontWeight: 600 }}>Go-To-Market Approach:</div>
                    <Ecommerce handleChange={handleChange} inputs={inputs} register={register} errors={errors} />
                    <Retailers handleChange={handleChange} inputs={inputs} register={register} errors={errors} />
                    <div style={{ padding: "1% 2% 2% 2%", fontSize: "1.5rem", fontWeight: 600 }}>Current communication channels:</div>
                    <MailingList handleChange={handleChange} inputs={inputs} register={register} errors={errors} />
                    <DigitalAds handleChange={handleChange} inputs={inputs} register={register} errors={errors} />
                    <Tradeshows handleChange={handleChange} inputs={inputs} register={register} errors={errors} />
                    <Webinars handleChange={handleChange} inputs={inputs} register={register} errors={errors} />
                    {/* <Covid handleChange={handleChange} inputs={inputs} register={register} errors={errors} /> */}
                    <Ganalytics handleChange={handleChange} register={register} errors={errors} />
                  </div>
                  <section style={{ display: "flex", justifyContent: "flex-end", marginRight: "5%" }}>
                    <button type='submit' style={{ width: "6rem", backgroundColor: "rgb(255,191,77)", padding: ".5rem 1rem", fontSize: "1rem", borderRadius: "10px", fontWeight: 900, border: "none", cursor: "pointer", margin: "1rem .2rem" }}>Next</button>
                  </section>
                </form>
              </section>
            </div>
          </div>
          {AfterInitialSignUp === false && <CurrentPageFooter page="marketingevaluation" />}
        </>)}
    </>
  );
}


const mapStateToProps = state => {
  return {
    company: state.companiesReducer.company,
  }
}

export default connect(mapStateToProps, { getCompanyInfo, fillOutForm })(MarketingEval);