import { useRef, useState } from "react";
import { useForm, Controller, Control } from "react-hook-form";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { creatingCompany } from '../../../reduxStore/actions/companies-actions';
import '@fontsource/roboto';
import Image from 'material-ui-image'
import { palette } from '@material-ui/system';
import '../../../App.css'


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: "60%",
    marginBottom: "5%"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  TextField: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
    fontSize: 18,
  },
  RyGrid: {
    padding: theme.spacing(3, 2),
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  heading: {
    fontSize: 36,
  },
  subHeading: {
    fontWeight: 700,
    fontSize: 22,
  },
  fieldHeading: {
    fontSize: 22,
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  para: {
    fontSize: 20
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    padding: "35px !important"
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  stepsConatiner: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "baseline"
  },
  mainContainer: {
    display: "flex", 
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  innerDivsForSteps: {
    width: "15%"
  },
  innerDivsForStepsText: {
    width: "85%"
  }
}));


function SignUpForm({ creatingCompany }) {
  const classes = useStyles();
  const history = useHistory();
  const [err, setErr] = useState();
  const { handleSubmit, errors, watch, control } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const handleSignup = data => {
    // confirm password isn't a field in our DB, so we are removing it from the object
    delete data['confirm_password'];
    data["need_info"] = true;
    data["service_of_interest"] = { 1: false, 2: false, 3: false, 4: false, 5: false };
    data["users_id"] = 1; // This will default to Trisha;

    creatingCompany(data)
      .then(response => {
        if (response && response.user) {
          history.push("/websites")
        } else {
          if (response.data.errorMessage) {
            setErr(response.data.errorMessage);
          } else {
            setErr("Unexpected Error");
          }
        }
      })
  }

  return (
    <div>
      <Container maxWidth="lg">
        <Grid container spacing={2} className={classes.mainContainer}>
          {/* This is the top header and paragraph section */}
          <Grid item xs={12} className={classes.headerText}>
            <Typography className={classes.heading} variant="h5" component="h2"> An Unprecedented, In-Depth Look At Your Business </Typography>
            <Typography className={classes.para} m>
            To get started and move forward, please create your account below to share some data with our team. We'll be right back to you with our assessment. Thank you.
            </Typography>
          </Grid>
          {/* This is the steps section */}
          <Grid item sm={9} className={classes.stepsConatiner}>
            <Grid item sm={3} className={classes.fieldHeading}>
              <div className={classes.innerDivsForSteps}>1.</div>
              <div className={classes.innerDivsForStepsText}>CREATE AN ACCOUNT</div>
            </Grid>
            <Grid item sm={3} className={classes.fieldHeading}>
              <div className={classes.innerDivsForSteps}>2.</div>
              <div className={classes.innerDivsForStepsText}>TELL US MORE</div>
            </Grid>
            <Grid item sm={3} className={classes.fieldHeading}>
              <div className={classes.innerDivsForSteps}>3.</div>
              <div className={classes.innerDivsForStepsText}>WE'LL GET BACK TO YOU ASAP</div>
            </Grid>
          </Grid>
          {/* Start of the form section */}
          <Grid item xs={12} className={classes.formContainer}>
            <div className={classes.paper}>
              <div class="signup">
                <Typography className={classes.heading} component="h1" variant="h5" >
                  Marketing Evaluation Sign-up
                </Typography>
              </div>
              <form className={classes.form} onSubmit={handleSubmit(handleSignup)} >
                <Grid item xs={12}>
                  <Controller
                    render={(props) => <TextField {...props} required className={classes.TextField} variant="outlined" fullWidth label="First Name" />}
                    rules={{
                      required: "You must provide a first name",
                      minLength: {
                        value: 2,
                        message: "First name must be at least 2 characters"
                      }
                    }}
                    name="first_name"
                    id="first_name"
                    control={control}

                  />
                  {errors.first_name && <p style={{ color: "red", margin: "0rem" }}>* {errors.first_name.message} *</p>}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={(props) => <TextField {...props} required className={classes.TextField} variant="outlined" d fullWidth label="Last Name" />}


                    rules={{
                      required: "You must provide a last name",
                      minLength: {
                        value: 2,
                        message: "Last name must be at least 2 characters"
                      }
                    }}
                    name="last_name"
                    id="last_name"
                    control={control}
                    fullWidth
                  />
                  {errors.last_name && <p style={{ color: "red", margin: "0rem" }}>* {errors.last_name.message} *</p>}
                </Grid>
                <Grid item xs={12}>
                  <Controller

                    render={(props) => <TextField {...props} required className={classes.TextField} variant="outlined" fullWidth label="Company Name" />}

                    rules={{
                      required: "You must provide a Company name",
                      minLength: {
                        value: 2,
                        message: "Company name must be at least 2 characters"
                      }
                    }}
                    control={control}
                    name="name"
                    id="name"
                  />
                  {errors.name && <p style={{ color: "red", margin: "0rem" }}>* {errors.name.message} *</p>}
                </Grid>
                <Grid item xs={12} onClick={() => err ? setErr("") : null}>
                  <Controller
                    render={(props) => <TextField {...props} required className={classes.TextField} variant="outlined" fullWidth label="Email Address" />}
                    variant="outlined"
                    fullWidth
                    rules={{
                      required: "You must enter an email",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Please enter a valid email address"
                      }
                    }}

                    control={control}

                    name="email"
                    id="email"
                  />
                  {errors.email && <p style={{ color: "red", margin: "0rem" }}>* {errors.email.message} *</p>}
                  {err && <p style={{ color: "red", marginTop: "1rem" }}>* {err} *</p>}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={(props) => <TextField {...props} className={classes.TextField} required variant="outlined" defaultValue="Phone Number" fullWidth label="Phone Number" />}
                    variant="outlined"
                    type="tel"
                    name="phone_number"
                    id="phone_number"
                    control={control}
                    defaultValue={""}
                    fullWidth
                    rules={{
                      required: "You must enter a phone number",
                      pattern: {
                        value: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i,
                        message: "Please enter a valid phone number"
                      }
                    }}
                  />
                  {errors.phone_number && <p style={{ color: "red", margin: "0rem" }}>* {errors.phone_number.message} *</p>}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={(props) => <TextField {...props} required className={classes.TextField} variant="outlined" fullWidth type="password" label="Password" />}
                    control={control}
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    rules={{
                      required: "You must enter a password",
                      minLength: {
                        value: 8,
                        message: "Your password must have at least 8 characters",
                      },
                    }}
                  />
                  {errors.password && <p style={{ color: "red", margin: "0rem" }}>* {errors.password.message} *</p>}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    render={(props) => <TextField {...props} required variant="outlined" className={classes.TextField} fullWidth type="password" label="Confirm Password" />}
                    control={control}
                    fullWidth
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    defaultValue={""}
                    rules={{
                      validate: (value) =>
                        value === password.current || "Your passwords do not match",
                    }}
                  />
                  {errors.confirm_password && <p style={{ color: "red", margin: "0rem" }}>* {errors.confirm_password.message} *</p>}
                </Grid>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  id="submit"
                  className={classes.submit}

                >
                  Sign Up
                </Button>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link to="/login" variant="body2">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default connect(null, { creatingCompany })(SignUpForm)