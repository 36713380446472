import { useState, useEffect } from "react";
import { Button, Table, Modal, Label, Form } from "semantic-ui-react";
import { useForm } from 'react-hook-form';
import { axiosWithAuth } from "../../../../helperFunctions/axiosWithAuth.js";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";

const OneDrive = () => {

    const [oneDrive, setOneDrive] = useState();
    const { register, handleSubmit, errors } = useForm();
    const [ dupError, setDupError] = useState(false)
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    var byteId = CryptoJS.AES.decrypt(decodeURIComponent(id), localStorage.getItem("SWassWQWiu"));
    var companiesId = byteId.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        axiosWithAuth()
            .get(`/companies/${companiesId}/creds`)
            .then(res => {
                setOneDrive(res.data.filter(cre => {
                    if (cre.name === "one drive") {
                        return cre
                    }
                }))
            }).catch(err => { console.log(err) })
    }, [])

    const handleOneDrivePost = data => {
        setDupError(false)
        data["name"] = "one drive"
        data["companies_id"] = Number(companiesId)
        axiosWithAuth()
            .post(`/creds`, data)
            .then(res => {
                setOneDrive(res.data)
                setOpen(false)
            }).catch(err => setDupError(true))
    }

    const handleRemovingInstance = instanceId => {
        axiosWithAuth()
            .delete(`/creds/${instanceId}`)
            .then(res => {
                setOneDrive([])
            }).catch(err => console.log(err))
    }


    return (
        <div>
            {oneDrive && oneDrive.length > 0 ?
                <div style={{ padding: "6rem 0rem" }}>
                    <Table celled style={{ width: "1100px", margin: "3rem auto" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>URL</Table.HeaderCell>
                                <Table.HeaderCell style={{ textAlign: "center" }}>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {oneDrive.map(one => (
                                <Table.Row key={one.id}>
                                    <Table.Cell style={{ width: "20%" }}>One Drive</Table.Cell>
                                    <Table.Cell style={{ width: "60%" }}><a target="_blank" href={one.data}>{one.data}</a></Table.Cell>
                                    <Table.Cell style={{ width: "20%", textAlign: "center" }}><Button color="red" basic onClick={() => handleRemovingInstance(one.id)}>Delete</Button></Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='3'>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </div> :
                <div style={{ margin: "1rem auto" }}>
                    <p>We need to generate a link for the user to upload documents to One Drive</p>
                    <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        trigger={<Button color="yellow" basic>Add OneDrive Link</Button>}
                    >
                        <Modal.Header>One Drive Link</Modal.Header>
                        <Modal.Content image scrolling style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div>
                                <Form style={{ width: "700px", margin: "0 auto", display: "flex", flexDirection: "column" }}>
                                    <label htmlFor="name" >Name</label>
                                    <p style={{ margin: ".5rem 0rem", width: "700px", border: "1px solid rgb(222,222,223)", borderRadius: "5px", padding: ".5rem 0rem .5rem 1rem" }}>One Drive</p>
                                    <label htmlFor="data">URL</label>
                                    <input
                                        style={{ margin: ".5rem 0rem", width: "700px" }}
                                        type="text"
                                        defaultValue={"https://"}
                                        name="data"
                                        id="data"
                                        ref={register({
                                            required: "Please provide a valid URL",
                                            pattern: {
                                                value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i,
                                                message: "Please provide a valid url starting with HTTP or HTTPS"
                                            }
                                        })}
                                    />
                                    {errors.data && <Label basic color='red' pointing >* {errors.data.message} *</Label>}
                                </Form>
                                {dupError && <Label basic color='red' pointing style={{margin: "1 auto", width: "700px", textAlign: "center"}}>The url entered already exist in our Database for another company</Label>}
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button type="submit" basic color='yellow' onClick={handleSubmit(handleOneDrivePost)}>
                                Save
                            </Button>
                            <Button onClick={() => setOpen(false)} basic color='red'>
                                Exit
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </div>}
        </div>
    )
}

export default OneDrive;