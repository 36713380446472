import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { getUserInfo } from "../../reduxStore/actions/user-actions";
import AllUsersGrid from "./allUsersGrid"
import { Grid } from 'semantic-ui-react';
import Breadcrumbs from "../breadcrumbs";
import CryptoJS from "crypto-js";
import { axiosWithAuth } from '../../helperFunctions/axiosWithAuth';


function ManageLevyUsers({ user, getUserInfo }) {

    const history = useHistory();
    const [users, setUsers] = useState([])
    const isAdmin = !(user.user_types_id === 1);
    const breadcrumbPath = [{ name: "Dashboard", url: "/employeedashboard" }, { name: `${isAdmin ? "Manage" : ""} Internal Users` }];
    var byteId = CryptoJS.AES.decrypt(localStorage.getItem("JFZR5qve"), localStorage.getItem("SWassWQWiu"));
    var theId = byteId.toString(CryptoJS.enc.Utf8);
    var byteType = CryptoJS.AES.decrypt(localStorage.getItem("YGVtSKYr"), localStorage.getItem("SWassWQWiu"));
    var theType = byteType.toString(CryptoJS.enc.Utf8);

    useEffect(() => {
        if (theType === "4") {
            // then check the user to see if that is 4
            history.push(`/dashboard/${encodeURIComponent(localStorage.getItem("JFZR5qve"))}`)
        }
        getUserInfo(theId)
    }, [])

    useEffect(() => {
        axiosWithAuth()
            .get('/users')
            .then(res => {
                setUsers(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <div>
            <div style={{ width: "90%", margin: "0 auto", textAlign: "left", marginTop: "2rem" }}>
                <Breadcrumbs path={breadcrumbPath} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "4rem", zIndex: "-1", }}>
                <Grid style={{ width: "90%" }} columns={!(user.user_types_id === 1 || user.user_types_id === 1) ? 6 : 5} padded >
                    <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", backgroundColor: "grey", color: "white" }}>Name</Grid.Column>
                    <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", backgroundColor: "grey", color: "white" }}>Email</Grid.Column>
                    <Grid.Column style={{ display: "flex", alignItems: "center", textAlign: "left", backgroundColor: "grey", color: "white" }}>Position</Grid.Column>
                    <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "grey", color: "white" }}>Permissions</Grid.Column>
                    <Grid.Column style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "grey", color: "white" }}>HubSpot Owner ID</Grid.Column>
                    {isAdmin && (
                        <Grid.Column style={{ display: "flex", alignItems: "center", backgroundColor: "grey", color: "white", justifyContent: "center" }}>Actions</Grid.Column>
                    )}
                    {users && users.length > 0 && users.map(myUser => {
                        if (myUser.id !== user.id) {
                            return (<AllUsersGrid key={myUser.id} myUser={myUser} user={user} setUsers={setUsers} users={users} isAdmin={isAdmin} />)
                        }
                    })}
                </Grid>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        user: state.usersReducer.user,
    }
}


export default connect(mapStateToProps, { getUserInfo })(ManageLevyUsers);